import React, { useEffect, useState } from "react";
import { MDBRow, MDBInput, MDBContainer, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';
import { RecuperarPwdEnAPI, getDataUser } from "../action/actionCreatorsRequests";
import { Modal, Button, Input, Row, Col } from 'antd';
import { obtenerParametrosPorQueryString, MSGERROR, MSGOK, traducirLogin, delayTest, getIdiomaLogin } from "../librerias/funciones"
import store from "../store";


const ModalPassword = ({ ABRIR_MODAL = false, LANGUAGE = "", EMAIL_RECUPERACION = "", CERRAR_MODAL = () => { }, MODIFICAR_EMAIL_RECUPERACION = () => { }, RECUPERAR_PWD = () => { }, className = "" }) => {
  return (
    <Modal
      visible={ABRIR_MODAL}
      width={600}
      centered
      title={traducirLogin("Recuperar_contrasenia", LANGUAGE).toUpperCase()}
      afterClose={() => { MODIFICAR_EMAIL_RECUPERACION("") }}
      onCancel={() => CERRAR_MODAL(false)}
      style={{ zIndex: '99999999' }}
      footer={[
        <Button key="back" onClick={() => { CERRAR_MODAL(false) }}>
          {traducirLogin("Salir", LANGUAGE)}
        </Button>,

        <Button className={className} key="submit" type="primary" onClick={() => {
          RECUPERAR_PWD(EMAIL_RECUPERACION, LANGUAGE)
        }}> {traducirLogin("Enviar", LANGUAGE)}</Button>
      ]}
    >

      <Row>
        <Col md={24} className="mt-2 mb-2">
          <span className="tituloRecuperarEmail">{traducirLogin("Email_recuperacion", LANGUAGE)} </span>
        </Col>
        <Col md={24}>
          <Input value={EMAIL_RECUPERACION} onChange={(e) => { MODIFICAR_EMAIL_RECUPERACION(e.target.value) }} />
        </Col>
      </Row>
    </Modal>
  )
}

const CardLogin = ({ LANGUAGE = "", CERRAR_MODAL = () => { }, VERIFICA_USUARIO = () => { }, children = {}, styles_card_body = {}, classNameButton = "" }) => {
  const default_user = { USER: "", PWD: "" }
  const [credenciales, setCredenciales] = useState({ ...default_user })


  return (
    <MDBContainer>
      <MDBRow>

        <MDBCol md="12">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '100vh' }}>
            <MDBCard className="col-md-6">

              {children}

              <MDBCardBody style={styles_card_body}>
                <form>
                  <div className="grey-text">
                    <MDBInput
                      id="txtusuario"
                      label={traducirLogin("Usuario", LANGUAGE)}
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={credenciales.USER}
                      onChange={(e) => { credenciales.USER = e.target.value; setCredenciales({ ...credenciales }) }}
                    />

                    <MDBInput
                      id="txtpassword"
                      label={traducirLogin("Contrasenia", LANGUAGE)}
                      group
                      type="password"
                      validate
                      value={credenciales.PWD}
                      onChange={(e) => { credenciales.PWD = e.target.value; setCredenciales({ ...credenciales }); }}
                      onKeyPress={(e) => { if (e.which === 13) { VERIFICA_USUARIO(credenciales.USER, e.target.value, LANGUAGE) } }}
                    />

                    <a onClick={() => { CERRAR_MODAL(true) }}>{traducirLogin("Contrasenia_olvidada", LANGUAGE)}</a>
                  </div>

                  <div className="text-center py-4">
                    <MDBBtn color="elegant" className={classNameButton} onClick={() => {
                      VERIFICA_USUARIO(credenciales.USER, credenciales.PWD, LANGUAGE)
                      document.body.style.overflow = "hidden";
                    }}>

                      {traducirLogin("Iniciar_sesion", LANGUAGE)}

                    </MDBBtn>
                  </div>

                </form>
              </MDBCardBody>

            </MDBCard>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

const LOGIN_DEFAULT = (props) => {
  return <div>

    <CardLogin
      LANGUAGE={props.LANGUAGE}
      CERRAR_MODAL={props.CERRAR_MODAL}
      VERIFICA_USUARIO={props.VERIFICA_USUARIO}
    >
      <MDBCardImage style={{ margin: '0 auto', maxHeight: '250px' }} className="img-response" src="logo_login.png" waves />

    </CardLogin>

    <ModalPassword
      ABRIR_MODAL={props.ABRIR_MODAL}
      CERRAR_MODAL={props.CERRAR_MODAL}
      LANGUAGE={props.LANGUAGE}
      EMAIL_RECUPERACION={props.EMAIL_RECUPERACION}
      MODIFICAR_EMAIL_RECUPERACION={props.MODIFICAR_EMAIL_RECUPERACION}
      RECUPERAR_PWD={props.RECUPERAR_PWD}
      className="color_btn_sertec_default"
    />

  </div>
}

const LOGIN_WOP_ASOCIADAS = (props) => {

  return <div>
    <CardLogin
      LANGUAGE={props.LANGUAGE}
      CERRAR_MODAL={props.CERRAR_MODAL}
      VERIFICA_USUARIO={props.VERIFICA_USUARIO}
      styles_card_body={{ paddingTop: '0px' }}
      classNameButton={"color_btn_importadoras"}
    >

      <div>
        <img style={{ height: '260px', marginTop: '20px' }} src="logoIA.png" alt="logo"></img>
      </div>

    </CardLogin>


    <ModalPassword
      ABRIR_MODAL={props.ABRIR_MODAL}
      CERRAR_MODAL={props.CERRAR_MODAL}
      LANGUAGE={props.LANGUAGE}
      EMAIL_RECUPERACION={props.EMAIL_RECUPERACION}
      MODIFICAR_EMAIL_RECUPERACION={props.MODIFICAR_EMAIL_RECUPERACION}
      RECUPERAR_PWD={props.RECUPERAR_PWD}
      className="color_btn_importadoras"
    />

  </div>
}

const LOGIN_WOP_GPN = (props) => {
  //naraja #f28230
  //azul #008abd
  return <div>

    <CardLogin
      LANGUAGE={props.LANGUAGE}
      CERRAR_MODAL={props.CERRAR_MODAL}
      VERIFICA_USUARIO={props.VERIFICA_USUARIO}
      classNameButton={"color_btn_wop_gpn"}
    >

      <div>
        <img style={{ height: '150px', margin: '50px 0px 30px 0px' }} src="./imagenes/wop_logo.png" alt="logo"></img>
      </div>
    </CardLogin>


    <ModalPassword
      ABRIR_MODAL={props.ABRIR_MODAL}
      CERRAR_MODAL={props.CERRAR_MODAL}
      LANGUAGE={props.LANGUAGE}
      EMAIL_RECUPERACION={props.EMAIL_RECUPERACION}
      MODIFICAR_EMAIL_RECUPERACION={props.MODIFICAR_EMAIL_RECUPERACION}
      RECUPERAR_PWD={props.RECUPERAR_PWD}
      className="color_btn_wop_gpn"
    />

  </div>
}


const Login_Page = ({ props }) => {
  //const params = window.location.href.includes("&") ? obtenerParametrosPorQueryString(window.location.href) : null
  const [visible, setVisible] = React.useState(false);
  const [emailRecuperacion, setEmailRecuperacion] = React.useState("");
  const [diccionarios, setDiccionarios] = useState({
    registros: [],
    isLoading: false
  })

  const query = new URLSearchParams(props.location.search);
  const language = query.get('language');


  const verificarUsuario = (user, pwd, language) => {
    if (user === "" || pwd === "") {
      MSGERROR(traducirLogin("Falta_usuario", language))
    } else {
      getDataUser(user, pwd)
    }
  }

  const recuperarPWD = (EMAIL_RECUP, language) => {
    if (EMAIL_RECUP.trim() === "") {
      MSGERROR(traducirLogin("Email_recuperacion", language))
    } else {
      RecuperarPwdEnAPI(EMAIL_RECUP).then((registro) => {
        if (registro !== undefined) {
          if (registro.OK) {
            MSGOK(traducirLogin("Email_enviado", language));
            setVisible(false)
          }
        }
      })
    }
  }

  const TIPO_LOGIN = () => {
    let HOST_NAME = window.location.hostname

    switch (HOST_NAME) {
      case "wop.iasociadas.com":
        return (<LOGIN_WOP_ASOCIADAS
          ABRIR_MODAL={visible}
          CERRAR_MODAL={setVisible}
          EMAIL_RECUPERACION={emailRecuperacion}
          MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
          RECUPERAR_PWD={recuperarPWD}
          VERIFICA_USUARIO={verificarUsuario}
          LANGUAGE={language}
        />
        )

      case "wop-gpn.com":
        return (<LOGIN_WOP_GPN
          ABRIR_MODAL={visible}
          CERRAR_MODAL={setVisible}
          EMAIL_RECUPERACION={emailRecuperacion}
          MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
          RECUPERAR_PWD={recuperarPWD}
          VERIFICA_USUARIO={verificarUsuario}
          LANGUAGE={"FR"}
        />
        )
      case "wop.idrechange.fr":
        return (<LOGIN_WOP_GPN
          ABRIR_MODAL={visible}
          CERRAR_MODAL={setVisible}
          EMAIL_RECUPERACION={emailRecuperacion}
          MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
          RECUPERAR_PWD={recuperarPWD}
          VERIFICA_USUARIO={verificarUsuario}
          LANGUAGE={"FR"}
        />
        )

      default:
        return (<LOGIN_DEFAULT
          ABRIR_MODAL={visible}
          CERRAR_MODAL={setVisible}
          EMAIL_RECUPERACION={emailRecuperacion}
          MODIFICAR_EMAIL_RECUPERACION={setEmailRecuperacion}
          RECUPERAR_PWD={recuperarPWD}
          VERIFICA_USUARIO={verificarUsuario}
          LANGUAGE={language}
        />
        )

    }
  }

  const obtenerDiccionarioLogin = async () => {
    diccionarios.isLoading = true; setDiccionarios({ ...diccionarios })
    const nombreJson = getIdiomaLogin()
    const diccionario = await fetch(`./traducciones/${nombreJson}.json`)
    const response = await diccionario.json();
    if (response !== undefined && response !== null) {
      store.dispatch({ type: "STATE_DICCIONARIO_LOGIN", DICCIONARIO_LOGIN: response })
    }
    diccionarios.isLoading = false;
    setDiccionarios({ ...diccionarios })
  }

  const authLogin = () => {
    if (query.size >= 2) {
      const { hash } = props.location;
      const user = query.get('user');
      const pwd = query.get('pass');
      verificarUsuario(user, `${pwd}${hash}`)
    }
    document.body.style.overflow = "visible";
  }


  useEffect(() => {
    obtenerDiccionarioLogin().then(() =>{
      authLogin()
    })
    
  }, [])

  return (
    <div>
      {
        diccionarios.isLoading ?
          <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="spinner-border  fast colorSubAzul" role="status" disabled />
          </div>
          :
          <TIPO_LOGIN />
      }
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    credenciales: state.credenciales,
    props: props,
  };
}


export default connect(mapStateToProps)(Login_Page);