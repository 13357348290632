import React from "react";
import { etiquetaBtnSolicitar, etiquetaSinDisponibilidad, obtenerDatosUsuarioYLicenciaActual, traducirPagina } from "../../../librerias/funciones";
import { MDBCol, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import CPCantidad from "../../utils/CPCantidad";
import BtnSolicitar from "../../utils/BtnSolicitar";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";

const TableCesta = ({ esCestaCompra = false, desglose = [],loadingArticuloERP=true, setDesglose = () => { }, esperandoDesglose = false, añadirALaCesta = () => { }, eventInsertarConsulta = () => { },
    event_input = () => { }, inputCantidadBlur = () => { }, gestionarCantidad = () => { }, alturaTabla = 50
}) => {


    const buscarFechaEntrega = (registros) => {
        const resultado = registros.filter((registro) => (registro.FechaEntrega !== undefined && registro.FechaEntrega !== null && registro.FechaEntrega !== ""));
        return resultado;
    }

    return (


        <div className="ContainerDetalles" style={{ marginBottom: "0px", display: 'block', height: `calc(100% - ${alturaTabla}px)` }}>
            {desglose.length > 0 ?

                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: `calc(100% - ${50}px)`, overflow: 'auto' }} className={"row"} >

                    <MDBTable responsive className="tblcesta">
                        <MDBTableHead>
                            <tr>
                                <th style={{ textAlign: 'left', fontWeight: '500' }}>{ }</th>
                                <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Almacen").toUpperCase()}</th>
                                {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <th style={{ textAlign: 'right', width: '100px', fontWeight: '500' }}>{obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ? "" : obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? "" : traducirPagina("Cantidad").toUpperCase()}</th>}
                                <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Disponibilidad").toUpperCase()}</th>

                                {buscarFechaEntrega(desglose).length > 0 ? <th style={{ textAlign: 'center', width: '190px', fontWeight: '500' }}>{traducirPagina("Fecha_entrega").toUpperCase()}</th> : null}

                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {desglose.map((item, i) =>
                                <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }}>
                                    <td style={{ textAlign: 'left', visibility: item.Stock === 0 ? 'hidden' : 'visible' }} >
                                        <CPCantidad
                                            value={item.CANTIDAD}
                                            event_input={(e) => {
                                                event_input(item, e.target.value, item.CANTIDAD_MINIMA)
                                            }}
                                            onBlur={(e) => {
                                                inputCantidadBlur(item, item.CANTIDAD_MINIMA)
                                            }}
                                            event_minus={() => {
                                                gestionarCantidad(item, item.CANTIDAD_MINIMA, true)
                                            }}

                                            event_plus={() => {
                                                gestionarCantidad(item, item.CANTIDAD_MINIMA, false)
                                            }}
                                        />
                                    </td>

                                    <td>
                                        {item.DescripcionAlmacen}
                                    </td>

                                    {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <td style={{ textAlign: 'right', width: '100px' }}>{item.Stock}</td>}
                                    <td style={{ textAlign: 'center', width: '100px' }}>
                                        <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: item.Stock === 0 ? "red" : '#25c281', backgroundColor: 'transparent' }}>  {item.Stock === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                    </td>


                                    {buscarFechaEntrega(desglose).length > 0 ? <td style={{ textAlign: 'center', width: '190px' }}>  {item.FechaEntrega !== null ? `${item.FechaEntrega} ${item.HoraEntrega}` : ""} </td> : null}

                                </tr>

                            )
                            }
                        </MDBTableBody>
                    </MDBTable>
                </div>
                :

                esperandoDesglose ?
                    <div>
                        <span className="skeleton-box" style={{ width: '450px' }}></span>
                        <br />
                        <span className="skeleton-box" style={{ width: '450px' }}></span>
                        <br />
                        <span className="skeleton-box" style={{ width: '450px' }}></span>
                        <br />
                        <span className="skeleton-box" style={{ width: '450px' }}></span>
                        <br />
                        <span className="skeleton-box" style={{ width: '450px' }}></span>
                    </div>
                    :
                    <div style={{ fontSize: '22px', fontWeight: '500', color: "#dc3545" }} className="row">
                        <MDBCol className="col-md-6">
                            <img src="./imagenes/no_disponible.svg" style={{ height: '170px' }}></img>
                        </MDBCol>
                        <MDBCol className="col-md-6">
                            <span>{etiquetaSinDisponibilidad()}</span>
                        </MDBCol>
                    </div>
            }


            {
                esperandoDesglose ?
                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }}>
                        <div style={{ marginTop: '10px' }}>
                            <button className={`activebtnMenu3 ${loadingArticuloERP ? "disable_button":""}`} style={{ height: '40px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: '#4285f4' }}
                                onClick={() => { añadirALaCesta(); }}
                            >
                                <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{
                                    !esCestaCompra ?

                                        traducirPagina("Añadir_a_la_cesta")

                                        :
                                        traducirPagina("Modificar_a_la_cesta")
                                }
                                </span>
                            </button>
                        </div>
                    </div>
                    :
                    <div style={{ fontSize: '16px', fontWeight: '500' }} className='container_info_cesta'>
                        <div>
                            <BtnSolicitar
                                texto={etiquetaBtnSolicitar()}
                                onClick={eventInsertarConsulta}
                            />

                        </div>

                        <div className="col-md-6">
                            <button style={{ height: '60px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#eee' }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("btn_pedido_referencia")}</span>  </button>
                        </div>
                    </div>
            }

        </div>
    )

}

export default TableCesta;