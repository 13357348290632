import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerMasInformacion, actulizarReferenciaSeleccionada, DrawerStockAlmacen } from "../action/actionCreators"
import { getCestaLineasEnAPI, eliminarCestaLineasEnAPI, vaciarCestaLineasEnAPI, CrearPedidoEnAPI, setCestaLineasEnAPI, ObtenerCentroClienteERPEnAPI, CrearPresupuestoEnAPI } from "../action/actionCreatorsRequests"
import { traducirPagina, referenciaActualSelect, convertirMoneda, redondeo, MSGOK, obtenerDatosUsuarioYLicenciaActual, MSGERROR, verificarSiEsMostrador, datosLincenciaUsuario, obtenerRegistroQuery } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import {
    EyeOutlined,
    LikeOutlined,
    DislikeOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { Select, Card, Modal, Button, Input, Row, Col, Checkbox, List, Drawer } from 'antd';
import store from "../store";
import { FichaAgregarArticuloGenerico } from './Compra/FichaAgregarArticuloGenerico';
import { FichaStockNeumatico } from './Compra/FichaStockNeumatico';
import HeaderCesta from './Cesta/header/HeaderCesta';
import CPSelect from './utils/CPSelect';
import FichaAñadirCesta from './FichaAñadirCesta';

const { Option } = Select;
const CestaCompra = ({ props, configApp, referenciaActual, ClientesMostrador, ABRIR_FICHA = false, CERRAR_FICHA = () => { } }) => {
    //const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});
    const [obtenerCesta, setObtenerCesta] = React.useState([]);
    const [obtenerCestaAUX, setObtenerCestaAUX] = React.useState([]);

    const selects = {
        id_seleccionado: undefined,
        registers: []
    }

    const selectsAlmacen = {
        id_seleccionado: undefined,
        registers: [{
            ID_SELECT: 0,
            DESCRIPCION_SELECT: traducirPagina("EN_TODOS_LOS_ALMACENES")
        }]
    }
    const defaultTotales = {
        unidades: 0,
        pedidos: 0,
        reset: true
    }

    const [totales, setTotales] = React.useState({ ...defaultTotales });
    const [almacenes, SetAlmacenes] = React.useState({ ...selectsAlmacen });
    const [clientesCesta, SetClientesCesta] = React.useState({ ...selects });
    const [clienteSeleccionado, SetClienteSeleccionado] = React.useState("");
    const [visible, SetVisible] = React.useState(false);
    const [visibleConsulta, SetVisibleConsulta] = React.useState(false);
    const [visiblePedido, SetVisiblePedido] = React.useState(false);
    const [cestaLineaSeleccionada, SetCestaLineaSeleccionada] = React.useState([]);
    const [listaLineas, SetListaLineas] = React.useState([]);
    const [pedidosRealizados, SetPedidosRealizados] = React.useState([]);
    const [centros, SetCentros] = React.useState([]);
    const [centroSeleccionado, SetCentroSeleccionado] = React.useState("");
    const [esperarPedido, setEsperarPedido] = React.useState(false);
    const [fichaCesta, setFichaCesta] = React.useState(false);
    const [fichaCestaNeumatico, setFichaCestaNeumatico] = React.useState(false);
    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});
    const [esCrearPresupuesto, setEsCrearPresupuesto] = React.useState(false);
    const [payloadNeumatico, setPayloadNeumatico] = React.useState(
        {
            NOMRE_MARCA: "",
            CODIGO_MARCA: "",
            EAN: "",
            ID_MARCA: "",

            DETALLE_ERP_SOCIO: {
                NETO: 0,
                PRECIO: 0,
                PVP: 0,
                DESCUENTO: 0,
                CODIGO_MARCA_SOCIO: "",

                REFERENCIA_SOCIO: "",
                REFERENCIA_FABRICANTE: ""
            }

        }
    );



    const [datosCompra, setDatosCompra] = React.useState(
        {
            observacionesConsulta: "",
            observacionesPedido: "",
            numeroPedido: "",
            pedidoPor: "",
            recibirMailPedido: false,
            lineasPedido: "",
            recogerArticulo: false
        }
    );

    const [abrirStockNeumatico, setAbrirStockNeumatico] = React.useState(false);
    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const { PLANTILLA } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { PLANTILLA: false } : OBTENER_DATOS_USUARIO;
    const { COMPRAR_SIN_DISPONIBILIDAD } = OBTENER_DATOS_USUARIO === undefined ? { COMPRAR_SIN_DISPONIBILIDAD: false } : OBTENER_DATOS_USUARIO;

    const calcularTotales = (registros = []) => {
        if (registros === null) { registros = [] }

        let sumaUnidades = 0;
        let sumaPrecio = 0;

        registros.map((item) => {
            let estaHabilitado = permitirSeleccionarLinea(item) ? false : item.TEXTO_LIBRE ? false : true
            if (!estaHabilitado) {
                sumaUnidades += item.CANTIDAD
                sumaPrecio += redondeo(item.TOTAL, 2)
            }
        })
        totales.unidades = sumaUnidades
        totales.pedidos = sumaPrecio
        setTotales({ ...totales })
    }

    const obtenerAlmacenesLocal = (registers) => {
        let filtroPorAlmacen = [];

        filtroPorAlmacen = registers.filter((a, i) => registers.findIndex((s) => a.ALMACEN === s.ALMACEN) === i).map((item) => {
            return { ID_SELECT: item.ALMACEN, DESCRIPCION_SELECT: item.DESCRIPCION_ALMACEN === "" ? item.ALMACEN : item.DESCRIPCION_ALMACEN }
        })

        almacenes.id_seleccionado = undefined
        almacenes.registers = [...selectsAlmacen.registers, ...filtroPorAlmacen]
        SetAlmacenes({ ...almacenes });
    }


    const obtenrLineasCesta = async () => {
        setObtenerCesta([]);
        setObtenerCestaAUX([]);

        let registros = await getCestaLineasEnAPI()
        let registros_filtrados = [];

        if (registros !== undefined && registros !== null && registros.length > 0) {
            registros = registros.map((item) => { return { ACTIVAR_LINEA: false, ...item } })

            setObtenerCesta(registros);
            setObtenerCestaAUX(registros);

            if (Object.keys(referenciaActual).length > 0) {
                resetChecked(false, obtenerCesta)
            }

            // calcularTotales(registros)
            obtenerAlmacenesLocal(registros)

            registros_filtrados = mostrarClientesCesta(registros);
        } else {
            setObtenerCesta(null)
            setObtenerCestaAUX(null);
            registros_filtrados = null
        }


        if (registros_filtrados !== undefined && registros_filtrados !== null && registros_filtrados.length > 0) {
            totales.reset = true
            totales.unidades = 0
            totales.pedidos = 0

            setTotales({ ...totales })
            SetListaLineas([])
            SetCestaLineaSeleccionada([])
            calcularTotales(registros_filtrados)

        }


        return registros_filtrados
    }

    const obtenerCentros = async (ID_CLIENTE) => {
        const { VISUALIZAR_CENTROS_PEDIDO, CENTRO, CLIENTE } = obtenerDatosUsuarioYLicenciaActual()
        const esMostrador = verificarSiEsMostrador()


        if (esMostrador || VISUALIZAR_CENTROS_PEDIDO) {
            SetCentros([])
            const registros = await ObtenerCentroClienteERPEnAPI(ID_CLIENTE)
            if (registros !== undefined) {
                if (registros.CENTROS !== null && registros.CENTROS.length > 0) {
                    SetCentros(registros.CENTROS)

                    if (esMostrador) {
                        SetCentroSeleccionado(registros.CENTROS[0].CODIGO)
                    } else {
                        const buscarCentro = registros.CENTROS.find((item) => item.CODIGO === CENTRO)
                        SetCentroSeleccionado(buscarCentro === undefined ? registros.CENTROS[0].CODIGO : CENTRO)
                    }
                } else {
                    SetCentros(null)
                }
            }
        }
    }


    const cargarCestaLineas = async () => {
        /*
                if (verificarSiEsMostrador()) {
                   
                }
        */

        await obtenerCentros()

        const registers = await obtenrLineasCesta()

        return registers;
    }




    const mostrarClientesCesta = (registros) => {
        let filtroPorCliente = [];
        let nuevaListaCesta = [];

        filtroPorCliente = registros.filter((a, i) => registros.findIndex((s) => a.CLIENTE === s.CLIENTE) === i).map((item) => {
            return { ID_SELECT: item.CLIENTE, DESCRIPCION_SELECT: verCodigoNombreCliente(item.CLIENTE) }
        })


        clientesCesta.registers = filtroPorCliente
        clientesCesta.id_seleccionado = filtroPorCliente[0].ID_SELECT

        nuevaListaCesta = registros.filter((item) => item.CLIENTE === clientesCesta.id_seleccionado)

        SetClientesCesta({ ...clientesCesta });
        SetClienteSeleccionado(clientesCesta.id_seleccionado);

        setObtenerCesta(nuevaListaCesta);
        return nuevaListaCesta
    }

    const guardarDatos = (e) => {

        let modificar = {
            observacionesConsulta: "",
            observacionesPedido: "",
            numeroPedido: "",
            pedidoPor: "",
            recibirMailPedido: false,
            lineasPedido: "",
            recogerArticulo: false
        }

        switch (e.target.name) {
            case "observacionesConsulta":
                modificar.observacionesConsulta = e.target.value;
                modificar.observacionesPedido = datosCompra.observacionesPedido
                modificar.numeroPedido = datosCompra.numeroPedido
                modificar.pedidoPor = datosCompra.pedidoPor
                modificar.recibirMailPedido = datosCompra.recibirMailPedido;
                modificar.lineasPedido = datosCompra.lineasPedido;
                modificar.recogerArticulo = datosCompra.recogerArticulo;

                setDatosCompra(modificar)
                break;
            case "observacionesPedido":
                modificar.observacionesPedido = e.target.value;
                modificar.observacionesConsulta = datosCompra.observacionesConsulta;
                modificar.numeroPedido = datosCompra.numeroPedido
                modificar.pedidoPor = datosCompra.pedidoPor
                modificar.recibirMailPedido = datosCompra.recibirMailPedido;
                modificar.lineasPedido = datosCompra.lineasPedido;
                modificar.recogerArticulo = datosCompra.recogerArticulo;

                setDatosCompra(modificar)
                break;
            case "numeroPedido":
                modificar.numeroPedido = e.target.value;
                modificar.observacionesConsulta = datosCompra.observacionesConsulta;
                modificar.observacionesPedido = datosCompra.observacionesPedido
                modificar.pedidoPor = datosCompra.pedidoPor
                modificar.recibirMailPedido = datosCompra.recibirMailPedido;
                modificar.lineasPedido = datosCompra.lineasPedido;
                modificar.recogerArticulo = datosCompra.recogerArticulo;

                setDatosCompra(modificar)
                break;
            case "pedidoPor":
                modificar.pedidoPor = e.target.value;
                modificar.observacionesConsulta = datosCompra.observacionesConsulta;
                modificar.observacionesPedido = datosCompra.observacionesPedido;
                modificar.numeroPedido = datosCompra.numeroPedido;
                modificar.recibirMailPedido = datosCompra.recibirMailPedido;
                modificar.lineasPedido = datosCompra.lineasPedido;
                modificar.recogerArticulo = datosCompra.recogerArticulo;

                setDatosCompra(modificar)
                break;
            case "recibirMailPedido":
                modificar.recibirMailPedido = e.target.checked;
                modificar.pedidoPor = datosCompra.pedidoPor
                modificar.observacionesConsulta = datosCompra.observacionesConsulta;
                modificar.observacionesPedido = datosCompra.observacionesPedido;
                modificar.numeroPedido = datosCompra.numeroPedido;
                modificar.lineasPedido = datosCompra.lineasPedido;
                modificar.recogerArticulo = datosCompra.recogerArticulo;

                setDatosCompra(modificar)
                break;
            case "recogerArticulo":
                modificar.recogerArticulo = e.target.checked
                modificar.recibirMailPedido = datosCompra.recibirMailPedido;
                modificar.pedidoPor = datosCompra.pedidoPor
                modificar.observacionesConsulta = datosCompra.observacionesConsulta;
                modificar.observacionesPedido = datosCompra.observacionesPedido;
                modificar.numeroPedido = datosCompra.numeroPedido;
                modificar.lineasPedido = datosCompra.lineasPedido;
                setDatosCompra(modificar)
                break;
        }
    }


    const resetChecked = (chkTotal, registers = []) => {

        setTotales({ ...defaultTotales })

        let lineas = 0;
        let unidades = 0;
        let lineaSeleccionada = [];
        if (registers !== null) {

            registers.map((cesta, i) => {
                if (chkTotal) {
                    let estaHabilitado = permitirSeleccionarLinea(cesta) ? false : cesta.TEXTO_LIBRE ? false : true
                    if (!estaHabilitado) {
                        gestionarLineasPedido(true, cesta)
                    }
                } else {
                    gestionarLineasPedido(false, cesta)
                }

                let marcarLinea = permitirSeleccionarLinea(cesta) ? false : cesta.TEXTO_LIBRE ? false : true;
                if (!marcarLinea) {
                    if (chkTotal) {
                        lineas += cesta.TOTAL;
                        unidades += cesta.CANTIDAD;

                    }
                    lineaSeleccionada.push(cesta)

                    /*
                    if (document.getElementById("checkbox" + i) !== null) {
                        document.getElementById("checkbox" + i).checked = chkTotal;
                    }
                    */
                    cesta.ACTIVAR_LINEA = chkTotal
                }
            })


        }

        if (chkTotal) {
            SetCestaLineaSeleccionada(lineaSeleccionada)
        } else {
            SetCestaLineaSeleccionada([])
        }

        if (document.getElementById("chkTodos") !== null) {
            document.getElementById("chkTodos").checked = chkTotal;
        }


        totales.unidades = unidades
        totales.pedidos = lineas
        setTotales({ ...totales })
    }



    const checkedSeleccionado = (chkLinea, lineaSeleccionada) => {

        if (totales.reset) {
            totales.reset = false

            totales.unidades = 0
            totales.pedidos = 0
            setTotales({ ...totales })
        }

        lineaSeleccionada.ACTIVAR_LINEA = chkLinea
        setObtenerCesta([...obtenerCesta])
        gestionarLineasPedido(chkLinea, lineaSeleccionada)

        if (chkLinea) {
            let sumaTotalLinea = redondeo(totales.pedidos + lineaSeleccionada.TOTAL, 2);

            totales.unidades = totales.unidades + lineaSeleccionada.CANTIDAD
            totales.pedidos = sumaTotalLinea
            setTotales({ ...totales })



            let añadirLineaCesta = cestaLineaSeleccionada;
            let buscarLinea = cestaLineaSeleccionada.find(cesta => cesta.ID === lineaSeleccionada.ID);
            if (buscarLinea === undefined) {
                añadirLineaCesta.push(lineaSeleccionada)
                SetCestaLineaSeleccionada(añadirLineaCesta);
            }

        } else if (totales.pedidos >= 0) {
            let restaLinea = totales.pedidos - lineaSeleccionada.TOTAL;
            let eliminarCestaLineaSeleccionada = cestaLineaSeleccionada.filter(cesta => cesta.ID !== lineaSeleccionada.ID);
            SetCestaLineaSeleccionada(eliminarCestaLineaSeleccionada)


            totales.unidades = totales.unidades - lineaSeleccionada.CANTIDAD
            totales.pedidos = restaLinea < 0 ? 0 : restaLinea
            setTotales({ ...totales })
        }

    }



    const estadoCestaCompra = (lineaSeleccionada) => {
        eliminarCestaLineasEnAPI(lineaSeleccionada.ID).then(cesta => {
            let nuevoEstado = []
            if (cesta) {
                gestionarLineasPedido(false, lineaSeleccionada.ID)

                obtenerCesta.map((cesta, i) => {
                    if (cesta.ID !== lineaSeleccionada.ID) {
                        nuevoEstado.push(cesta);
                    }
                })

                if (nuevoEstado.length === 0) {
                    setObtenerCesta(null)
                    setObtenerCestaAUX(null);
                    SetAlmacenes({ ...selectsAlmacen });
                    SetClientesCesta({ ...selects });
                } else {
                    setObtenerCesta(nuevoEstado);
                    setObtenerCestaAUX(nuevoEstado);
                    cargarCestaLineas()

                }
            }
        })
    }


    const addConsulta = (descripcion) => {
        let almacen = obtenerDatosUsuarioYLicenciaActual();

        setCestaLineasEnAPI("", "", "CONSULTA", "", descripcion, 1, 1, 0, 0, 0, almacen.ALMACEN, "", 0).then(data => {
            if (data.OK) {
                MSGOK("Consulta añadida.", "botton-right");
                obtenrLineasCesta()
            }
        })
    }

    const eliminarCesta = () => {
        vaciarCestaLineasEnAPI().then(cesta => {
            if (cesta) {
                setObtenerCesta(null)
                setObtenerCestaAUX(null)


                setTotales({ ...defaultTotales })


                SetAlmacenes({ ...selectsAlmacen });
                SetClientesCesta({ ...selects });
            }
        })
    }

    const generarPedido = () => {

        if (clienteSeleccionado === "") {
            MSGERROR(traducirPagina("msgTiene_seleccionar_cliente"))
            return null
        }

        if (cestaLineaSeleccionada.length > 0 && totales.unidades > 0) {
            SetVisible(true)

        } else {
            MSGERROR(traducirPagina("Seleccionar_linea"));
        }
    }


    const filtrarTabla = (e) => {
        let nuevaListaCesta = []

        almacenes.id_seleccionado = e
        SetAlmacenes({ ...almacenes })

        if (e === 0) {
            clientesCesta.id_seleccionado = undefined
            SetClientesCesta({ ...clientesCesta })
            SetClienteSeleccionado("")

            nuevaListaCesta = obtenerCestaAUX
        } else {

            if (clienteSeleccionado !== "") {
                nuevaListaCesta = obtenerCestaAUX.filter((cesta) => e === cesta.ALMACEN && clienteSeleccionado === cesta.CLIENTE)
            } else {
                nuevaListaCesta = obtenerCestaAUX.filter((cesta) => e === cesta.ALMACEN)
            }
        }

        totales.reset = true
        totales.unidades = 0
        totales.pedidos = 0

        setTotales({ ...totales })
        SetCestaLineaSeleccionada([])
        nuevaListaCesta = nuevaListaCesta.map((item) => { item.ACTIVAR_LINEA = false; return item })

        setObtenerCesta(nuevaListaCesta.length === 0 ? null : nuevaListaCesta)
        calcularTotales(nuevaListaCesta)
    }


    const filtrarTablaCliente = (e) => {
        let nuevaListaCesta = []

        obtenerCestaAUX.map((cesta, i) => {
            if (e === cesta.CLIENTE) {
                nuevaListaCesta.push(cesta)
            }
        })

        almacenes.id_seleccionado = undefined
        SetAlmacenes({ ...almacenes })

        SetClienteSeleccionado(e);
        clientesCesta.id_seleccionado = e
        SetClientesCesta({ ...clientesCesta })

        //resetChecked(true, nuevaListaCesta)
        totales.reset = true
        totales.unidades = 0
        totales.pedidos = 0

        setTotales({ ...totales })
        SetCestaLineaSeleccionada([])

        nuevaListaCesta = nuevaListaCesta.map((item) => { item.ACTIVAR_LINEA = false; return item })

        setObtenerCesta(nuevaListaCesta);
        calcularTotales(nuevaListaCesta)
    }


    const modificarEstadoConsulta = (e) => {
        let observacion = datosCompra.observacionesConsulta
        if (observacion !== "") {
            datosCompra.observacionesConsulta = ""
            setDatosCompra(datosCompra)
        }
    }


    const gestionarLineasPedido = (esAñadir, linea) => {
        if (esAñadir) {
            if (listaLineas.length === 0) {
                listaLineas.push(linea.ID)
                SetListaLineas(listaLineas);
            } else {
                let hayLinea = listaLineas.find(item => item.ID === linea.ID)

                if (hayLinea === undefined) {
                    let modificarLinear = []
                    modificarLinear = listaLineas;
                    modificarLinear.push(linea.ID)
                    SetListaLineas(modificarLinear)
                }

            }
        } else {
            if (listaLineas.length > 0) {
                let arr = listaLineas;
                let i = arr.indexOf(linea.ID);
                arr.splice(i, 1);
                SetListaLineas(arr)
            }
        }
    }

    const crearPedidoDeLineas = (datos, esPresupuesto) => {

        let noRepetidas = listaLineas.filter((v, i, a) => a.indexOf(v) === i)
        let lineasPedido = noRepetidas.toString();
        let convertir = lineasPedido.replaceAll(",", ";")

        if (clienteSeleccionado === "") {

            MSGERROR(traducirPagina("msgTiene_seleccionar_cliente"))
            return null
        }



        if (convertir !== "") {
            setEsperarPedido(true)
            //MSGOK("Realizando el pedido, espere un momento por favor")
            if (esPresupuesto) {
                CrearPresupuestoEnAPI(datos, convertir, clienteSeleccionado, centroSeleccionado).then(registro => {
                    setEsperarPedido(false)
                    SetVisible(false)
                    if (registro.OK) {
                        if (registro.RESULTADOS !== null && registro.RESULTADOS.length > 0) {
                            SetPedidosRealizados(registro.RESULTADOS)
                            SetVisiblePedido(true)
                            cargarCestaLineas();
                            SetListaLineas([])
                        }
                    }
                })
            } else {

                CrearPedidoEnAPI(datos, convertir, clienteSeleccionado, centroSeleccionado).then(registro => {
                    setEsperarPedido(false)
                    SetVisible(false)
                    if (registro.OK) {
                        if (registro.RESULTADOS !== null && registro.RESULTADOS.length > 0) {
                            SetPedidosRealizados(registro.RESULTADOS)
                            SetVisiblePedido(true)
                            cargarCestaLineas();
                            SetListaLineas([])
                        }
                    }
                })

            }
        } else {
            MSGERROR(traducirPagina("Seleccionar_linea"));
        }

    }

    const abrirDesglose = (item) => {
        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {

            if (item.ES_NEUMATICO) {
                setAbrirStockNeumatico(true)
                referenciaSeleccionada.REFERENCIA = item.REFERENCIA_TECDOC
                referenciaSeleccionada.REFERENCIA_PROVEEDOR = item.REFERENCIA_ERP
                referenciaSeleccionada.CODIGO_MARCA = item.MARCA_TECDOC

                setReferenciaSeleccionada({ ...referenciaSeleccionada })


            } else {
                store.dispatch(DrawerStockAlmacen(true));
                referenciaActualSelect({ REFERENCIA: item.REFERENCIA_TECDOC, CODIGO_MARCA: item.MARCA_TECDOC, REFERENCIA_PROVEEDOR: item.REFERENCIA_ERP, REGISTROS: item });
            }

        }
    }
    const permitirSeleccionarLinea = (REGISTRO) => {
        let { DISPONIBILIDAD } = REGISTRO

        return (DISPONIBILIDAD || COMPRAR_SIN_DISPONIBILIDAD)
    }



    const verCodigoNombreCliente = (cliente) => {
        if (cliente !== undefined && cliente !== null && cliente !== "") {
            let esMostrador = verificarSiEsMostrador();
            try {
                if (ClientesMostrador !== undefined && ClientesMostrador !== null && esMostrador) {
                    let RESULT_CLIENTE = ClientesMostrador.find((registro) => registro.Codigo === cliente);
                    cliente = RESULT_CLIENTE !== undefined ? `${cliente} - ${RESULT_CLIENTE.Razon}` : cliente;
                }
            } catch (error) {
                console.log("busqueda cliente", error)
            }
        }
        return cliente
    }


    useEffect(() => {
        cargarCestaLineas()
    }, [])



    return (
        <Drawer
            width={1300}
            onClose={() => {
                CERRAR_FICHA()
            }}
            visible={ABRIR_FICHA}
            placement={"right"}
            closable={false}
            bodyStyle={{ padding: '0px' }}
            style={{ color: '#fff' }}>


            <div className="mdb-skin">

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <button className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            CERRAR_FICHA()
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </button>
                    </div>
                    <div style={{ color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>



                <HeaderCesta
                    formConsulta={{ visibleConsulta, SetVisibleConsulta }}
                    event_vaciar={() => { eliminarCesta() }}
                    event_pedido={() => {
                        generarPedido(); setEsCrearPresupuesto(false); obtenerCentros(clienteSeleccionado);
                        setTimeout(() => {
                            let registro = obtenerRegistroQuery("quoteId")
                            if (registro !== null) {
                                guardarDatos({ target: { name: "numeroPedido", value: registro } })
                            }
                        }, 500)
                    }}
                    event_presupuesto={() => { generarPedido(); setEsCrearPresupuesto(true) }}
                >

                    <CPSelect
                        esListaObjeto={true}
                        placeholder={traducirPagina("EN_TODOS_LOS_ALMACENES")}
                        registers={almacenes.registers}
                        value={almacenes.id_seleccionado}
                        onChange={(e) => filtrarTabla(e)}
                        allowClear={false}

                    />


                    <CPSelect
                        esListaObjeto={true}
                        placeholder={traducirPagina("Clientes").toUpperCase()}
                        registers={clientesCesta.registers}
                        value={clientesCesta.id_seleccionado}
                        onChange={(e) => filtrarTablaCliente(e)}
                        allowClear={false}

                    >
                    </CPSelect>


                </HeaderCesta>


                <div style={{ height: (configApp.height - 150).toString() + "px", overflow: 'auto', padding: '0px 20px' }} >

                    <Card style={{ height: (configApp.height - 170).toString() + "px" }} >
                        {obtenerCesta === null ?
                            <div style={{ textAlign: 'center' }}>
                                <h4>{traducirPagina("No_hay_registros_cesta")}</h4>
                            </div>
                            :
                            obtenerCesta.length > 0 ?
                                <div>
                                    <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: (configApp.height - 255).toString() + "px", overflow: 'auto' }} className="row">
                                        <MDBTable hover >
                                            <MDBTableHead>
                                                <tr>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '24px' }}><MDBInput type="checkbox" id={"chkTodos"} style={{ verticalAlign: 'top' }} onChange={(e) => { resetChecked(e.target.checked, obtenerCesta) }} /> </th>

                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '240px', verticalAlign: 'baseline' }}>{traducirPagina("Referencia")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline' }}>{traducirPagina("Identificador")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '200px', verticalAlign: 'baseline' }}>{traducirPagina("Descripción")}</th>

                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '120px', verticalAlign: 'baseline' }}>{traducirPagina("Almacen")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '100px', textAlign: 'right', verticalAlign: 'baseline' }}>{traducirPagina("Precio")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '80px', textAlign: 'right', verticalAlign: 'baseline' }}>{traducirPagina("Dto")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '105px', textAlign: 'right', verticalAlign: 'baseline' }}>{traducirPagina("Neto")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '50px', textAlign: 'right', verticalAlign: 'baseline' }}>{traducirPagina("Cantidad")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '100px', textAlign: 'right', verticalAlign: 'baseline' }}>{traducirPagina("Total")}</th>
                                                    <th style={{ padding: '5px', fontSize: "14px", fontWeight: "bold", width: '100px', textAlign: 'center', verticalAlign: 'baseline' }}></th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {obtenerCesta.map((cesta, i) =>
                                                    <tr key={cesta.ID} style={{ color: cesta.DISPONIBILIDAD ? '#000' : '#999' }}>
                                                        <td style={{ padding: '5px', fontSize: "14px", verticalAlign: 'middle' }}>
                                                            <MDBInput disabled={permitirSeleccionarLinea(cesta) ? false : cesta.TEXTO_LIBRE ? false : true} type="checkbox" id={"checkbox" + i} checked={cesta.ACTIVAR_LINEA} onChange={(e) => { checkedSeleccionado(e.target.checked, cesta) }} />
                                                        </td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '240px', verticalAlign: 'middle' }}>{cesta.REFERENCIA_TECDOC !== undefined && cesta.REFERENCIA_TECDOC !== null && cesta.REFERENCIA_TECDOC.trim() === "" ? cesta.REFERENCIA_ERP : cesta.REFERENCIA_TECDOC}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '120px', verticalAlign: 'middle' }}>{cesta.IDENTIFICADOR_LINEA}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '200px', verticalAlign: 'middle' }}>{cesta.DESCRIPCION}</td>

                                                        <td style={{ padding: '5px', fontSize: "14px", width: '120px', verticalAlign: 'middle' }}>{cesta.DESCRIPCION_ALMACEN}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '100px', textAlign: 'right', verticalAlign: 'middle' }}>{convertirMoneda(cesta.PRECIO, " €")}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '50px', textAlign: 'right', verticalAlign: 'middle' }}>{cesta.DESCUENTO + "%"}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '105px', textAlign: 'right', verticalAlign: 'middle' }}>{convertirMoneda(cesta.NETO, " €")}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '50px', textAlign: 'right', verticalAlign: 'middle' }}>{convertirMoneda(cesta.CANTIDAD)}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '100px', textAlign: 'right', verticalAlign: 'middle' }}>{convertirMoneda(cesta.TOTAL, " €")}</td>
                                                        <td style={{ padding: '5px', fontSize: "14px", width: '100px', textAlign: 'right', verticalAlign: 'middle' }}>
                                                            <div className="flex-container-Grow-Simple">
                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn disabled={cesta.TEXTO_LIBRE} onClick={e => {
                                                                        createRipple(e);
                                                                        store.dispatch(DrawerMasInformacion(true));
                                                                        store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: cesta.REFERENCIA_TECDOC, CODIGO_MARCA: cesta.MARCA_TECDOC }));

                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                        <EyeOutlined style={{ color: cesta.TEXTO_LIBRE ? '#999' : '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>

                                                                </div>

                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn disabled={cesta.TEXTO_LIBRE} onClick={e => {
                                                                        createRipple(e);
                                                                        abrirDesglose(cesta)
                                                                        if (cesta.ES_NEUMATICO) {
                                                                            store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: true, REFERENCIA_FABRICANTE: cesta.REFERENCIA_TECDOC } })
                                                                        }
                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                        {cesta.DISPONIBILIDAD ? <LikeOutlined style={{ color: cesta.TEXTO_LIBRE ? '#999' : '#24c382', cursor: 'pointer', fontSize: '20px' }} /> : <DislikeOutlined style={{ color: cesta.TEXTO_LIBRE ? '#999' : 'red', cursor: 'pointer', fontSize: '20px' }} />}
                                                                    </MDBBtn>

                                                                </div>
                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn disabled={!cesta.DISPONIBILIDAD} onClick={e => {
                                                                        createRipple(e);

                                                                        if (cesta.ES_NEUMATICO) {
                                                                            store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: true, REFERENCIA_FABRICANTE: cesta.REFERENCIA_TECDOC } })
                                                                            setFichaCestaNeumatico(true);


                                                                            payloadNeumatico.ID_MARCA = cesta.MARCA_TECDOC
                                                                            payloadNeumatico.NOMRE_MARCA = ""
                                                                            payloadNeumatico.EAN = ""

                                                                            payloadNeumatico.DETALLE_ERP_SOCIO = {

                                                                                NETO: cesta.NETO,
                                                                                PRECIO: cesta.PRECIO,
                                                                                PVP: 0,
                                                                                DESCUENTO: cesta.DESCUENTO,
                                                                                CODIGO_MARCA_SOCIO: cesta.MARCA_ERP,

                                                                                REFERENCIA_SOCIO: cesta.REFERENCIA_ERP,
                                                                                REFERENCIA_FABRICANTE: cesta.REFERENCIA_TECDOC
                                                                            }


                                                                            payloadNeumatico.CODIGO_MARCA = cesta.MARCA_TECDOC
                                                                            payloadNeumatico.REFERENCIA = cesta.REFERENCIA_TECDOC
                                                                            payloadNeumatico.REFERENCIA_PROVEEDOR = cesta.REFERENCIA_ERP
                                                                            payloadNeumatico.CANTIDAD = cesta.CANTIDAD
                                                                            payloadNeumatico.CANTIDAD_SELECCIONADA = cesta.CANTIDAD
                                                                            payloadNeumatico.ALMACEN = cesta.ALMACEN
                                                                            payloadNeumatico.IDCESTA = cesta.ID
                                                                            payloadNeumatico.CLIENTE = cesta.CLIENTE
                                                                            payloadNeumatico.REGISTROS = cesta
                                                                            payloadNeumatico.VEHICULO = {
                                                                                MATRICULA: cesta.MATRICULA,
                                                                                BASTIDOR: cesta.BASTIDOR,
                                                                                MARCA_VEHICULO: cesta.MARCA_VEHICULO,
                                                                                MODELO_VEHICULO: cesta.MODELO_VEHICULO,
                                                                                MOTOR_VEHICULO: cesta.MOTOR_VEHICULO,
                                                                                VEHICULO_TECDOC: { ID: cesta.ID_VEHICULO_TECDOC === undefined || cesta.ID_VEHICULO_TECDOC === null ? 0 : cesta.ID_VEHICULO_TECDOC }
                                                                            }

                                                                            setPayloadNeumatico({ ...payloadNeumatico })


                                                                        } else {
                                                                            setFichaCesta(true)
                                                                            setReferenciaSeleccionada({

                                                                                REFERENCIA: cesta.REFERENCIA_TECDOC,
                                                                                CODIGO_MARCA: cesta.MARCA_TECDOC,
                                                                                REFERENCIA_PROVEEDOR: cesta.REFERENCIA_ERP,
                                                                                CANTIDAD: cesta.CANTIDAD,
                                                                                CANTIDAD_SELECCIONADA: cesta.CANTIDAD,
                                                                                ALMACEN: cesta.ALMACEN,
                                                                                IDCESTA: cesta.ID,
                                                                                CLIENTE: cesta.CLIENTE,
                                                                                REGISTROS: cesta,
                                                                                VEHICULO: {
                                                                                    MATRICULA: cesta.MATRICULA,
                                                                                    BASTIDOR: cesta.BASTIDOR,
                                                                                    MARCA_VEHICULO: cesta.MARCA_VEHICULO,
                                                                                    MODELO_VEHICULO: cesta.MODELO_VEHICULO,
                                                                                    MOTOR_VEHICULO: cesta.MOTOR_VEHICULO,
                                                                                    VEHICULO_TECDOC: { ID: cesta.ID_VEHICULO_TECDOC === undefined || cesta.ID_VEHICULO_TECDOC === null ? 0 : cesta.ID_VEHICULO_TECDOC }
                                                                                }
                                                                            })

                                                                        }

                                                                    }}
                                                                        className="btn-matricula  estiloBotonRemove paddindDeTres">
                                                                        <EditOutlined style={{ color: !cesta.DISPONIBILIDAD ? '#999' : '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>

                                                                <div style={{ flexGrow: '3', textAlign: 'left', margin: '0px 2px' }}>
                                                                    <MDBBtn onClick={e => {
                                                                        createRipple(e);
                                                                        estadoCestaCompra(cesta)
                                                                    }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                        <DeleteOutlined style={{ color: '#333', cursor: 'pointer', fontSize: '20px' }} />
                                                                    </MDBBtn>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>

                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr style={{ borderTop: '1px solid #999' }}>
                                                <td></td>
                                                <td style={{ width: '200px', textAlign: 'right', color: '#999', fontSize: '20px', fontWeight: '500' }}>{traducirPagina("Total_unidades") + ": "}</td>
                                                <td style={{ width: '100px', textAlign: 'right', color: '#000', fontSize: '20px', fontWeight: '500' }}>{totales.unidades} </td>
                                                <td style={{ width: '270px', textAlign: 'right', color: '#999', fontSize: '20px', fontWeight: '500' }}>{traducirPagina("Total_pedido") + ": "}</td>
                                                <td style={{ width: '200px', textAlign: 'right', color: '#000', fontSize: '20px', fontWeight: '500' }}>{convertirMoneda(totales.pedidos, "€")} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                    <MDBCol md="9">
                                        <div>
                                            <span className="skeleton-box" style={{ width: '900px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '900px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '800px' }}></span>
                                        </div>
                                    </MDBCol>
                                </div>
                        }
                    </Card>
                </div>

                <Modal
                    visible={esperarPedido ? true : visible}
                    width={700}
                    centered
                    title={esCrearPresupuesto ? traducirPagina("Confirmar_presupuesto") : traducirPagina("Confirmación_pedido")}
                    afterClose={() => {
                        setDatosCompra({
                            observacionesConsulta: "",
                            observacionesPedido: "",
                            numeroPedido: "",
                            pedidoPor: "",
                            recibirMailPedido: false,
                            lineasPedido: "",
                            recogerArticulo: false
                        });
                        cargarCestaLineas();

                    }}

                    onCancel={() => SetVisible(false)}
                    footer={[

                        esperarPedido ?
                            <span style={{ fontSize: '20px', fontWeight: '500', color: '#4285f4' }}>{esCrearPresupuesto ? traducirPagina("Msg_espera_presupuesto") : traducirPagina("Msg_espera_pedido")}</span>
                            :
                            <div className='container_Pedido_final'>
                                <div className='pedido_final_item2'>
                                    {!esCrearPresupuesto && (PLANTILLA !== "" && PLANTILLA.toUpperCase() === "NEXUS") ?
                                        <div>
                                            <Checkbox name="recogerArticulo" checked={datosCompra.recogerArticulo} onChange={(e) => guardarDatos(e)} > {traducirPagina("Msg_recoger_articulo")}   </Checkbox>
                                        </div>
                                        :
                                        null
                                    }


                                    <div>
                                        <Button disabled={esperarPedido} className="btnColor" key="submit" type="primary" onClick={() => crearPedidoDeLineas(datosCompra, esCrearPresupuesto)}>{esCrearPresupuesto ? traducirPagina("Finalizar") : traducirPagina("Finalizar_pedido")} </Button>
                                    </div>
                                </div>
                            </div>

                    ]}>
                    <Row>
                        <Col md={24} >
                            {centros !== null ?
                                centros.length > 1 ?
                                    <span>{`${traducirPagina("Direccion_entrega")}: `} </span>
                                    : null
                                :
                                null
                            }
                        </Col>

                        <Col md={24}>
                            {centros !== null ?
                                centros.length > 1 ?
                                    <Select
                                        showSearch
                                        value={centroSeleccionado}
                                        placeholder={traducirPagina("Seleccion_centro").toUpperCase()}
                                        style={{ width: "100%", textAlign: 'left' }}
                                        optionFilterProp="children"
                                        onChange={(e) => { SetCentroSeleccionado(e) }}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {centros.map((registro, i) =>
                                            <Option value={registro.CODIGO} key={i}>{registro.DIRECCION}</Option>
                                        )}
                                    </Select>
                                    :
                                    null
                                :
                                null}
                        </Col>

                        <Col md={12} >
                            <span>{traducirPagina("Pedido_por") + ": "} </span>
                        </Col>
                        <Col md={12}>
                            <span>{esCrearPresupuesto ? traducirPagina("Numero_presupuesto") : traducirPagina("Numero_pedido")}</span>
                        </Col>
                        <Col md={12} >
                            <Input name="pedidoPor" value={datosCompra.pedidoPor} onChange={(e) => guardarDatos(e)} style={{ width: '320px' }} />
                        </Col>
                        <Col md={12}>
                            <Input name="numeroPedido" value={datosCompra.numeroPedido} onChange={(e) => guardarDatos(e)} style={{ width: '320px' }} />
                        </Col>
                        <Col md={24} className="mt-2">
                            <span>{traducirPagina("Observaciones")} </span>
                        </Col>

                        <Col md={24}>
                            <Input.TextArea name="observacionesPedido" value={datosCompra.observacionesPedido} onChange={(e) => guardarDatos(e)} rows={5} style={{ width: '645px' }} />
                        </Col>

                        <Col md={12} className="mt-2">
                        </Col>

                        <Col md={12} className="mt-2">
                            <Checkbox name="recibirMailPedido" checked={datosCompra.recibirMailPedido} onChange={(e) => guardarDatos(e)} > {traducirPagina("Recibir_email")}   </Checkbox>
                        </Col>

                    </Row>
                </Modal>






                <Modal
                    visible={visibleConsulta}
                    width={700}
                    centered
                    title={traducirPagina("Realizar_pedido")}
                    onCancel={() => { SetVisibleConsulta(false); modificarEstadoConsulta() }}
                    footer={[
                        <Button className="btnColor" key="submit" type="primary" onClick={() => { addConsulta(datosCompra.observacionesConsulta); SetVisibleConsulta(false); modificarEstadoConsulta() }}>{traducirPagina("Añadir_consulta")} </Button>
                    ]}>
                    <Row>
                        <Col md={24} className="mt-2">
                            <span>{traducirPagina("Observaciones")} </span>
                        </Col>
                        <Col md={24}>
                            {/* onChange={(e) => cambiosConsulta(e.target.value)} e.target.checked */}
                            <Input.TextArea name="observacionesConsulta" value={datosCompra.observacionesConsulta} onChange={(e) => guardarDatos(e)} allowClear rows={5} style={{ width: '645px' }} />
                        </Col>
                    </Row>
                </Modal>

                <Modal
                    visible={visiblePedido}
                    width={700}
                    centered
                    title={esCrearPresupuesto ? traducirPagina("Respuesta_crear_presupuesto").toUpperCase() : traducirPagina("Respuesta_realizar_pedido").toUpperCase()}
                    onCancel={() => { SetVisiblePedido(false) }}
                    footer={null}
                >

                    <List
                        header={<div style={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>{esCrearPresupuesto ? traducirPagina("Seguimiento_presupuesto").toUpperCase() : traducirPagina("Seguimiento_pedidos").toUpperCase()} </div>}
                        bordered
                        dataSource={pedidosRealizados}
                        renderItem={pedido => (
                            <List.Item>
                                <div style={{ fontSize: '18px' }}>
                                    <div><span style={{ fontWeight: 'bold' }}>{`${traducirPagina("Almacen").toUpperCase()}:`}</span>   <span>{pedido.ALMACEN}</span> </div>
                                    <div><span style={{ fontWeight: 'bold', color: pedido.REALIZADO ? '#000' : '#b41e23' }}>{(pedido.REALIZADO ? esCrearPresupuesto ? traducirPagina("Presupuesto_realizado").toUpperCase() : traducirPagina("Pedido_realizado").toUpperCase() : pedido.MENSAJE_ERROR !== undefined && pedido.MENSAJE_ERROR !== null ? esCrearPresupuesto ? `${traducirPagina("Presupuesto_no_realizado").toUpperCase()}: ` : `${traducirPagina("Pedido_no_realizado").toUpperCase()}: ${pedido.MENSAJE_ERROR.toUpperCase()}` : "")}</span>  </div>
                                    <div><span style={{ fontWeight: 'bold' }}>{esCrearPresupuesto ? `${traducirPagina("Codigo_presupuesto").toUpperCase()}:` : `${traducirPagina("Codigo_pedido").toUpperCase()}:`}</span>  <span>{pedido.CODIGO_PEDIDO}</span> </div>
                                </div>

                            </List.Item>

                        )}
                    />
                </Modal>




                {fichaCesta ?

                    <FichaAñadirCesta
                        title={traducirPagina("Editar_referencia_a_la_cesta").toUpperCase()}
                        ABRIR_FICHA={fichaCesta}
                        CERRAR_FICHA={setFichaCesta}
                        DATOS_REFERENCIA={referenciaSeleccionada}
                        ES_CESTA_COMPRA={true}
                        CARGAR_CESTA={cargarCestaLineas}
                    >

                    </FichaAñadirCesta>
                    :
                    null
                }


                {
                    abrirStockNeumatico &&
                    <FichaStockNeumatico
                        title={traducirPagina("Desglose_disponibilidad").toUpperCase()}
                        ABRIR_FICHA={abrirStockNeumatico}
                        CERRAR_FICHA={setAbrirStockNeumatico}
                        REFERENCIA_FABRICANTE={referenciaSeleccionada.REFERENCIA}
                        REFERENCIA_ERP={referenciaSeleccionada.REFERENCIA_PROVEEDOR}
                        EAN={""}
                        ID_MARCA={referenciaSeleccionada.CODIGO_MARCA}
                    >

                    </FichaStockNeumatico>
                }


                {
                    fichaCestaNeumatico &&
                    <FichaAgregarArticuloGenerico
                        title={traducirPagina("Editar_referencia_a_la_cesta").toUpperCase()}
                        ABRIR_FICHA={fichaCestaNeumatico}
                        CERRAR_FICHA={setFichaCestaNeumatico}
                        REFERENCIA_FABRICANTE={payloadNeumatico.DETALLE_ERP_SOCIO.REFERENCIA_FABRICANTE}
                        REFERENCIA_ERP={payloadNeumatico.DETALLE_ERP_SOCIO.REFERENCIA_SOCIO}
                        EAN={""}
                        ID_MARCA={payloadNeumatico.CODIGO_MARCA}

                        DATOS_REFERENCIA={payloadNeumatico}
                        ES_CESTA_COMPRA={true}
                        CARGAR_CESTA={cargarCestaLineas}
                    >

                    </FichaAgregarArticuloGenerico>
                }



            </div>
        </Drawer>
    )
}
CestaCompra.propTypes = {
    onClick: PropTypes.func,
    fichaBusquedaVehiculo: PropTypes.object,
    tipoTabla: PropTypes.string,
    referencia: PropTypes.string,
    marca: PropTypes.number
};

const mapStateToProps = (state, props) => {
    return {
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        datosGraficos: state.datosGraficos,
        hayReferencias: state.hayReferencias,
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        allCourse: state.allCourse,
        openDrawerCarroCompra: state.estadoDrawer.openDrawerCarroCompra,
        openDrawerIntegraciones: state.openDrawerIntegraciones,
        actualizarCestaLineas: state.actualizarCestaLineas,
        referenciaActual: state.referenciaActual,
        ClientesMostrador: state.ClientesMostrador

    };
}

export default connect(mapStateToProps)(CestaCompra);