import React, { useEffect } from 'react';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn } from 'mdbreact';
import { ObtenerEngNoByEngCodeEnAPI } from "../action/actionCreatorsRequests";

import { cambioNombreBusscadorCoche, createRipple, efectoBotonRemove, changeStateInputCoche, cambioImagenPais } from "../action/actionCreators";


import { busquedaCochePorMatriculaOBastiidor, cambiarEstadoDropVehiculo, traducirPagina, datosLincenciaSertec, MSGERROR, obtenerRegistroQuery } from '../librerias/funciones'
import { connect } from 'react-redux';
import {
    SearchOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import store from '../store'
import FichaCodigoMotor from './FichaCodigoMotor';


const CptInputCoche = ({ nameDropDownCar, configApp, fichaBusquedaVehiculo, idiomaActual, usuario, DROP_SELECCION_COCHE, props }) => {
    const [motores, setMotores] = React.useState([]);
    const { banderaImagen, codPaisIdioma } = fichaBusquedaVehiculo
    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { PAISES } = OBTENER_DATOS_USUARIO === undefined ? { PAISES: [] } : OBTENER_DATOS_USUARIO;
    const [fichaAbierta, setFichaAbierta] = React.useState(false);
    const [textoABuscar, setTextoABuscar] = React.useState("");
    const parametros = props.listaValoresDrop !== undefined ? JSON.parse(props.listaValoresDrop) : {}
    const menuBanderas = PAISES




    const verificarIntroParaVehiculo = (e, habiitar) => {
        if (e.key === 'Enter' || habiitar) {
            if (Object.keys(parametros).length > 0) {
                if (DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "BAS" || DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "MAT") {
                    busquedaCochePorMatriculaOBastiidor(false);

                } else if (DROP_SELECCION_COCHE.IDENTIFICADOR.toUpperCase() === "CODM") {
                    ObtenerEngNoByEngCodeEnAPI(textoABuscar).then(registro => {
                        if (registro !== undefined) {
                            if (registro.REGISTROS !== null && registro.REGISTROS.length > 0) {
                                setMotores(registro.REGISTROS)
                                setFichaAbierta(true)
                            } else {
                                setMotores(null)
                                MSGERROR(traducirPagina("msgMotor"))
                            }
                        }
                    })
                }
            }
        }
    }

    useEffect(() => {
/*
        setTimeout(() => {
            let registro = obtenerRegistroQuery("Plate")
            if (registro !== null) {
                setTextoABuscar(registro);
                store.dispatch(changeStateInputCoche(registro, parametros.id));
                efectoBotonRemove(registro, parametros.id)
                if (props.EVENTO_CHANGE !== undefined) {
                    props.EVENTO_CHANGE(registro)
                }

                verificarIntroParaVehiculo({ key: "Enter" }, false)

            }
        }, 1000)
*/
    }, [])



    return (
        Object.keys(parametros).length > 0 ?
            <div id={"contenedor" + parametros.id} className="grid-container" style={parametros.id === "dropcliente" ? { float: 'right' } : {}} >
                {parametros.nombreDrop === "" ? null :
                    <div className="grid-item" >
                        {usuario.LoginSertec.List_LicenciaSERTE !== null ?
                            <>
                                <MDBDropdown>
                                    <MDBDropdownToggle caret className="boton-Coche dropCoche" style={{ padding: '.05rem .5rem', texttransform: 'none', marginRight: '5px', paddingRight: '2px' }} onClick={e => { cambiarEstadoDropVehiculo() }}>
                                        <span className="estiloTexto"
                                            title={usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS ? "MATRICULA" : usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_BASTIDORES ? "BASTIDOR" : "CODM"}
                                            id="busquedaVehiculo"> {usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS || usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_BASTIDORES ? DROP_SELECCION_COCHE.NAME_Drop : traducirPagina("Cod_motor")}</span>
                                    </MDBDropdownToggle>


                                    <MDBDropdownMenu onClick={e => { store.dispatch(cambioNombreBusscadorCoche(e.target)) }}>
                                        <MDBDropdownItem className="soloColorBlanco estiloTexto active" id="MAT" style={{ display: usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS ? '' : 'none' }}>{traducirPagina("Matricula")}</MDBDropdownItem>
                                        <MDBDropdownItem className="soloColorBlanco estiloTexto" id="BAS" style={{ display: usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_BASTIDORES ? '' : 'none' }}>{traducirPagina("Bastidor")} </MDBDropdownItem>
                                        <MDBDropdownItem className="soloColorBlanco estiloTexto" id="CODM">{traducirPagina("Cod_motor")} </MDBDropdownItem>
                                    </MDBDropdownMenu>

                                </MDBDropdown>


                                <MDBDropdown style={{ cursor: 'pointer' }}>

                                    <MDBDropdownToggle style={{ border: 'none', verticalAlign: 'bottom', padding: '0px', margin: '0px 8px', display: usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_MATRICULAS || usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_BASTIDORES ? '' : 'none' }} onClick={e => { cambiarEstadoDropVehiculo() }} >

                                        <span className="estiloTexto" id="paisVehiculo" title={codPaisIdioma} > <img src={banderaImagen} style={{ height: '24px' }} /> </span>

                                    </MDBDropdownToggle>

                                    <MDBDropdownMenu>
                                        {menuBanderas.map((registro) =>
                                            <MDBDropdownItem onClick={e => {
                                                store.dispatch({
                                                    type: "REPLACE_BANDERA_PAIS",
                                                    banderaImagen: registro.URL_IMAGEN,
                                                    codPaisIdioma: registro.CODIGO_TECDOC
                                                })

                                            }} id={registro.ID_PAIS}><span className="estiloTexto"> <img src={registro.URL_IMAGEN} style={{ height: '24px', verticalAlign: 'botton' }} /> {registro.NOMBRE}</span> </MDBDropdownItem>

                                        )}
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </>

                            : null
                        }



                    </div>
                }
                {parametros.nombreDrop === "" ? null :
                    <div className="grid-item barraLateral"></div>
                }

                <div className="grid-item">
                    <input id={"imput" + parametros.id}
                        //value={textoABuscar}
                        className="form-control boxCoche"
                        type="text" aria-label="Search"
                        placeholder={traducirPagina(parametros.placeholder)}
                        name={parametros.id}
                        style={{ backgroundColor: "#eee", width: parametros.width !== undefined ? parametros.width : parametros.id === "dropmodelos" || parametros.id === "dropmotorizacion" ? '480px' : parametros.id === "dropmarcas" ? '450px' : configApp.width < 800 ? '170px' : '260px', border: '0' }}
                        onChange={e => {
                            setTextoABuscar(e.target.value);
                            store.dispatch(changeStateInputCoche(e.target.value, parametros.id));
                            efectoBotonRemove(e.target.value, parametros.id)
                            if (props.EVENTO_CHANGE !== undefined) {
                                props.EVENTO_CHANGE(e.target.value)
                            }
                        }} autoComplete="off"
                        onKeyPress={(e) => { verificarIntroParaVehiculo(e, false) }} />
                </div>

                <div className="grid-item">
                    <MDBBtn id={parametros.id} onClick={e => { createRipple(e);  setTextoABuscar(""); efectoBotonRemove("", parametros.id); store.dispatch(changeStateInputCoche("", parametros.id)); }} className="btn-matricula  estiloBotonRemove" style={{ visibility: 'hidden' }}> <CloseOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} /> </MDBBtn>
                </div>

                <div className="grid-item barraLateral" ></div>

                <div className="grid-item">
                    {parametros.nombreDrop === "" ?
                        <MDBBtn onClick={e => { createRipple(e); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                        :
                        <MDBBtn onClick={e => { createRipple(e); verificarIntroParaVehiculo(e, true); }} style={{ border: '0', padding: '6px 1px 5px 7px', margin: '0', boxShadown: 'none', backgroundColor: 'transparent !important' }} className="btn-matricula">  <SearchOutlined style={{ color: '#666', cursor: 'pointer', fontSize: '20px' }} />  </MDBBtn>
                    }
                </div>


                {fichaAbierta ?
                    <FichaCodigoMotor
                        title={traducirPagina("Cod_motor").toUpperCase()}
                        FICHA_ABIERTA={fichaAbierta}
                        CERRAR_ABIERTA={setFichaAbierta}
                        MOTORES={motores}
                        MODIFICAR_MOTORES={setMotores}
                    >
                    </FichaCodigoMotor>
                    :
                    null
                }


            </div>

            : null
    );


}


const mapStateToProps = (state, props) => {
    return {
        nameDropDownCar: state.nameDropDownCar,
        DROP_SELECCION_COCHE: state.DROP_SELECCION_COCHE,
        buscadorCoche: state.buscadorCoche,
        configApp: state.configApp,
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        idiomaActual: state.idiomaActual,
        usuario: state.usuario,
        props: props,

    };
}




export default connect(mapStateToProps)(CptInputCoche);