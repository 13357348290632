import store from '../store';
import grafico_carroceria0 from "../graficos/procesado_carroceria.json"
import grafico_necanica0 from "../graficos/mecanica0.json"
import grafico_necanica1 from "../graficos/procesado_mecanica_1.json"
import grafico_necanica2 from "../graficos/procesado_mecanica_2.json"


import {
    estadoAgrupacionDeMarcas, updateStateMasterPage, showItemReferencia, showChildrenDrawerLevel2, showChildrenDrawerIntegraciones, activeItemTipoIntegracion, setHistoricApp, updateVisualizacionFiltro, gestionarContenedorCoche, removeAllEngineType, removeAllModel, showItemCar, referenciaActual,
    detenerBusquedaDetalles, mostrarVistaGrafica, DrawerFichaCocheDGT, actulizarNivelGrafico, onChildrenDrawerCloseIntegraciones, deleteItemHistoricApp, clienteSeleccionado, efectoBotonRemove,
    DrawerHistoricoCoche,
} from "../action/actionCreators";
import { ObtenerArticulosByDescripcionEnAPI, logOutSesion, buscarMatriculaCoche, buscarBastidorCoche, getFichaCoche, getIdTecdocMotorByIdHaynes, getEngineType, getModelVehicle, getIdVehicleByTecdocNumber, getPricePageOEM, buscarReferenciaEnAPI, getListSuplier, ObtenerArticuloERPEnAPI, SetPortapapelesPresupuestosEnAPI, abortFetch, getFichaCocheEnAPI, setCestaLineasEnAPI, getFabricantesEnAPI, getModelosTurismosEnAPI, getMotorizacionesTurismosEnAPI, ObtenerNotificacionesEnAPI, SetPortapapelesPresupuestosFlotasEnAPI, getDiccionarioEnAPI, setHistorialBusqueda } from "../action/actionCreatorsRequests";
import alertify from 'alertifyjs';
import history from '../history';





export function referenciaActualSelect(item) {
    store.dispatch(referenciaActual(item));
}


export function obtenerTipoOrdenacion(listaDeReferencias) {

    let condicion = null;
    /**ALMACEN REGUALADOR */
    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.STOCK_REGULADOR === false)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 78); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.STOCK_REGULADOR === false)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 72); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_TOTAL > 0 && element.STOCK_REGULADOR === false)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 66); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_TOTAL > 0 && element.STOCK_REGULADOR === false)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 60); }
    /**FIN DE ALMACEN REGULADOR */

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.PRECIO >= 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 54); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.PRECIO >= 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 48); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL > 0 && element.PRECIO >= 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 42); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL > 0 && element.PRECIO >= 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 36); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO > 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 30); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO > 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 24); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 18); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 12); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR === "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 6); }

    condicion = listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR === "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0)
    if (condicion !== undefined) { return (condicion.ORDEN_MARCA + 0); }


    if (listaDeReferencias.find(element => element.PRECIO === null) !== undefined) { return -1; }
    return 1;
}


/*

OLD
export function obtenerTipoOrdenacion(listaDeReferencias) {

if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.STOCK_REGULADOR === false) !== undefined) { return 13; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.STOCK_REGULADOR === false) !== undefined) { return 12; }

if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_TOTAL > 0 && element.STOCK_REGULADOR === false) !== undefined) { return 11; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_TOTAL > 0 && element.STOCK_REGULADOR === false) !== undefined) { return 10; }


if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.PRECIO >= 0) !== undefined) { return 9; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN > 0 && element.PRECIO >= 0) !== undefined) { return 8; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL > 0 && element.PRECIO >= 0) !== undefined) { return 7; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL > 0 && element.PRECIO >= 0) !== undefined) { return 6; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO > 0) !== undefined) { return 5; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO > 0) !== undefined) { return 4; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0) !== undefined) { return 3; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0) !== undefined) { return 2; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === true && element.REFERENCIA_PROVEEDOR === "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0) !== undefined) { return 1; }
if (listaDeReferencias.find(element => element.MARCA_PATROCINADA === false && element.REFERENCIA_PROVEEDOR === "" && element.STOCK_ALMACEN === 0 && element.STOCK_TOTAL === 0 && element.PRECIO === 0) !== undefined) { return 0; }


if (listaDeReferencias.find(element => element.PRECIO === null) !== undefined) { return -1; }
return 1;
}
*/

export function obtenerListaOrdenadaPorStock(listaDeReferencias) {
    let lista = listaDeReferencias.sort(dynamicSortMultipleDesc("STOCK_ALMACEN", "STOCK_TOTAL", "NETO_ORDENADO"));

    return lista;
}


export function obtenerStockAlmacen(listaDeReferencias) {
    let stockMaximo = 0;
    let item = listaDeReferencias.sort(dynamicSortDesc("STOCK_ALMACEN"));
    stockMaximo = item[0].STOCK_ALMACEN;
    if (stockMaximo === 0) {
        let item2 = listaDeReferencias.sort(dynamicSortDesc("STOCK_TOTAL"));
        stockMaximo = item2[0].STOCK_TOTAL;
    }
    return stockMaximo;
}

export function obtenerPrecioMinimo(listaDeReferencias) {
    let precioMinimo = 0;
    let filtroNeto = listaDeReferencias.filter((registro) => registro.NETO !== 0);
    if (filtroNeto.length === 0) {
        precioMinimo = 0;
    } else {
        let item = filtroNeto.sort(dynamicSortAsc("NETO"));
        precioMinimo = item[0].NETO;
    }

    return precioMinimo;
}
export function obtenerMarcaPatrocinada(listaDeReferencias) {
    let marcaPatrocinada = false;
    let item = listaDeReferencias.sort(dynamicSortAsc("MARCA_PATROCINADA"));
    marcaPatrocinada = item[0].MARCA_PATROCINADA;

    return marcaPatrocinada;
}


export function ordenarGruposMarcas(listaDeGrupoDeMarcas) {
    console.log("Ordenacion", listaDeGrupoDeMarcas)
    let lista = listaDeGrupoDeMarcas.sort(dynamicSortMultiple(["tipoOrdenacion", "D"], ["stock", "D"], ["precio", "A"]));

    return lista;
}


export function dynamicSortDesc(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}


export function dynamicSortAsc(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}


export function dynamicSortAscString(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export function dynamicSortMultipleDesc() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSortDesc(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

export function dynamicSortMultiple() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;

        while (result === 0 && i < numberOfProperties) {
            if (props[i][1] === "D") {
                result = dynamicSortDesc(props[i][0])(obj1, obj2);
            } else {
                result = dynamicSortAsc(props[i][0])(obj1, obj2);
            }
            i++;
        }
        return result;
    }
}


export function dynamicSortMultipleAsc() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;

        while (result === 0 && i < numberOfProperties) {
            result = dynamicSortAsc(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

export function obtenerPaginas(registros, numeroRegistroPorPagina) {
    let numeroDePaginas = 0; let numReferenciasPagina = 0;

    let listaMarcas = registros.marcas;

    if (registros.marcasSeleccionadas !== undefined) {
        if (registros.marcasSeleccionadas.length > 0) {
            listaMarcas = registros.marcasSeleccionadas
        }
    }
    let listaMarcasOrdenadas = [];

    listaMarcas.map((marca) => {
        let listaDeReferenciasDeLaMarca = registros.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
        if (listaDeReferenciasDeLaMarca.length > 0) {
            let tipoOrdenacionDeLaMarca = obtenerTipoOrdenacion(listaDeReferenciasDeLaMarca);
            let stockMaximoDeLaMarca = obtenerStockAlmacen(listaDeReferenciasDeLaMarca);
            let precioMinimoDeLaMarca = obtenerPrecioMinimo(listaDeReferenciasDeLaMarca);
            //let marcaPatrocinada = obtenerMarcaPatrocinada(listaDeReferenciasDeLaMarca)



            listaMarcasOrdenadas.push({
                marca: marca,
                tipoOrdenacion: tipoOrdenacionDeLaMarca,
                stock: stockMaximoDeLaMarca,
                precio: precioMinimoDeLaMarca,
            })
        }
    })


    listaMarcasOrdenadas = ordenarGruposMarcas(listaMarcasOrdenadas);


    listaMarcas = [];
    listaMarcasOrdenadas.map((marcaOrdenada) => {
        listaMarcas.push(marcaOrdenada.marca);
    })

    let paginas = []; let marcaIncio = ''; let marcaFin = ''; let inicial = 0;

    for (var i = 0; i < listaMarcas.length; i++) {
        if (inicial === 0) {
            marcaIncio = listaMarcas[i];
            inicial = 1;
        }

        let numeroDeReferenciasEnLaMarca = registros.referencias.filter(registro => registro.NOMRE_MARCA === listaMarcas[i]);

        numReferenciasPagina += numeroDeReferenciasEnLaMarca.length;
        if (i === (listaMarcas.length - 1)) {
            numeroRegistroPorPagina = numReferenciasPagina
        }

        if (numReferenciasPagina >= numeroRegistroPorPagina) {
            marcaFin = listaMarcas[i];

            numeroDePaginas += 1;

            paginas.push({ marcaIncio: marcaIncio, marcaFin: marcaFin });
            inicial = 0;

            numReferenciasPagina = 0;
        } else {

            marcaFin = listaMarcas[i];
        }
    }
    return paginas;
}


export function obtenerPaginasNeumaticos(registros, numeroRegistroPorPagina) {
    let numeroDePaginas = 0; let numReferenciasPagina = 0;

    let listaMarcas = registros.marcas;

    if (registros.marcasSeleccionadas !== undefined) {
        if (registros.marcasSeleccionadas.length > 0) {
            listaMarcas = registros.marcasSeleccionadas
        }
    }
    let listaMarcasOrdenadas = [];

    listaMarcas.map((marca) => {
        let listaDeReferenciasDeLaMarca = registros.neumaticos.filter(referencia => referencia.MARCA_DESCRIPCION === marca);
        if (listaDeReferenciasDeLaMarca.length > 0) {
            /*
            let tipoOrdenacionDeLaMarca = obtenerTipoOrdenacion(listaDeReferenciasDeLaMarca);
            let stockMaximoDeLaMarca = obtenerStockAlmacen(listaDeReferenciasDeLaMarca);
            let precioMinimoDeLaMarca = obtenerPrecioMinimo(listaDeReferenciasDeLaMarca);
*/
            listaMarcasOrdenadas.push({
                marca: marca,
                tipoOrdenacion: 1,
                stock: 1,
                precio: 1
            })
        }
    })


    listaMarcasOrdenadas = ordenarGruposMarcas(listaMarcasOrdenadas);

    listaMarcas = [];
    listaMarcasOrdenadas.map((marcaOrdenada) => {
        listaMarcas.push(marcaOrdenada.marca);
    })

    let paginas = []; let marcaIncio = ''; let marcaFin = ''; let inicial = 0;

    for (var i = 0; i < listaMarcas.length; i++) {
        if (inicial === 0) {
            marcaIncio = listaMarcas[i];
            inicial = 1;
        }

        let numeroDeReferenciasEnLaMarca = registros.neumaticos.filter(registro => registro.MARCA_DESCRIPCION === listaMarcas[i]);

        numReferenciasPagina += numeroDeReferenciasEnLaMarca.length;
        if (i === (listaMarcas.length - 1)) {
            numeroRegistroPorPagina = numReferenciasPagina
        }

        if (numReferenciasPagina >= numeroRegistroPorPagina) {
            marcaFin = listaMarcas[i];

            numeroDePaginas += 1;

            paginas.push({ marcaIncio: marcaIncio, marcaFin: marcaFin });
            inicial = 0;

            numReferenciasPagina = 0;
        } else {

            marcaFin = listaMarcas[i];
        }
    }
    return paginas;
}



export function distinctMarcas(lista) {
    let lookup = {}; let distinctMarcas = [];
    if (lista !== undefined) {
        lista.map((item, pos) => {
            var name = item.NOMRE_MARCA;
            if (!(name in lookup)) {
                lookup[name] = 1;
                distinctMarcas.push(item);
            }
        })

        distinctMarcas = distinctMarcas.sort(dynamicSortAsc("NOMRE_MARCA"));
    }
    return distinctMarcas;
}

export function distinctGrupoMontaje(referencias) {
    let distinctGrupoMontajeLista = [];
    let lookup = {};
    if (referencias !== undefined) {
        referencias.map((item, pos) => {
            if (item.CODIGO_MARCA > 0) {
                var name = item.NOMBRE_GRUPO_MONTAJE;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    distinctGrupoMontajeLista.push(item);
                }
            }
        })
        distinctGrupoMontajeLista = distinctGrupoMontajeLista.sort(dynamicSortAsc("NOMBRE_GRUPO_MONTAJE"));
    }
    return distinctGrupoMontajeLista
}

export function buscarNodoTree(lista) {
    if (lista.children.length > 0) {
        let lista = []
        lista.push(lista.title);

        return buscarNodoTree(lista.children);
    } else {
        return lista.title
    }
}




export function modelarObjetoReferencia(referencias, marcasSeleccionadas, gruposSeleccionadas, ladosSeleccionados) {

    let agrupacionMarcas = [];
    let agrupacionGrupoMontaje = [];
    let grupoDeReferencias;
    let listaReferencias = [];
    referencias.map(referencia => {
        if (referencia.NETO === 0) {
            referencia.NETO_ORDENADO = -999999999;
        } else {
            referencia.NETO_ORDENADO = (referencia.NETO * -1)
        }
        listaReferencias.push(referencia)
    })

    /*+**************+++*MODELAR OBJETO REFERENCIA*******************************/
    agrupacionMarcas = referencias.map(item => item.NOMRE_MARCA)
        .filter((value, index, self) => self.indexOf(value) === index)
    agrupacionMarcas = agrupacionMarcas.sort((a, b) => a.localeCompare(b));

    agrupacionGrupoMontaje = referencias.map(item => item.NOMBRE_GRUPO_MONTAJE)
        .filter((value, index, self) => self.indexOf(value) === index)
    agrupacionGrupoMontaje = agrupacionGrupoMontaje.sort((a, b) => a.localeCompare(b));

    if (marcasSeleccionadas === undefined) { marcasSeleccionadas = []; }
    if (gruposSeleccionadas === undefined) { gruposSeleccionadas = []; }
    if (ladosSeleccionados === undefined) { ladosSeleccionados = []; }

    grupoDeReferencias = {
        marcas: agrupacionMarcas,
        referencias: listaReferencias,
        auxReferencia: listaReferencias,
        grupoMontaje: agrupacionGrupoMontaje,
        gruposMontajeSeleccionados: gruposSeleccionadas,
        ladosSeleccionados: ladosSeleccionados,
        marcasSeleccionadas: marcasSeleccionadas
    }
    return grupoDeReferencias;
}


export function modelarObjetoNeumaticos(neumaticos, marcasSeleccionadas) {

    let agrupacionMarcas = [];
    let listaNeumaticos = [];
    let grupoDeNeumaticos = {};

    neumaticos.map(neumatico => {
        /*
        if (referencia.NETO === 0) {
            referencia.NETO_ORDENADO = -999999999;
        } else {
            referencia.NETO_ORDENADO = (referencia.NETO * -1)
        }
        */
        listaNeumaticos.push(neumatico)
    })

    /*+**************+++*MODELAR OBJETO REFERENCIA*******************************/
    agrupacionMarcas = neumaticos.map(item => item.MARCA_DESCRIPCION)
        .filter((value, index, self) => self.indexOf(value) === index)
    agrupacionMarcas = agrupacionMarcas.sort((a, b) => a.localeCompare(b));



    if (marcasSeleccionadas === undefined) { marcasSeleccionadas = []; }

    grupoDeNeumaticos = {
        marcas: agrupacionMarcas,
        neumaticos: listaNeumaticos,
        auxNeumaticos: listaNeumaticos,
        marcasSeleccionadas: marcasSeleccionadas
    }
    return grupoDeNeumaticos;
}



export function filtrarReferenciasLocalmente(listaReferencias) {
    let marcasSeleccionadas = listaReferencias.marcasSeleccionadas;
    let grupoDeMontajeSeleccionados = listaReferencias.gruposMontajeSeleccionados;
    let ladosSeleccionados = listaReferencias.ladosSeleccionados;
    let listaReferenciasFiltradas = [];
    let objReferenciasFiltradas = listaReferencias;
    if (marcasSeleccionadas.length === 0) {
        listaReferenciasFiltradas = listaReferencias.referencias
    } else {
        listaReferenciasFiltradas = listaReferencias.referencias.filter(referencia => marcasSeleccionadas.includes(referencia.NOMRE_MARCA))
    }

    if (grupoDeMontajeSeleccionados.length === 0) {
        listaReferenciasFiltradas = listaReferenciasFiltradas
    } else {
        listaReferenciasFiltradas = listaReferenciasFiltradas.filter(referencia => grupoDeMontajeSeleccionados.includes(referencia.NOMBRE_GRUPO_MONTAJE))
    }

    if (ladosSeleccionados.length === 0) {
        listaReferenciasFiltradas = listaReferenciasFiltradas
    } else {
        let referencias = [];


        referencias = listaReferenciasFiltradas.filter((referencia) => referencia.LADO_MONTAJE !== undefined && referencia.LADO_MONTAJE !== null)


        if (referencias.length > 0) {
            referencias = referencias.filter((referencia) => ladosSeleccionados.includes(referencia.LADO_MONTAJE))
        }

        //console.log("PRUEBAS INTERNA",referencias)
        /*
             listaReferenciasFiltradas.map(registro => {
                 if (registro.LADO_MONTAJE !== null) {
 
                     for (let i = 0; i < ladosSeleccionados.length; i++) {
                         let n = registro.LADO_MONTAJE.search(ladosSeleccionados[i])
                         if (n !== -1) {
                             referencias.push(registro)
                             break;
                         }
 
                     }
                 }
             })
             console.log("PRUEBAS INTERNA",referencias)
         */

        listaReferenciasFiltradas = referencias;
    }
    objReferenciasFiltradas.referencias = listaReferenciasFiltradas;
    return objReferenciasFiltradas;
}


export function crearPaginacion(referencias, listaPaginacion) {


    let inicioRegistroPaginación = 40;
    if (Object.keys(listaPaginacion).length > 0) {
        inicioRegistroPaginación = listaPaginacion.numeroRegistroPagina === undefined ? 40 : listaPaginacion.numeroRegistroPagina;
    }

    let listaPaginas = obtenerPaginas(referencias, inicioRegistroPaginación);

    let paginationPropiedades = {

        "numeroTotalRegistro": referencias?.referencias?.length - 1,
        "numeroTotalRegistroPaginacion": listaPaginas.length * inicioRegistroPaginación,
        "numeroRegistroPagina": inicioRegistroPaginación,
        "numeroDePaginas": listaPaginas.length,
        "paginas": listaPaginas,
        "paginaAMostrar": 0
    }

    return paginationPropiedades;
}

export function crearPaginacionNeumaticos(referencias, listaPaginacion) {
    let inicioRegistroPaginación = 40;
    if (Object.keys(listaPaginacion).length > 0) {
        inicioRegistroPaginación = listaPaginacion.numeroRegistroPagina === undefined ? 40 : listaPaginacion.numeroRegistroPagina;
    }

    let listaPaginas = obtenerPaginasNeumaticos(referencias, inicioRegistroPaginación);
    let paginationPropiedades = {
        "numeroTotalRegistro": referencias.neumaticos.length - 1,
        "numeroTotalRegistroPaginacion": listaPaginas.length * inicioRegistroPaginación,
        "numeroRegistroPagina": inicioRegistroPaginación,
        "numeroDePaginas": listaPaginas.length,
        "paginas": listaPaginas,
        "paginaAMostrar": 0
    }

    return paginationPropiedades;
}



export function obtenerDatosUsuarioYLicenciaActual(activarMensaje) {
    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = {};
    if (getCookieUser !== null) {
        accesoLicencia = getCookieUser.LoginSertec.List_LicenciaSERTE[0];
    } else {
        let verificarMensaje = activarMensaje === undefined ? true : activarMensaje;
        if (verificarMensaje) {
            MSGERROR("LA PETICION NO ESTA LOGEADA");
        }
    }

    return accesoLicencia;
}


export function verficarSiEsCatalogo(usuario, verificarCondicionSugeridos = true) {
    let esCatalogo = true;
    const { ES_ARTICULO_SUGERIDO } = store.getState().SUGERIDOS;
    const registros = datosLincenciaUsuario();

    if (registros !== undefined) {
        esCatalogo = registros.SOLO_CATALOGO;
    }

    if (verificarCondicionSugeridos) {
        if (ES_ARTICULO_SUGERIDO) {
            esCatalogo = true
        }
    }
    if (registros !== undefined) {
        if (registros.CATALOGO_PVP && registros.CATALOGO_STOCKS) {
            esCatalogo = false
        }
    }

    return esCatalogo
}

export function es_Sugerido() {
    let esSugerido = false;
    const { ES_ARTICULO_SUGERIDO } = store.getState().SUGERIDOS;

    if (ES_ARTICULO_SUGERIDO) {
        esSugerido = true
    }

    return esSugerido
}

export function obtenerPuntos() {
    const DATOS_USUARIO = store.getState().usuario;
    let PUNTOS_REGALO = { PUNTOS: 0, ENNLACE: "", ID_SOCIO: 0 }

    if (DATOS_USUARIO.LoginSertec !== undefined) {
        const { ID_SOCIO, DATOS_FIDELIZACION_CLIENTE } = DATOS_USUARIO.LoginSertec;
        const { PUNTOS, URL } = DATOS_FIDELIZACION_CLIENTE === undefined || DATOS_FIDELIZACION_CLIENTE === null ? { PUNTOS: 0, URL: "" } : DATOS_FIDELIZACION_CLIENTE
        PUNTOS_REGALO.PUNTOS = PUNTOS
        PUNTOS_REGALO.URL = URL
        PUNTOS_REGALO.ID_SOCIO = ID_SOCIO


    }

    return PUNTOS_REGALO
}

export function verficarPreciosAsincronos() {
    let PRECIOS = true;

    const DATOS_USUARIO = store.getState().usuario;
    const { PRECIOS_ASINCRONOS } = DATOS_USUARIO.LoginSertec;

    if (PRECIOS_ASINCRONOS !== undefined && PRECIOS_ASINCRONOS !== null) {
        PRECIOS = PRECIOS_ASINCRONOS
    }
    return PRECIOS_ASINCRONOS
}

export function datosLincenciaUsuario() {
    let REGISTRO_USUARIO = undefined

    const DATOS_USUARIO = store.getState().usuario;
    if (DATOS_USUARIO.LoginSertec !== undefined) {
        REGISTRO_USUARIO = DATOS_USUARIO.LoginSertec.List_LicenciaSERTE[0];
    }

    return REGISTRO_USUARIO
}


export function datosLincenciaSertec() {
    let REGISTRO_USUARIO = undefined

    const DATOS_USUARIO = store.getState().usuario;
    if (DATOS_USUARIO.LoginSertec !== undefined) {
        REGISTRO_USUARIO = DATOS_USUARIO.LoginSertec;
    }

    return REGISTRO_USUARIO
}

export function datosServiciosSertec(NOMBRE_SERVICIO) {
    let REGISTRO_USUARIO = undefined
    let DATA_INICIAL = {
        CONTRATADO: false,
        ORIGEN: "HAY",
        PLATAFORMA: "",
        PWD: "",
        USUARIO: "",
    }

    const DATOS_USUARIO = store.getState().usuario;
    if (DATOS_USUARIO.LoginSertec !== undefined) {
        REGISTRO_USUARIO = DATOS_USUARIO.LoginSertec;

        const { SERVICIOS } = REGISTRO_USUARIO

        if (SERVICIOS !== undefined && SERVICIOS !== null && SERVICIOS.length > 0) {

            let resultado = SERVICIOS.find((registro) => registro.PLATAFORMA === NOMBRE_SERVICIO)
            if (resultado !== undefined) {
                const { CONTRATADO, ORIGEN, PLATAFORMA, PWD, USUARIO } = resultado
                DATA_INICIAL = {
                    CONTRATADO: CONTRATADO,
                    ORIGEN: ORIGEN,
                    PLATAFORMA: PLATAFORMA,
                    PWD: PWD,
                    USUARIO: USUARIO,
                }
            }

        }
    }

    return DATA_INICIAL
}




export function verficarSiEsPVP(usuario) {
    let esCatalogo = true;
    if (usuario.LoginSertec !== undefined) {
        esCatalogo = usuario.LoginSertec.List_LicenciaSERTE[0].VISUALIZAR_PVP;
    }
    return esCatalogo
}





export function colorCabeceraPorElPrecioDeLaReferencia(marcas) {
    //console.log("mae",marcas.referencias[0].MARCA_PATROCINADA)
    let colorPorDefecto = "#999"
    if (marcas.referencias !== undefined) {
        for (let i = 0; i < marcas.referencias.length; i++) {
            if (marcas.referencias[i].PRECIO > 0) {
                colorPorDefecto = "#1f1e1e"
                break;
            }
        }
    }

    for (let i = 0; i < marcas.referencias.length; i++) {
        if (marcas.referencias[i].MARCA_PATROCINADA) {
            colorPorDefecto = "#1b3867"
            break;
        }
    }

    return colorPorDefecto;
}

export function colorCabeceraFuente(marcas) {
    let colorPorDefecto = "#fff";

    for (let i = 0; i < marcas.referencias.length; i++) {
        if (marcas.referencias[i].MARCA_PATROCINADA) {
            colorPorDefecto = "#e4ba1c"
            break;
        }
    }
    return colorPorDefecto;
}


export function obtenerPathNodo(nodos) {
    let retornoNodos = "";
    /*
    nodos.map((nodo) => {
        retornoNodos = retornoNodos + nodo.Term + " " + " / ";
    })


    retornoNodos = retornoNodos.substring(0, retornoNodos.length - 2);
    */
    retornoNodos = nodos[nodos.length - 1].Term;
    return retornoNodos
}



export function obtenerPathNodoGrupos(nodos) {
    let retornoNodos = "";
    nodos.map((nodo) => {

        retornoNodos = retornoNodos + nodo.NOMBRE + " " + " / ";
    })
    retornoNodos = retornoNodos.substring(0, retornoNodos.length - 2);
    return retornoNodos
}




export function colorConsumo(LETRA) {
    let color = "#ccc";
    switch (LETRA) {
        case "A":
            color = "#02a650";
            break;
        case "B":
            color = "#9aca3d";
            break;
        case "C":
            color = "#fff200";
            break;
        case "D":
            color = "#fdb912";
            break;
        case "F":
            color = "#ed1d24";
            break;
        default:
            color = "#ccc";
            break;
    }
    return color;
}

export function colorAgarre(LETRA) {
    let color = "#ccc";
    switch (LETRA) {
        case "A":
            color = "#0066b3";
            break;
        case "B":
            color = "#0080c6";
            break;
        case "C":
            color = "#4aa7dd";
            break;
        case "D":
            color = "#75bee9";
            break;
        case "F":
            color = "#abe1fa";
            break;
        default:
            color = "#ccc";
            break;
    }
    return color;
}

export function mostrarPaginaPorFiltro(aplicarFiltroPor) {
    let listaMarca = store.getState().grupoDeMarcas;
    let listaPaginacion = store.getState().pagination;
    let agrupacionMarcas = []; let listaDeReferencias = [];
    let listaDeGrupoMontaje = distinctGrupoMontaje(listaMarca.referencias)
    let posicionPagina = 0;
    let listaMarcaFiltro = listaPaginacion.tipoFiltroPorMarca;


    if (listaMarca.referencias !== undefined) {
        if (aplicarFiltroPor === "PorMarcas") {
            //words.filter(word => word.length > 6);
            for (var i = 0; i < listaMarcaFiltro.length; i++) {
                listaDeReferencias.push(listaMarca.referencias.filter(referencia => referencia.NOMRE_MARCA === listaMarcaFiltro[i]));
            }
        }
    }

}

export function mostrarPaginaActual(registros, pagination, actualizarVista = () => { }) {

    let marcas = [];
    let continuar = false;
    let posicionPagina = pagination.paginaAMostrar === undefined ? 0 : pagination.paginaAMostrar === -1 ? 0 : pagination.paginaAMostrar;
    let listaMarcas = registros.marcas;

    const { paginas } = pagination


    if (registros.marcasSeleccionadas !== undefined) {
        if (registros.marcasSeleccionadas.length > 0) {
            listaMarcas = registros.marcasSeleccionadas
        }
    }


    let listaMarcasOrdenadas = [];


    listaMarcas.map((marca) => {
        let listaDeReferenciasDeLaMarca = registros.referencias.filter(referencia => referencia.NOMRE_MARCA === marca);
        if (listaDeReferenciasDeLaMarca.length > 0) {
            let tipoOrdenacionDeLaMarca = obtenerTipoOrdenacion(listaDeReferenciasDeLaMarca);
            let stockMaximoDeLaMarca = obtenerStockAlmacen(listaDeReferenciasDeLaMarca);
            let precioMinimoDeLaMarca = obtenerPrecioMinimo(listaDeReferenciasDeLaMarca);
            //let marcaPatrocinada = obtenerMarcaPatrocinada(listaDeReferenciasDeLaMarca)
            //if (listaDeReferencias.find(element =>  element.REFERENCIA_PROVEEDOR !== "" && element.STOCK_TOTAL > 0 && element.STOCK_REGULADOR === false) !== undefined) { return 10; }


            listaMarcasOrdenadas.push({
                marca: marca,
                tipoOrdenacion: tipoOrdenacionDeLaMarca,
                stock: stockMaximoDeLaMarca,
                precio: precioMinimoDeLaMarca,
            })
        }
    })

    listaMarcasOrdenadas = ordenarGruposMarcas(listaMarcasOrdenadas);


    //Comprueba la marca inicial que debe vese en pantalla, si es diferente se modifica por la nueva que se obtiene por listaMarcasOrdenadas
    if (paginas !== undefined && paginas !== null && paginas.length > 0) {
        if (listaMarcasOrdenadas !== undefined && listaMarcasOrdenadas !== null && listaMarcasOrdenadas.length > 0) {

            const marcaActual = paginas[0].marcaIncio;
            if (marcaActual !== listaMarcasOrdenadas[0].marca) {
                pagination.paginas[0].marcaIncio = listaMarcasOrdenadas[0].marca
                actualizarVista()
            }
        }
    }


    listaMarcas = [];
    listaMarcasOrdenadas.map((marcaOrdenada) => {
        listaMarcas.push(marcaOrdenada.marca);
    })
    //let listaMarcaFiltro = pagination.tipoFiltroPorMarca;
    if (pagination.paginas !== undefined) {
        if (pagination.paginas.length > posicionPagina) {
            for (var i = 0; i < listaMarcas.length; i++) {
                if (pagination.paginas[posicionPagina].marcaIncio === listaMarcas[i] || continuar) {
                    continuar = true;
                    marcas.push(listaMarcas[i]);
                    if (pagination.paginas[posicionPagina].marcaFin === listaMarcas[i]) {
                        break;
                    }
                }
            }

        }
    }
    //console.log("marcas FINAL", marcas)

    return marcas;
}




export function mostrarPaginaActualNeumaticos(registros, pagination) {
    let marcas = [];
    let continuar = false;
    let posicionPagina = pagination.paginaAMostrar === undefined ? 0 : pagination.paginaAMostrar === -1 ? 0 : pagination.paginaAMostrar;

    let listaMarcas = registros.marcas;

    if (registros.marcasSeleccionadas !== undefined) {
        if (registros.marcasSeleccionadas.length > 0) {
            listaMarcas = registros.marcasSeleccionadas
        }
    }


    let listaMarcasOrdenadas = [];

    listaMarcas.map((marca) => {
        let listaDeReferenciasDeLaMarca = registros.neumaticos.filter(referencia => referencia.MARCA_DESCRIPCION === marca);
        if (listaDeReferenciasDeLaMarca.length > 0) {
            /*
            let tipoOrdenacionDeLaMarca = obtenerTipoOrdenacion(listaDeReferenciasDeLaMarca);
            let stockMaximoDeLaMarca = obtenerStockAlmacen(listaDeReferenciasDeLaMarca);
            let precioMinimoDeLaMarca = obtenerPrecioMinimo(listaDeReferenciasDeLaMarca);
*/
            listaMarcasOrdenadas.push({
                marca: marca,
                tipoOrdenacion: 1,
                stock: 1,
                precio: 1
            })
        }
    })
    listaMarcasOrdenadas = ordenarGruposMarcas(listaMarcasOrdenadas);

    listaMarcas = [];
    //listaMarcas = [...listaMarcasOrdenadas.marca];

    listaMarcasOrdenadas.map((marcaOrdenada) => {
        listaMarcas.push(marcaOrdenada.marca);
    })


    //let listaMarcaFiltro = pagination.tipoFiltroPorMarca;
    if (pagination.paginas !== undefined) {
        if (pagination.paginas.length > posicionPagina) {
            for (var i = 0; i < listaMarcas.length; i++) {
                if (pagination.paginas[posicionPagina].marcaIncio === listaMarcas[i] || continuar) {
                    continuar = true;
                    marcas.push(listaMarcas[i]);
                    if (pagination.paginas[posicionPagina].marcaFin === listaMarcas[i]) {
                        break;
                    }
                }
            }

        }
    }

    return marcas;
}

export function volverAMostrarReferenciaPorCliente() {
    let nodoActual = store.getState().buscadorTree;
    let configApp = store.getState().configApp;
    let txtBuscadorReferencia = store.getState().txtBuscadorReferencia;

    if (configApp.visualizacionFiltro) {
        if (nodoActual.nodoIdActual !== "" && nodoActual.nodoIdActual !== undefined) {
            getListSuplier(nodoActual.nodoIdActual);
        }
    } else {
        if (txtBuscadorReferencia !== "") {
            buscarReferenciaYMostrarEnPantalla(txtBuscadorReferencia)
        }
    }
}

export function mostarReferenciasEnPantalla(referenciasEncontradas, CON_LADO_MONTAJE) {
    //store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))

    if (referenciasEncontradas === null || referenciasEncontradas.length === 0) {
        store.dispatch(estadoAgrupacionDeMarcas({
            marcas: [],
            referencias: null,
        }))
    } else {

        //Inicializo las referencias con su propiedad detalles a null
        referenciasEncontradas.map((x, pos) => { x.detalles = {}; x.DESCUENTO_ACTUALIZADO = false; })
        let marcasSeleccionadas = store.getState().grupoDeMarcas.marcasSeleccionadas;
        let gruposSeleccionadas = store.getState().grupoDeMarcas.gruposMontajeSeleccionados;
        let ladosSeleccionados = store.getState().grupoDeMarcas.ladosSeleccionados;


        let objReferencias = modelarObjetoReferencia(referenciasEncontradas, marcasSeleccionadas, gruposSeleccionadas, ladosSeleccionados);

        if (CON_LADO_MONTAJE !== undefined) {
            objReferencias = { ...objReferencias, conLadoMontaje: CON_LADO_MONTAJE };
        }


        //Filtramos marcas y grupos de montaje
        objReferencias = filtrarReferenciasLocalmente(objReferencias)

        //Actualizo el estado de las referencias.
        store.dispatch(estadoAgrupacionDeMarcas(objReferencias))

        //Definimos cada marca en cada página va
        let listaPaginacion = store.getState().pagination;

        let paginationPropiedades = crearPaginacion(objReferencias, listaPaginacion)

        store.dispatch({
            type: "UPDATE_PAGINATION",
            pagination: paginationPropiedades,
        })
    }


    /*
        if (referenciasEncontradas !== null) {
    
            //Inicializo las referencias con su propiedad detalles a null
            referenciasEncontradas.map((x, pos) => { x.detalles = {}; x.DESCUENTO_ACTUALIZADO = false; })
            let marcasSeleccionadas = store.getState().grupoDeMarcas.marcasSeleccionadas;
            let gruposSeleccionadas = store.getState().grupoDeMarcas.gruposMontajeSeleccionados;
            let ladosSeleccionados = store.getState().grupoDeMarcas.ladosSeleccionados;
    
    
            let objReferencias = modelarObjetoReferencia(referenciasEncontradas, marcasSeleccionadas, gruposSeleccionadas, ladosSeleccionados);
    
            if (CON_LADO_MONTAJE !== undefined) {
                objReferencias = { ...objReferencias, conLadoMontaje: CON_LADO_MONTAJE };
            }
    
    
            //Filtramos marcas y grupos de montaje
            objReferencias = filtrarReferenciasLocalmente(objReferencias)
    
            //Actualizo el estado de las referencias.
            store.dispatch(estadoAgrupacionDeMarcas(objReferencias))
    
            //Definimos cada marca en cada página va
            let listaPaginacion = store.getState().pagination;
    
            let paginationPropiedades = crearPaginacion(objReferencias, listaPaginacion)
    
            store.dispatch({
                type: "UPDATE_PAGINATION",
                pagination: paginationPropiedades,
            })
        } else {
            store.dispatch(estadoAgrupacionDeMarcas({
                marcas: [],
                referencias: [],
            }))
        }
        */
}

export function mostarReferenciasGenericasEnPantalla(referenciasEncontradas, marcaSeleccionada, grupoSeleccionado, ladosSeleccionados) {
    let registros = [];
    let nuevoObjetoRegistro = {};
    //store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))
    if (referenciasEncontradas !== null) {
        //Inicializo las referencias con su propiedad detalles a null
        referenciasEncontradas.map((x, pos) => { x.detalles = {} })

        let objReferencias = modelarObjetoReferencia(referenciasEncontradas, marcaSeleccionada, grupoSeleccionado, ladosSeleccionados);
        //Filtramos marcas y grupos de montaje
        objReferencias = filtrarReferenciasLocalmente(objReferencias)

        //Actualizo el estado de las referencias.
        registros = objReferencias

        //Definimos cada marca en cada página va
        let pagination = crearPaginacion(objReferencias, {})

        nuevoObjetoRegistro = { Pagination: pagination, Referencias: registros }

    }
    return nuevoObjetoRegistro
}


export function mostrarNeumaticosEnPantalla(referenciasEncontradas, marcaSeleccionada) {
    //let registros = [];
    let paginacionNeumaticos = {};
    //store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))
    if (referenciasEncontradas !== null) {
        //Inicializo las referencias con su propiedad detalles a null
        //referenciasEncontradas.map((x, pos) => { x.detalles = {} })

        let neumaticos = modelarObjetoNeumaticos(referenciasEncontradas, marcaSeleccionada);
        //Filtramos marcas y grupos de montaje
        //objReferencias = filtrarReferenciasLocalmente(objReferencias)

        //Actualizo el estado de las referencias.
        //registros = objReferencias

        //Definimos cada marca en cada página va
        let pagination = crearPaginacionNeumaticos(neumaticos, {})

        paginacionNeumaticos = { Pagination: pagination, Referencias: neumaticos }

    }
    return paginacionNeumaticos
}


export function historialGrupoMontaje(datos) {
    const { ID_MARCA, MARCA, ID_MODELO, MODELO, ID, MOTORIZACION, MATRICULA ,ID_MODELO_CATALOGO} = datos

    let marca = {
        ID: ID_MARCA,
        DESCRIPCION: MARCA.substring(0, MARCA.length > 7 ? 7 : MARCA.length) + " ..."
    }

    let modelo = {
        ID: ID_MODELO,
        DESCRIPCION: MODELO.substring(0, MODELO.length > 7 ? 7 : MODELO.length) + " ..."
    }

    let objetoVehiculo = {
        ID: ID,
        DESCRIPCION: MOTORIZACION === null ? "" : MOTORIZACION.substring(0, MOTORIZACION.length > 7 ? 7 : MOTORIZACION.length) + " ..."
    };

    if (esMercadoLatam()) {
        //objetoVehiculo.ID_MODELO_CATALOGO = objetoVehiculo.ID
        objetoVehiculo.ID_MODELO_CATALOGO = ID_MODELO_CATALOGO
    }

    store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
    store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
    store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));

    if (MATRICULA !== undefined && MATRICULA !== null && MATRICULA !== "") {
        store.dispatch({ type: "IDENTIFICACION_COCHE", cocheABuscar: MATRICULA })
    }
}


export function obtenerToken() {
    return localStorage.getItem("tokensertec");
}




export function efectoRemoveBuscadorReferencia(id, estado) {
    if (document.getElementById(id) !== null) {
        document.getElementById(id).value = "";
    }

    if (document.getElementById("closeBuscadorReferencia") !== null) {
        document.getElementById("closeBuscadorReferencia").style.visibility = estado ? 'hidden' : 'visible';
    }
}

export function efectoRemoveBuscador(id, estado, nombreClose) {
    if (document.getElementById(id).value !== null) {
        document.getElementById(id).value = "";
    }

    if (document.getElementById(nombreClose) !== null) {
        document.getElementById(nombreClose).style.visibility = estado ? 'hidden' : 'visible';
    }
}

export function getFocus(id) {
    setTimeout(() => {
        if (document.getElementById(id) !== null) {
            document.getElementById(id).focus();
        }
    }, 500)
}

export function removeAllClassCoche() {
    let x = document.getElementById("svg");
    let y = x.getElementsByTagName("polygon");

    for (let i = 0; i < y.length; i++) {
        y[i].classList.remove("activeArcauto");
    }
}


export function addClassCoche(e, idSVG) {
    let x = document.getElementById(idSVG);
    let y = x.getElementsByTagName("polygon");

    for (let i = 0; i < y.length; i++) {
        if (y[i].attributes[0].nodeValue === e.target.attributes[0].nodeValue) {

            y[i].classList.add("activeArcauto");
        }
    }
}


export function removeClassCoche(e, idSVG) {
    let x = document.getElementById(idSVG);
    let y = x.getElementsByTagName("polygon");

    for (let i = 0; i < y.length; i++) {
        if (y[i].attributes[0].nodeValue === e.target.attributes[0].nodeValue) {
            y[i].classList.remove("activeArcauto");
        }
    }
}


export function warningCliente(texto) {

    alertify.set('notifier', 'position', 'top-left');
    alertify.warning(texto);

    limpiarGrupoDrawer();
    localStorage.removeItem('usersertec');
    localStorage.removeItem('tokensertec');
    store.dispatch(showChildrenDrawerLevel2(false));
    store.dispatch({ type: 'STATE_ES_NEUMATICO', NEUMATICO: { ES_NEUMATICO: false, REFERENCIA_FABRICANTE: "" } })
    history.push("/");
    store.dispatch(updateStateMasterPage(false));
    store.dispatch({ type: "DELETE_ITEM_HISTORIC_APP", historicapp: [] });
    store.dispatch(showItemReferencia(''));
}

export function MSGERROR(texto, posicion) {
    switch (texto) {
        case "LA PETICION NO ESTA LOGEADA":
            warningCliente(texto)
            break;
        default:
            if (texto !== undefined && texto !== null) {
                if (texto.trim() !== "") {
                    alertify.set('notifier', 'position', posicion === undefined ? 'top-left' : posicion);
                    alertify.warning(texto);
                }
            }
            break;

    }
}
export function MSGOK(texto, posicion) {
    switch (texto) {
        case "":
            break;
        default:
            if (texto !== undefined) {
                if (texto.trim() !== "") {
                    alertify.set('notifier', 'position', posicion === undefined ? 'top-left' : posicion);
                    alertify.success(texto);
                }
            }
            break;

    }
}

export function estadoMasterPage() {
    if (history.location.pathname === "/") {
        store.dispatch(updateStateMasterPage(false));

    } else {
        store.dispatch(updateStateMasterPage(true));
    }
}


export function crearListaKeysTree(nodoIDOrigen) {
    let listaArbol = store.getState().listaTree;
    if (listaArbol.length > 0) {
        let buscarNodo = listaArbol.find(element => element.key === nodoIDOrigen.key);

        if (buscarNodo !== undefined) {
            return buscadorNodo(buscarNodo)
        } else {

        } return buscadorNodo(nodoIDOrigen);
    }
}


export function crearListaKeysGenerico(nodoIDOrigen, registros) {
    if (registros.length > 0) {
        let buscarNodo = registros.find(element => element.ID_GRUPO_RAPIDO === nodoIDOrigen.ID_GRUPO_RAPIDO);

        if (buscarNodo !== undefined) {
            return buscadorNodoGenerico(buscarNodo)
        } else {

        } return buscadorNodoGenerico(nodoIDOrigen);
    }

}

export function buscadorNodoGenerico(lista) {
    if (lista.NODOS === 0) {
        return lista.ID_GRUPO_RAPIDO;
    } else {
        let almacenar = lista.ID_GRUPO_RAPIDO + ";";
        lista.NODOS.map((item) => {
            almacenar += item.ID_GRUPO_RAPIDO + ";"
            item.NODOS.map((item2) => {
                almacenar += item2.ID_GRUPO_RAPIDO + ";"
                item2.NODOS.map((item3) => {
                    almacenar += item3.ID_GRUPO_RAPIDO + ";"
                    item3.NODOS.map((item4) => {
                        almacenar += item4.ID_GRUPO_RAPIDO + ";"
                    })
                })
            })
        })
        return almacenar.length > 0 ? almacenar.slice(0, -1) : almacenar;
    }
}

export function crearListaKeysCategoriaYQ(nodoIDOrigen, registros) {
    if (registros.length > 0) {
        let buscarNodo = registros.find(element => element.ID_CATEGORIA === nodoIDOrigen.ID_CATEGORIA);

        if (buscarNodo !== undefined) {
            return buscadorNodoCategoriaYQ(buscarNodo)
        } else {

        } return buscadorNodoCategoriaYQ(nodoIDOrigen);
    }

}

export function buscadorNodoCategoriaYQ(lista) {
    if (lista.HIJOS === 0) {
        return lista.ID_CATEGORIA;
    } else {
        let almacenar = lista.ID_CATEGORIA + ";";

        VerificarNull(lista.HIJOS).map((item) => {
            almacenar += item.ID_CATEGORIA + ";"
            VerificarNull(item.HIJOS).map((item2) => {
                almacenar += item2.ID_CATEGORIA + ";"
                VerificarNull(item2.HIJOS).map((item3) => {
                    almacenar += item3.ID_CATEGORIA + ";"
                    VerificarNull(item3.HIJOS).map((item4) => {
                        almacenar += item4.ID_CATEGORIA + ";"
                    })
                })
            })
        })

        return almacenar.length > 0 ? almacenar.slice(0, -1) : almacenar;
    }
}

export function VerificarNull(lista) {
    return lista === null || lista === undefined ? [] : lista

}

export function buscadorNodo(lista) {
    if (lista.children === 0) {
        return lista.key;
    } else {
        let almacenar = lista.key + ";";
        lista.children.map((item) => {
            almacenar += item.key + ";"
            item.children.map((item2) => {
                almacenar += item2.key + ";"
                item2.children.map((item3) => {
                    almacenar += item3.key + ";"
                    item3.children.map((item4) => {
                        almacenar += item4.key + ";"
                    })
                })
            })
        })
        return almacenar.length > 0 ? almacenar.slice(0, -1) : almacenar;
    }
}

export function busquedaNodoRecursivo(lista) {
    let almacenar = "";
    lista.children.map((item) => {
        almacenar += item.key + ";"
        if (item.children > 0) {
            almacenar += busquedaNodoRecursivo(item);
        }
    })
    return almacenar;

}


export function busquedaCochePorMatriculaOBastiidor(esFichaDGT, REGISTROS) {

    let tipoBusqueda = store.getState().DROP_SELECCION_COCHE;

    let obtenerBuscadores = store.getState().buscadorCoche;

    if (Object.keys(obtenerBuscadores).length > 0) {
        if (obtenerBuscadores.txtBuscadorCoche !== undefined) {
            obtenerBuscadores.txtBuscadorCoche = obtenerBuscadores.txtBuscadorCoche.replaceAll(" ", "");
            //obtenerBuscadores.txtBuscadorCoche = obtenerBuscadores.txtBuscadorCoche.replaceAll("-", "");

            if (verificarMatricula(obtenerBuscadores.txtBuscadorCoche)) {
                if (obtenerBuscadores.txtBuscadorCoche !== "") {
                    if (tipoBusqueda.IDENTIFICADOR.toUpperCase() === "MAT") {

                        buscarMatriculaCoche(obtenerBuscadores.txtBuscadorCoche).then(coche => {
                            if (coche !== null) {
                                coche.TIPO_BUSQUEDA = "MATRICULA";

                                if (coche.VEHICULOS_TECDOC === null || coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                                    store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: true })
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                } else {
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                    gestionarBusquedasVehiculo(coche, esFichaDGT, obtenerBuscadores.txtBuscadorCoche, REGISTROS);
                                }
                            }
                        })

                    } else if (tipoBusqueda.IDENTIFICADOR.toUpperCase() === "BAS") {
                        buscarBastidorCoche(obtenerBuscadores.txtBuscadorCoche).then(coche => {
                            if (coche !== null) {
                                coche.TIPO_BUSQUEDA = "BASTIDOR";
                                if (coche.VEHICULOS_TECDOC === null || coche.VEHICULOS_TECDOC.length === 0 && coche.MATRICULA !== null) {
                                    store.dispatch({ type: "STATE_MODAL_VEHICULO", abrirModalVehiculo: true })
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                } else {
                                    store.dispatch({ type: "REPLACE_VEHICULO_MATRICULA", vehiculoPorMatricula: coche })
                                    gestionarBusquedasVehiculo(coche, esFichaDGT, obtenerBuscadores.txtBuscadorCoche, REGISTROS);
                                }
                            }
                        })
                    }
                } else {
                    MSGERROR("Ingrese un vehículo");
                }

            } else {
                MSGERROR("El formato introducido no es correcto, sólo se aceptan números y letras.");
            }
        } else {
            MSGERROR("Ingrese un vehículo");
        }
    } else {
        MSGERROR("Ingrese un vehículo");
    }
}

export const buscarReferenciaYMostrarEnPantalla = async (ref) => {
    let referencia = ref.trim()
    if (referencia === "") {
        alertify.set('notifier', 'position', 'top-right'); alertify.warning('Ingrese una referencia');
    } else {
        limpiarCocheStore();



        store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] })

        store.dispatch(detenerBusquedaDetalles(false));
        //Limpia el estado de las marcas de las referencias encontradas.
        store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))
        //Limpia el estado de paginación
        store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });


        //Vamos a buscar la referencia
        buscarReferenciaEnAPI(referencia).then(referenciasEncontradas => {
            if (referenciasEncontradas !== null) {
                if (referenciasEncontradas.ARTICULOS !== undefined) {
                    mostarReferenciasEnPantalla(referenciasEncontradas.ARTICULOS, referenciasEncontradas.CON_LADO_MONTAJE)
                }
            }
        })

        store.dispatch(showItemReferencia('tree'));
        store.dispatch(updateVisualizacionFiltro(false))
        store.dispatch(mostrarVistaGrafica(false))
    }

}



export const buscarArticuloMotoYMostrarEnPantalla = async (ref) => {
    let referencia = ref.trim()
    if (referencia === "") {
        alertify.set('notifier', 'position', 'top-right'); alertify.warning('Ingrese una descripción');
    } else {
        limpiarCocheStore();



        store.dispatch({ type: "REGISTRO_FILTRO_TREE", openFiltroTree: false, registrosFiltro: [] })

        store.dispatch(detenerBusquedaDetalles(false));
        //Limpia el estado de las marcas de las referencias encontradas.
        store.dispatch(estadoAgrupacionDeMarcas({ "marcas": [] }))
        //Limpia el estado de paginación
        store.dispatch({ type: "UPDATE_PAGINATION", pagination: {} });


        //Vamos a buscar la referencia
        ObtenerArticulosByDescripcionEnAPI(referencia).then(referenciasEncontradas => {
            if (referenciasEncontradas !== null) {
                if (referenciasEncontradas.ARTICULOSNODOS !== undefined) {
                    mostarReferenciasEnPantalla(referenciasEncontradas.ARTICULOSNODOS, referenciasEncontradas.CON_LADO_MONTAJE)
                }
            }
        })

        store.dispatch(showItemReferencia('tree'));
        store.dispatch(updateVisualizacionFiltro(false))
        store.dispatch(mostrarVistaGrafica(false))
    }

}





export function gestionarBusquedasVehiculo(datos, esFichaDGT, identificacion, REGISTROS) {
    //store.dispatch({ type: "VEHICULO_SERTEC", VEHICULO_SERTEC: datos })


    let vehiculo = datos.VEHICULOS_TECDOC === null ? [] : datos.VEHICULOS_TECDOC;
    if (vehiculo.length > 0) {
        //let TIPO_VEHICULO = REGISTROS === undefined || REGISTROS === null ? 1 : (REGISTROS.TIPO_VEHICULO === "I" ? 2 : 1);
        //let TIPO_VEHICULO = REGISTROS === undefined || REGISTROS === null ? "C" : REGISTROS.TIPO_VEHICULO;


        if (vehiculo.length > 1) {
            store.dispatch(showChildrenDrawerIntegraciones(true));
            store.dispatch(activeItemTipoIntegracion("busquedaVehiculo"))
        } else {

            vehiculo[0].MATRICULA = identificacion;

            if (esFichaDGT) {

                store.dispatch({
                    type: "SET_ID_MOTORIZACION_FICHA_HISTORIAL",
                    codMotorizacionFichaHistorial: vehiculo[0].ID,
                })


                store.dispatch(DrawerFichaCocheDGT(true))

            } else {
                store.dispatch({ type: "VEHICULO_SERTEC", VEHICULO_SERTEC: datos })



                store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                store.dispatch(showChildrenDrawerLevel2(false));
                console.log("vehiculo[0].MATRICULA ",vehiculo[0] )

                store.dispatch(getIdTecdocMotorByIdHaynes(vehiculo[0].ID,vehiculo[0]));

                store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: vehiculo[0].TIPO_COMBUSTIBLE })

                historialGrupoMontaje(vehiculo[0]);

                gestionarContenedorCoche(true);
                store.dispatch(updateVisualizacionFiltro(true));
                store.dispatch(showChildrenDrawerIntegraciones(false));
                store.dispatch(showItemReferencia("tree"));

            }
        }
    } else {
        MSGERROR("No hay datos en VEHICULOS_TECDOC");
    }
}


export function volverABuscarVehiculo(row) {
    let obtenerEstadoFichaBusquedaVehiculo = store.getState().fichaBusquedaVehiculo;

    store.dispatch(showItemCar("1"));
    gestionarContenedorCoche(false);
    store.dispatch(updateVisualizacionFiltro(true))
    store.dispatch(showItemReferencia(""));
    limpiarCocheStore();
    store.dispatch({
        type: "LIST_TREE",
        listaTree: []
    })

    switch (row.posicion) {
        case "brand":

            break;
        case "modelo":
            let verficarEstadoModelo = obtenerEstadoFichaBusquedaVehiculo.models;
            let findmarca = obtenerEstadoFichaBusquedaVehiculo.historicapp.find(element => element.posicion === "brand");
            if (findmarca !== undefined) {
                if (Object.keys(verficarEstadoModelo).length > 0) {

                    store.dispatch(removeAllEngineType());
                    store.dispatch(getModelVehicle({ ID: findmarca.id }));
                    store.dispatch(removeAllModel());

                } else {
                    store.dispatch(removeAllEngineType());
                    store.dispatch(getModelVehicle({ ID: findmarca.id }));
                    store.dispatch(removeAllModel());
                }
            } else {
                MSGERROR("Prolemas al buscar los datos.")
            }
            break;
        case "tipomotor":
            let verficarEstadoMotorizacion = obtenerEstadoFichaBusquedaVehiculo.enginesType;
            let findmodelo = obtenerEstadoFichaBusquedaVehiculo.historicapp.find(element => element.posicion === "modelo");
            if (findmodelo !== undefined) {
                if (Object.keys(verficarEstadoMotorizacion).length > 0) {
                    store.dispatch(getEngineType({ ID: findmodelo.id })); store.dispatch(removeAllEngineType());

                } else {
                    store.dispatch(getEngineType({ ID: findmodelo.id })); store.dispatch(removeAllEngineType());
                }
            } else {
                MSGERROR("Prolemas al buscar los datos.")
            }
            break;
    }
}


export function cambiarEstadoDropVehiculo() {
    setTimeout(() => {
        let listaCodigosBandera = ["ESP", "POR", "FRA", "MAT", "BAS"];
        listaCodigosBandera.map((i) => {
            let etiqueta = document.getElementById(i)
            if (etiqueta !== undefined && etiqueta !== null) {
                etiqueta.classList.remove("active");
            }
        })

        let tagPrincipal = document.getElementById("paisVehiculo").title;
        let tagBusquedaVeh = document.getElementById("busquedaVehiculo").title;
        let find = listaCodigosBandera.find(element => tagPrincipal.includes(element))

        let findVeh = listaCodigosBandera.find(element => tagBusquedaVeh.includes(element))
        if (findVeh !== undefined) {
            if (document.getElementById(findVeh) !== undefined && document.getElementById(findVeh) !== null) {
                document.getElementById(findVeh).classList.add("active")
            }
        }
        if (find !== undefined) {
            if (document.getElementById(find) !== undefined && document.getElementById(find) !== null) {
                document.getElementById(find).classList.add("active")
            }
        }
    }, 100)
}

export function volverAlHome() {
    let historicapp = store.getState().fichaBusquedaVehiculo.historicapp;
    store.dispatch(deleteItemHistoricApp('referencia', historicapp));
    efectoRemoveBuscadorReferencia("buscadorReferencia", true);
    gestionarContenedorCoche(false);
    store.dispatch(showItemReferencia(""));
    //store.dispatch({ type: "UPDATE_LISTA_PRESUPUESTO_SECUNDARIO", presupuestoSecundario: [] })

    limpiarCocheStore();

    store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: {} })

}


export function limpiarStoreDeRedux() {

}

export function limpiarGrupoDrawer() {
    let drawer = {
        openDrawerHistoricoCoche: false,
        openDrawerCarroCompra: false,
        openDrawerMasInfo: false,
        openDrawerMasInfo2: false,
        openDrawerStockAlmacen: false,
        openDrawerReferenciaGrafico: false,
        openDrawerHaynesPro: false,
        openDrawerPerfilUsuario: false,
        openDrawerNextAssist: false,
        openDrawerAcademyCloud: false,
        openDrawerFichaCliente: false,
        openDrawerFichaCocheDGT: false,
        openDrawerFichaClienteTaller: false,
        openDrawerFichaMantenimientoCliente: false,
        openDrawerFichaMantenimientoVehiculo: false,
        openDrawerFichaPresupuestoTaller: false,
        openDrawerFichaAlbaranesTaller: false,
        openDrawerFichaDatosClientes: false,
        openDrawerFichaMarcasVehiculo: false,
        openDrawerFichaReferenciaVehiculo: false,
        openDrawerFichaEquivalenciaArticulo: false
    }

    store.dispatch({
        type: 'STATE_DRAWER_GRUPO_COCHE',
        estadoDrawer: drawer
    })

    store.dispatch(onChildrenDrawerCloseIntegraciones(false));
}

export function limpiarCocheStore() {
    store.dispatch({ type: "STATE_ID_MODELO_MOTO", ID_MODELO_MOTO: null });


    store.dispatch({ type: "STATE_TIPO_VEHICULO_POR_MODELO", TIPO_VEHICULO_POR_MODELO: "" })

    store.dispatch({ type: "UPDATE_FILTRO_BUSQUEDA", TextoBuscadorPorModelo: "" })


    store.dispatch({ type: "SET_ID_MOTORIZACION", codmotorizacion: "" })
    store.dispatch({
        type: 'DELETE_ITEM_HISTORIC_VEHICLE',
        allManufactur: {},
        enginesType: {},
        fichaCoche: {},
        historicapp: [],
        idmarca: "",
        idmodelo: "",
        models: {},
        vehiculoPorBastidor: {},
        vehiculoPorMatricula: {},
        cocheABuscar: "",

    })



    store.dispatch({
        type: "STATE_HISTORY_GRAFIC_CAR",
        historialGrafico: []
    })

    store.dispatch({
        type: "REGISTRO_FILTRO_TREE",
        openFiltroTree: false,
        registrosFiltro: []
    })

    store.dispatch({ type: "IDENTIFICACION_COCHE", cocheABuscar: "" })
    store.dispatch({ type: "VEHICULO_SERTEC", VEHICULO_SERTEC: {} })


    store.dispatch(actulizarNivelGrafico(0, ""))


}

export function limpiarFichaClienteSeleccionado() {
    store.dispatch({
        type: "STATE_FICHA_CLIENTE_SELECCIONADO",
        nombre: "",
        codigo: "",
        cif: "",
        registroCliente: {}
    })
}




export function obtenerIdioma(RESGISTRO) {
    const { ID_IDIOMA, CODIGO_IDIOMA } = RESGISTRO
    return { IDIOMA: ID_IDIOMA, CODIGO_IDIOMA: CODIGO_IDIOMA };
}


export function traducirPagina(id) {
    let idioma = store.getState().idiomaActual;
    let diccionario = store.getState().DICCIONARIO

    let retorno = {};

    if (idioma !== undefined) {


        retorno = id === undefined || id === null || typeof id === 'object' ? undefined : diccionario.find((r) => r.id.toUpperCase() === id.toUpperCase())

        /*
        if (Object.keys(idioma).length > 0) {

            switch (idioma.IDIOMA) {
                case 1:
                    retorno = txt_esp.textos.find((r) => r.id === id)
                    break;
                case 2:
                    retorno = txt_ing.textos.find((r) => r.id === id)
                    break;
                case 3:
                    retorno = txt_por.textos.find((r) => r.id === id)
                    break;
                case 4:
                    retorno = txt_ale.textos.find((r) => r.id === id)
                    break;
                case 5:
                    retorno = txt_fra.textos.find((r) => r.id === id)
                    break;
                case 6:
                    retorno = txt_ita.textos.find((r) => r.id === id)
                    break;
                case 7:
                    retorno = txt_fraSUI.textos.find((r) => r.id === id)
                    break;
                case 12:
                    retorno = txt_tur.textos.find((r) => r.id === id)
                    break;
                case 16://POLACO
                    retorno = txt_pol.textos.find((r) => r.id === id)
                    break;
                case 25://Vietnamita
                    retorno = txt_vie.textos.find((r) => r.id === id)
                    break;
                case 26://Holandes
                    retorno = txt_hol.textos.find((r) => r.id === id)
                    break;
                default:
                    retorno = txt_esp.textos.find((r) => r.id === id)
                    break;
            }

        } else {
            retorno = txt_esp.textos.find((r) => r.id === id)
        }
        */


    }


    return retorno === undefined ? id : retorno.value;
}

export const delayTest = (ms) => new Promise((res) => setTimeout(res, ms))

export function getIdiomaLogin() {
    let HOST_NAME = window.location.hostname;
    let idioma = "esp"

    if (["wop-gpn.com", "wop.idrechange.fr"].includes(HOST_NAME)) {
        idioma = "fra"
    }
    return idioma;
}

export function traducirLogin(id) {

    let retorno = {};
    let diccionario = store.getState().DICCIONARIO_LOGIN

    retorno = diccionario.find((r) => r.id === id)


    /*
    const IDIOMA = getIdiomaLogin();

    switch (IDIOMA) {
        case "ES":
            retorno = txt_esp.textos.find((r) => r.id === id)
            break;
        case "FR":
            retorno = txt_fra.textos.find((r) => r.id === id)
            break;
        
        case "PT":
            retorno = txt_por.textos.find((r) => r.id === id)
            break;
        case "DE":
            retorno = txt_ale.textos.find((r) => r.id === id)
            break;
        case "IT":
            retorno = txt_ita.textos.find((r) => r.id === id)
            break;
        case "EN":
            retorno = txt_ing.textos.find((r) => r.id === id)
            break;
        default:
            retorno = txt_esp.textos.find((r) => r.id === id)
            break;
    }

*/

    return retorno === undefined ? "" : retorno.value;
}



export function buscarPrecioRefOE(lista) {
    let crearListaREF = []
    lista.map((item, indice) => {
        crearListaREF.push({ PartNo: item.REFERENCIA_ORIGINAL, EuconId: item.EUCON_ID, NOM_FABRICANTE: item.NOMBRE_FABRICANTE })
    })

    getPricePageOEM(crearListaREF);
}



export function formatoPrecio(numero) {
    let input = numero.toString();
    let resultado = input;

    //aquí elimino todo lo que no sea números o comas (,)
    let num = input.replace(/\,/g, '');
    if (!isNaN(num)) {
        //convierto a string
        num = num.toString();
        //separo los caracteres del string
        num = num.split('');
        //invierto el orden del string
        num = num.reverse();
        //junto todos los caracteres de la cadena
        num = num.join('');
        //busco los dos primeros caracteres y le coloco una coma en la siguiente posición
        num = num.replace(/(?=\d*\.?)(\d{2})/, '$1,');
        //invierto del contenido de la cadena y reemplazo todo lo que no sea números o comas
        num = num.split('').reverse().join('').replace(/^[\,]/, '');
        //asigno la cadena formateada al input
        resultado = num;
    }
    return resultado
}

export function calcularNeto(precio, descuento) {
    let resultado = Math.floor(precio * descuento) / 100;
    return resultado;
}


export function convertirSimboloMoneda(simbolo) {
    if (simbolo === undefined) { return "" }

    if (simbolo !== undefined && simbolo !== null && simbolo !== "") {
        const DATOS_USUARIO = store.getState().usuario;
        const { SIMBOLO_MONEDA } = DATOS_USUARIO.LoginSertec;
        if (simbolo === "€" || simbolo === " €") {
            simbolo = simbolo.replace("€", SIMBOLO_MONEDA);
        }
    }
    return simbolo
}


export function currencyFormatter({ currency, value, codigo = "es-ES" }) {
    const formatter = new Intl.NumberFormat(codigo, {
        style: 'currency',
        minimumFractionDigits: 2,
        currency,
    })
    return formatter.format(value)
}


export function convertirMoneda(moneda, simbolo) {
    const DATOS_USUARIO = store.getState().usuario;
    const { MONEDA_SIN_DECIMALES } = DATOS_USUARIO.LoginSertec;

    simbolo = convertirSimboloMoneda(simbolo)


    if (moneda === null || moneda === "" || moneda === " ") {
        moneda = 0;
    }


    if (MONEDA_SIN_DECIMALES) {
        return `${moneda} ${simbolo}`
    }


    if (esMercadoLatam()) {
        let nuevoFormatoMoneeda = ""
        nuevoFormatoMoneeda = currencyFormatter({
            currency: "EUR",
            value: moneda
        })

        let eliminarSimbolo = nuevoFormatoMoneeda.slice(0, -1)
        nuevoFormatoMoneeda = `${eliminarSimbolo} ${simbolo}`
        return nuevoFormatoMoneeda
    }



    let convertirMonedaAString = moneda.toString();
    let verificarNegativo = convertirMonedaAString.substring(0, 1);
    if (verificarNegativo === "-") {
        convertirMonedaAString = convertirMonedaAString.replace("-", "")
    } else {
        verificarNegativo = "";
    }

    convertirMonedaAString = convertirMonedaAString.replace(",", ".")


    let buscarPunto = convertirMonedaAString.indexOf(".");


    if (buscarPunto === -1) {
        convertirMonedaAString = convertirMonedaAString + ".00";
        buscarPunto = convertirMonedaAString.indexOf(".");
    }

    let obtenerDecimales = convertirMonedaAString.substring(buscarPunto + 1, convertirMonedaAString.length);

    if (obtenerDecimales.length < 2) {
        obtenerDecimales = obtenerDecimales + "0";
        convertirMonedaAString = convertirMonedaAString + "0";
    }

    let obtenerEntero = convertirMonedaAString.substring(0, buscarPunto);
    if (obtenerEntero.length > 3) {
        obtenerEntero = obtenerEntero.substring(0, obtenerEntero.length - 3) + "#" + obtenerEntero.substring(obtenerEntero.length - 3, obtenerEntero.length);

        convertirMonedaAString = obtenerEntero + "." + obtenerDecimales;
    }

    let obtenerMoneda = ""
    if (convertirMonedaAString.includes("#")) {
        obtenerMoneda = convertirMonedaAString.substring(0, buscarPunto + 4);
    } else {
        obtenerMoneda = convertirMonedaAString.substring(0, buscarPunto + 3);
    }


    let ponerComaALaMoneda = obtenerMoneda.replace(".", ",");
    ponerComaALaMoneda = ponerComaALaMoneda.replace("#", ".");

    if (simbolo !== undefined && simbolo !== "") {
        ponerComaALaMoneda = ponerComaALaMoneda + " " + simbolo;
    }
    ponerComaALaMoneda = verificarNegativo + ponerComaALaMoneda;

    return ponerComaALaMoneda;
}


export function calcularImporteBruto(precio, cantidad) {
    let resultado = 0;
    /*
    let convertPrecio = typeof precio === 'number' ? precio : precio.replace(",", ".");
    let convertCantidad = typeof cantidad === 'number' ? cantidad : cantidad.replace(",", ".");
    let convertirPrecio = parseFloat(convertPrecio);
*/
    let operacion = cantidad * precio;

    operacion = redondeo(operacion, 2);
    resultado = operacion

    return resultado
}

export function calculoLineaFacturacion(cantidad, precio, DTO, iva) {
    let objetoLineaFacturacion = {
        cantidad: 0,
        precio: 0,
        descuento: 0,
        iva: 0,
        importeBruto: 0,
        importeDescuento: 0,
        baseImp: 0,
        impuesto: 0,
        total: 0
    }

    let resultadoImporteBruto = 0;
    let resultadoDescuento = 0;
    let resultadoBaseImp = 0;
    let resultadoImpuesto = 0;
    let resultadoTotal = 0;


    cantidad = cantidad === "" ? "0" : cantidad.toString();
    precio = precio === "" ? "0" : precio.toString();
    DTO = DTO === "" ? "0" : DTO.toString();
    iva = iva === "" ? "0" : iva.toString();



    cantidad = cantidad.replace(",", ".");
    precio = precio.replace(",", ".");
    DTO = DTO.replace(",", ".");
    iva = iva.replace(",", ".");

    cantidad = parseFloat(cantidad);
    precio = parseFloat(precio);
    DTO = parseFloat(DTO);
    iva = parseFloat(iva);

    resultadoImporteBruto = calcularImporteBruto(precio, cantidad);

    resultadoDescuento = calcularImporteDescuento(resultadoImporteBruto, DTO);
    resultadoBaseImp = calcularBaseImp(resultadoImporteBruto, resultadoDescuento);
    resultadoImpuesto = calcularImporteImpuesto(resultadoBaseImp, iva)
    resultadoTotal = resultadoBaseImp + resultadoImpuesto;

    resultadoImporteBruto = redondeo(resultadoImporteBruto, 2);
    resultadoDescuento = redondeo(resultadoDescuento, 2);
    resultadoBaseImp = redondeo(resultadoBaseImp, 2);
    resultadoImpuesto = redondeo(resultadoImpuesto, 2);
    resultadoTotal = redondeo(resultadoTotal, 2);


    objetoLineaFacturacion.cantidad = cantidad;
    objetoLineaFacturacion.precio = precio;
    objetoLineaFacturacion.descuento = DTO;
    objetoLineaFacturacion.iva = iva;
    objetoLineaFacturacion.importeBruto = resultadoImporteBruto;
    objetoLineaFacturacion.importeDescuento = resultadoDescuento;
    objetoLineaFacturacion.baseImp = resultadoBaseImp;
    objetoLineaFacturacion.impuesto = resultadoImpuesto;
    objetoLineaFacturacion.total = resultadoTotal;


    return objetoLineaFacturacion;
}

export function asignarImportes(props, resultTotales) {
    props.payLoadLocal.VALOR_IVA_1 = resultTotales.iva_1
    props.payLoadLocal.VALOR_IVA_2 = resultTotales.iva_2
    props.payLoadLocal.VALOR_IVA_3 = resultTotales.iva_3
    props.payLoadLocal.IMPORTE_BRUTO_1 = resultTotales.importeBruto_1
    props.payLoadLocal.IMPORTE_BRUTO_2 = resultTotales.importeBruto_2
    props.payLoadLocal.IMPORTE_BRUTO_3 = resultTotales.importeBruto_3
    props.payLoadLocal.IMPORTE_BRUTO = resultTotales.importeBruto
    props.payLoadLocal.IMPORTE_DESCUENTOS_1 = resultTotales.importeDescuento_1
    props.payLoadLocal.IMPORTE_DESCUENTOS_2 = resultTotales.importeDescuento_2
    props.payLoadLocal.IMPORTE_DESCUENTOS_3 = resultTotales.importeDescuento_3
    props.payLoadLocal.IMPORTE_DESCUENTOS = resultTotales.importeDescuento
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE_1 = resultTotales.baseImp_1
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE_2 = resultTotales.baseImp_2
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE_3 = resultTotales.baseImp_3
    props.payLoadLocal.IMPORTE_BASE_IMPONIBLE = resultTotales.baseImp
    props.payLoadLocal.IMPORTE_IMPUESTOS_1 = resultTotales.impuesto_1
    props.payLoadLocal.IMPORTE_IMPUESTOS_2 = resultTotales.impuesto_2
    props.payLoadLocal.IMPORTE_IMPUESTOS_3 = resultTotales.impuesto_3
    props.payLoadLocal.IMPORTE_IMPUESTOS = resultTotales.impuesto
    props.payLoadLocal.IMPORTE_TOTAL_1 = resultTotales.total_1
    props.payLoadLocal.IMPORTE_TOTAL_2 = resultTotales.total_2
    props.payLoadLocal.IMPORTE_TOTAL_3 = resultTotales.total_3
    props.payLoadLocal.IMPORTE_TOTAL = resultTotales.total

    return props.payLoadLocal
}



export function calculoTotalesPresupuesto(lineasManoObra, lineasArticulos) {
    let objetoTotalFacturacion = {
        iva_1: "",
        iva_2: "",
        iva_3: "",
        importeBruto_1: 0,
        importeBruto_2: 0,
        importeBruto_3: 0,
        importeBruto: 0,
        importeDescuento_1: 0,
        importeDescuento_2: 0,
        importeDescuento_3: 0,
        importeDescuento: 0,
        baseImp_1: 0,
        baseImp_2: 0,
        baseImp_3: 0,
        baseImp: 0,
        impuesto_1: 0,
        impuesto_2: 0,
        impuesto_3: 0,
        impuesto: 0,
        total_1: 0,
        total_2: 0,
        total_3: 0,
        total: 0
    }


    let totalLinea = lineasManoObra.concat(lineasArticulos);
    let result = [];
    totalLinea.reduce(function (res, value) {
        if (!res[value.IVA_VALOR]) {
            res[value.IVA_VALOR] = {
                IVA_VALOR: value.IVA_VALOR, IMPORTE_BRUTO: 0, IMPORTE_DESCUENTOS: 0,
                IMPORTE_BASE_IMPONIBLE: 0, IMPORTE_IMPUESTOS: 0, IMPORTE_TOTAL: 0
            };
            result.push(res[value.IVA_VALOR])
        }
        res[value.IVA_VALOR].IMPORTE_BRUTO += value.IMPORTE_BRUTO;
        res[value.IVA_VALOR].IMPORTE_DESCUENTOS += value.IMPORTE_DESCUENTOS;
        res[value.IVA_VALOR].IMPORTE_BASE_IMPONIBLE += value.IMPORTE_BASE_IMPONIBLE;
        res[value.IVA_VALOR].IMPORTE_IMPUESTOS += value.IMPORTE_IMPUESTOS;
        res[value.IVA_VALOR].IMPORTE_TOTAL += value.IMPORTE_TOTAL;

        return res;
    }, {});

    if (result.length > 0) {
        objetoTotalFacturacion.iva_1 = result[0].IVA_VALOR
        objetoTotalFacturacion.importeBruto_1 = result[0].IMPORTE_BRUTO
        objetoTotalFacturacion.importeDescuento_1 = result[0].IMPORTE_DESCUENTOS
        objetoTotalFacturacion.baseImp_1 = result[0].IMPORTE_BASE_IMPONIBLE
        objetoTotalFacturacion.impuesto_1 = result[0].IMPORTE_IMPUESTOS
        objetoTotalFacturacion.total_1 = result[0].IMPORTE_TOTAL
    } else {

        objetoTotalFacturacion.iva_1 = ""
        objetoTotalFacturacion.importeBruto_1 = ""
        objetoTotalFacturacion.importeDescuento_1 = ""
        objetoTotalFacturacion.baseImp_1 = ""
        objetoTotalFacturacion.impuesto_1 = ""
        objetoTotalFacturacion.total_1 = ""
    }

    if (result.length > 1) {
        objetoTotalFacturacion.iva_2 = result[1].IVA_VALOR
        objetoTotalFacturacion.importeBruto_2 = result[1].IMPORTE_BRUTO
        objetoTotalFacturacion.importeDescuento_2 = result[1].IMPORTE_DESCUENTOS
        objetoTotalFacturacion.baseImp_2 = result[1].IMPORTE_BASE_IMPONIBLE
        objetoTotalFacturacion.impuesto_2 = result[1].IMPORTE_IMPUESTOS
        objetoTotalFacturacion.total_2 = result[1].IMPORTE_TOTAL
    } else {
        objetoTotalFacturacion.iva_2 = ""
        objetoTotalFacturacion.importeBruto_2 = ""
        objetoTotalFacturacion.importeDescuento_2 = ""
        objetoTotalFacturacion.baseImp_2 = ""
        objetoTotalFacturacion.impuesto_2 = ""
        objetoTotalFacturacion.total_2 = ""
    }

    if (result.length > 2) {
        objetoTotalFacturacion.iva_3 = result[2].IVA_VALOR
        objetoTotalFacturacion.importeBruto_3 = result[2].IMPORTE_BRUTO
        objetoTotalFacturacion.importeDescuento_3 = result[2].IMPORTE_DESCUENTOS
        objetoTotalFacturacion.baseImp_3 = result[2].IMPORTE_BASE_IMPONIBLE
        objetoTotalFacturacion.impuesto_3 = result[2].IMPORTE_IMPUESTOS
        objetoTotalFacturacion.total_3 = result[2].IMPORTE_TOTAL
    } else {
        objetoTotalFacturacion.iva_3 = ""
        objetoTotalFacturacion.importeBruto_3 = ""
        objetoTotalFacturacion.importeDescuento_3 = ""
        objetoTotalFacturacion.baseImp_3 = ""
        objetoTotalFacturacion.impuesto_3 = ""
        objetoTotalFacturacion.total_3 = ""
    }

    if (result.length > 0) {
        objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto + objetoTotalFacturacion.importeBruto_1
        objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento + objetoTotalFacturacion.importeDescuento_1
        objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp + objetoTotalFacturacion.baseImp_1
        objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto + objetoTotalFacturacion.impuesto_1
        objetoTotalFacturacion.total = objetoTotalFacturacion.total + objetoTotalFacturacion.total_1
    } else {
        objetoTotalFacturacion.importeBruto = ""
        objetoTotalFacturacion.importeDescuento = ""
        objetoTotalFacturacion.baseImp = ""
        objetoTotalFacturacion.impuesto = ""
        objetoTotalFacturacion.total = ""
    }

    if (result.length > 1) {
        objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto + objetoTotalFacturacion.importeBruto_2
        objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento + objetoTotalFacturacion.importeDescuento_2
        objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp + objetoTotalFacturacion.baseImp_2
        objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto + objetoTotalFacturacion.impuesto_2
        objetoTotalFacturacion.total = objetoTotalFacturacion.total + objetoTotalFacturacion.total_2
    }

    if (result.length > 2) {
        objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto + objetoTotalFacturacion.importeBruto_3
        objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento + objetoTotalFacturacion.importeDescuento_3
        objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp + objetoTotalFacturacion.baseImp_3
        objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto + objetoTotalFacturacion.impuesto_3
        objetoTotalFacturacion.total = objetoTotalFacturacion.total + objetoTotalFacturacion.total_3
    }



    objetoTotalFacturacion.importeBruto = objetoTotalFacturacion.importeBruto === "" ? objetoTotalFacturacion.importeBruto : convertirMoneda(objetoTotalFacturacion.importeBruto);
    objetoTotalFacturacion.importeBruto_1 = objetoTotalFacturacion.importeBruto_1 === "" ? objetoTotalFacturacion.importeBruto_1 : convertirMoneda(objetoTotalFacturacion.importeBruto_1);
    objetoTotalFacturacion.importeBruto_2 = objetoTotalFacturacion.importeBruto_2 === "" ? objetoTotalFacturacion.importeBruto_2 : convertirMoneda(objetoTotalFacturacion.importeBruto_2);
    objetoTotalFacturacion.importeBruto_3 = objetoTotalFacturacion.importeBruto_3 === "" ? objetoTotalFacturacion.importeBruto_3 : convertirMoneda(objetoTotalFacturacion.importeBruto_3);

    objetoTotalFacturacion.importeDescuento = objetoTotalFacturacion.importeDescuento === "" ? objetoTotalFacturacion.importeDescuento : convertirMoneda(objetoTotalFacturacion.importeDescuento);
    objetoTotalFacturacion.importeDescuento_1 = objetoTotalFacturacion.importeDescuento_1 === "" ? objetoTotalFacturacion.importeDescuento_1 : convertirMoneda(objetoTotalFacturacion.importeDescuento_1);
    objetoTotalFacturacion.importeDescuento_2 = objetoTotalFacturacion.importeDescuento_2 === "" ? objetoTotalFacturacion.importeDescuento_2 : convertirMoneda(objetoTotalFacturacion.importeDescuento_2);
    objetoTotalFacturacion.importeDescuento_3 = objetoTotalFacturacion.importeDescuento_3 === "" ? objetoTotalFacturacion.importeDescuento_3 : convertirMoneda(objetoTotalFacturacion.importeDescuento_3);

    objetoTotalFacturacion.baseImp = objetoTotalFacturacion.baseImp === "" ? objetoTotalFacturacion.baseImp : convertirMoneda(objetoTotalFacturacion.baseImp);
    objetoTotalFacturacion.baseImp_1 = objetoTotalFacturacion.baseImp_1 === "" ? objetoTotalFacturacion.baseImp_1 : convertirMoneda(objetoTotalFacturacion.baseImp_1);
    objetoTotalFacturacion.baseImp_2 = objetoTotalFacturacion.baseImp_2 === "" ? objetoTotalFacturacion.baseImp_2 : convertirMoneda(objetoTotalFacturacion.baseImp_2);
    objetoTotalFacturacion.baseImp_3 = objetoTotalFacturacion.baseImp_3 === "" ? objetoTotalFacturacion.baseImp_3 : convertirMoneda(objetoTotalFacturacion.baseImp_3);


    objetoTotalFacturacion.impuesto = objetoTotalFacturacion.impuesto === "" ? objetoTotalFacturacion.impuesto : convertirMoneda(objetoTotalFacturacion.impuesto);
    objetoTotalFacturacion.impuesto_1 = objetoTotalFacturacion.impuesto_1 === "" ? objetoTotalFacturacion.impuesto_1 : convertirMoneda(objetoTotalFacturacion.impuesto_1);
    objetoTotalFacturacion.impuesto_2 = objetoTotalFacturacion.impuesto_2 === "" ? objetoTotalFacturacion.impuesto_2 : convertirMoneda(objetoTotalFacturacion.impuesto_2);
    objetoTotalFacturacion.impuesto_3 = objetoTotalFacturacion.impuesto_3 === "" ? objetoTotalFacturacion.impuesto_3 : convertirMoneda(objetoTotalFacturacion.impuesto_3);

    objetoTotalFacturacion.total = objetoTotalFacturacion.total === "" ? objetoTotalFacturacion.total : convertirMoneda(objetoTotalFacturacion.total);


    objetoTotalFacturacion.iva_1 = objetoTotalFacturacion.iva_1 === "" ? objetoTotalFacturacion.iva_1 : convertirMoneda(objetoTotalFacturacion.iva_1);
    objetoTotalFacturacion.iva_2 = objetoTotalFacturacion.iva_2 === "" ? objetoTotalFacturacion.iva_2 : convertirMoneda(objetoTotalFacturacion.iva_2);
    objetoTotalFacturacion.iva_3 = objetoTotalFacturacion.iva_3 === "" ? objetoTotalFacturacion.iva_3 : convertirMoneda(objetoTotalFacturacion.iva_3);

    //console.log(objetoTotalFacturacion)
    return objetoTotalFacturacion;
}


export function calcularImporteDescuento(precio, descuento) {
    let des = ((precio * descuento) / 100);
    return des
}

export function calcularBaseImp(bruto, descuento) {
    let valor = bruto - descuento
    return valor;
}

export function calcularImporteImpuesto(precio, iva) {
    let imp = ((precio * iva) / 100);
    return imp
}


export function redondeo(num, numDec) {
    num = num * Math.pow(10, numDec)
    num = Math.round(num)
    num = num / Math.pow(10, numDec)
    return num
}

export function verificarMatricula(matricula) {
    const { codPaisIdioma } = store.getState().fichaBusquedaVehiculo;
    let esOk = true;

    if(codPaisIdioma !== "SF"){
        let expresion = "^[A-Za-z0-9]{1,30}$";
        let regExpMatricula = new RegExp(expresion);
    
        if (!regExpMatricula.test(matricula.toUpperCase())) {
            esOk = false
        }
    }

    return esOk;
}

export function obtenerParametrosPorQueryString(params = "") {
    let query = params.substring(params.indexOf("?"), params.length)
    let rawParams = query.replace("?", "").split("&");
    let extractedParams = {};
    if (params !== "") {
        rawParams.forEach((item) => {
            item = item.split("=");
            extractedParams[item[0]] = item[1];
        });
    } else {
        extractedParams = null;
    }

    return extractedParams;
}



export function copiarAlPortapapeles(idTag, texto) {
    var codigoACopiar = document.getElementById(idTag);    //Elemento a copiar
    //Debe estar seleccionado en la página para que surta efecto, así que...
    //var seleccion = document.createRange(); //Creo una nueva selección vacía
    //seleccion.selectNodeContents(codigoACopiar);    //incluyo el nodo en la selección
    navigator.clipboard.writeText(texto === undefined ? codigoACopiar.innerText.trim() : texto.trim());

    //console.log("Mostrar Texto",codigoACopiar.innerText,"Seleccion",seleccion)
    //let textoLimpio = codigoACopiar.innerText.trim();

    //Antes de añadir el intervalo de selección a la selección actual, elimino otros que pudieran existir (sino no funciona en Edge)
    //window.getSelection().removeAllRanges();
    //window.getSelection().addRange(seleccion);  //Y la añado a lo seleccionado actualmente

    try {
        var res = document.execCommand('copy',); //Intento el copiado
        if (res) {
            MSGOK("¡¡Texto copiado al portapapeles!!", "bottom-right")
        } else {
            MSGERROR("¡¡Ha fallado el copiado al portapapeles!!", "bottom-right")

        }
    }
    catch (ex) {
        alertify.set('notifier', 'position', 'top-left');
        alertify.error("Se ha producido un error al copiar al portapaples");
    }
    //window.getSelection().removeRange(seleccion);
}


export function getTwoDigits(value) {
    return value < 10 ? `0${value}` : value;
}


export function getLastWeeksDate() {
    let result = "";
    const d = new Date();
    d.setDate(d.getDate() - 7);
    let day = getTwoDigits(d.getDate());
    let month = getTwoDigits(d.getMonth() + 1);
    let year = d.getFullYear();

    result = !isNaN(day) ? `${year}-${month}-${day}` : undefined;
    return result;
}

export function obtenerFechaLocal(esMesAnterior) {
    let date = new Date();

    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(esMesAnterior ? date.getMonth() : date.getMonth() + 1);
    let year = date.getFullYear();

    if (esMesAnterior && (month === "01" || month === "00" || month === "02" || month === "04" || month === "05" || month === "06" || month === "07" || month === "08" || month === "09" || month === "10" || month === "11" || month === "12")) {
        let dateAnterior = new Date(year, month, day);
        dateAnterior.setFullYear(dateAnterior.getFullYear());
        dateAnterior.setMonth(dateAnterior.getMonth() - 1);
        dateAnterior.setDate(dateAnterior.getDate());

        day = getTwoDigits(dateAnterior.getDate());
        month = getTwoDigits(dateAnterior.getMonth() + 1);
        year = dateAnterior.getFullYear();
    }

    return `${year}-${month}-${day}`;
}

export function obtenerFechaYHora() {
    //esMesAnterior
    let date = new Date();


    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = getTwoDigits(date.getHours());
    let minute = getTwoDigits(date.getMinutes());
    let seconds = getTwoDigits(date.getSeconds());
    let formato = day + "/" + month + "/" + year + "_" + hour + ":" + minute + ":" + seconds

    return formato;
}


export function obtenerSoloFecha() {
    //esMesAnterior
    let date = new Date();


    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(date.getMonth() + 1);
    let year = date.getFullYear();

    let formato = day + "/" + month + "/" + year

    return formato;
}


export function nuevoFormatoFechaCliente(fecha) {
    let buscarEspacio = fecha.indexOf(" ");
    let nuevaFecha = buscarEspacio > 0 ? fecha.substr(0, buscarEspacio) : fecha

    let pattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    let arrayDate = nuevaFecha.match(pattern);
    /*
    var dt = new Date(arrayDate[3], arrayDate[2] - 1, arrayDate[1]);

    var diaActual = new Date(fecha);
    var day = diaActual.getDate() < 10 ? "0" + diaActual.getDate() : diaActual.getDate();
    var month = (diaActual.getMonth() + 1) < 10 ? "0" + (diaActual.getMonth() + 1) : (diaActual.getMonth() + 1);
    var year = diaActual.getFullYear();

    var hora = diaActual.getHours() < 10 ? "0" + diaActual.getHours() : diaActual.getHours();
    var minutos = diaActual.getMinutes() < 10 ? "0" + diaActual.getMinutes() : diaActual.getMinutes();
*/
    return `${arrayDate[3]}-${arrayDate[2]}-${arrayDate[1]}`;
}


export function efectoLadoDeMontaje(id) {
    let estaActivo = false;
    if (document.getElementById(id) !== null) {
        if (document.getElementById(id).style.opacity !== "") {
            document.getElementById(id).style.opacity = "";
            document.getElementById(id).style.backgroundColor = "#1790ff";


            estaActivo = true;
        } else {
            document.getElementById(id).style.opacity = "0.5";
            document.getElementById(id).style.backgroundColor = "transparent";

        }
    }
    return estaActivo
}



/*
export function filtroArbolLocal(palabra) {
 

    let treeOriginal = store.getState().listaEstructura;
    let nodosEncontrados = [];
    if (palabra.length >= 3) {
        treeOriginal.find(function (nodo, i) {

            let textoInicial = quitarAcentos(nodo.Term).toLowerCase();
            let textoEntrada =  quitarAcentos(palabra).toLowerCase();
//includes(textoEntrada) || textoInicial === textoEntrada

            if (textoInicial.match(´/${textoEntrada}./´ )) {

                if (nodo.NIVEL > 1) {
                    let continuarBuscando = true;
                    let nodosHijos = [];
                    let nodoParent = nodo.NODE_ID_PARENT;

                    do {
                       
                        let existenNodos = treeOriginal.find(registro => registro.NODE_ID === nodoParent)
                        if (existenNodos !== undefined) {
                            if (existenNodos.NIVEL === 1) {
                                continuarBuscando = false;
                            } else {
                                continuarBuscando = true;
                                nodoParent = existenNodos.NODE_ID_PARENT
                            }
                            nodosHijos.push(existenNodos)
                        } else {
                            continuarBuscando = false;
                        }
                    } while (continuarBuscando);

                    nodosHijos.push(nodo)
                    nodosHijos.sort(dynamicSortAsc("NIVEL"))
                    nodosEncontrados.push(nodosHijos)
                }
            }
        })
    }

    return nodosEncontrados;
  
}
*/




export function filtroTree(palabra) {
    let treeOriginal = store.getState().listaEstructura;
    let nodosEncontrados = [];
    if (palabra.length >= 3 && treeOriginal !== null) {
        treeOriginal.find(function (nodo, i) {

            let textoInicial = quitarAcentos(nodo.Term).toLowerCase();
            let textoEntrada = quitarAcentos(palabra).toLowerCase();


            let expression = `/${textoEntrada}.*/`;

            //const obj = JSON.parse(expression);

            //if(re.test(textoEntrada) ){

            if (textoInicial.match(eval(expression))) {
                //if (textoInicial.includes(textoEntrada) || textoInicial === textoEntrada) {
                if (nodo.NIVEL > 1) {
                    let continuarBuscando = true;
                    let nodosHijos = [];
                    let nodoParent = nodo.NODE_ID_PARENT;

                    do {
                        let existenNodos = treeOriginal.find(registro => registro.NODE_ID === nodoParent)
                        if (existenNodos !== undefined) {
                            if (existenNodos.NIVEL === 1) {
                                continuarBuscando = false;
                            } else {
                                continuarBuscando = true;
                                nodoParent = existenNodos.NODE_ID_PARENT
                            }
                            nodosHijos.push(existenNodos)
                        } else {
                            continuarBuscando = false;
                        }
                    } while (continuarBuscando);

                    nodosHijos.push(nodo)
                    nodosHijos.sort(dynamicSortAsc("NIVEL"))
                    nodosEncontrados.push(nodosHijos)
                }
            }
        })
    }

    nodosEncontrados.map((registro) => {
        registro.PALABRA = registro[registro.length - 1].Term;
    })
    nodosEncontrados.sort(dynamicSortAsc("PALABRA"))


    nodosEncontrados = [...new Map(nodosEncontrados.map(item =>
        [item['PALABRA'], item])).values()];

    store.dispatch({
        type: "REGISTRO_FILTRO_TREE",
        openFiltroTree: nodosEncontrados.length > 0 ? true : false,
        registrosFiltro: nodosEncontrados
    })
}


export function filtroGenericoTree(palabra, treeOriginal) {
    let nodosEncontrados = [];
    if (palabra.length >= 3) {
        treeOriginal.find(function (nodo, i) {
            if (nodo.NOMBRE.toLowerCase().includes(palabra.toLowerCase())) {

                if (nodo.NIVEL > 1) {
                    let continuarBuscando = true;
                    let nodosHijos = [];
                    let nodoParent = nodo.ID_NODO_PADRE;
                    do {
                        let existenNodos = treeOriginal.find(registro => registro.ID_GRUPO_RAPIDO === nodoParent)
                        if (existenNodos !== undefined) {
                            if (existenNodos.NIVEL === 1) {
                                continuarBuscando = false;
                            } else {
                                continuarBuscando = true;
                                nodoParent = existenNodos.ID_NODO_PADRE
                            }
                            nodosHijos.push(existenNodos)
                        } else {
                            continuarBuscando = false;
                        }
                    } while (continuarBuscando);

                    nodosHijos.push(nodo)
                    nodosHijos.sort(dynamicSortAsc("NIVEL"))
                    nodosEncontrados.push(nodosHijos)
                }

            }
        })

    }

    return {
        openFiltroTree: nodosEncontrados.length > 0 ? true : false,
        registrosFiltro: nodosEncontrados
    }

}

export function filtroGenericoCategorias(palabra, treeOriginal) {
    let nodosEncontrados = [];
    if (palabra.length >= 3) {
        // nodosEncontrados = treeOriginal.filter((nodo) => nodo.NOMBRE.toLowerCase().includes(palabra.toLowerCase()));



        treeOriginal.find(function (nodo, i) {
            if (nodo.NOMBRE.toLowerCase().includes(palabra.toLowerCase())) {

                if (nodo.NIVEL > 0) {
                    let continuarBuscando = true;
                    let nodosHijos = [];
                    let nodoParent = nodo.ID_CATEGORIA_PADRE;
                    do {
                        let existenNodos = treeOriginal.find(registro => registro.ID_CATEGORIA === nodoParent)
                        if (existenNodos !== undefined) {
                            if (existenNodos.NIVEL === 1) {
                                continuarBuscando = false;
                            } else {
                                continuarBuscando = true;
                                nodoParent = existenNodos.ID_CATEGORIA_PADRE
                            }
                            nodosHijos.push(existenNodos)
                        } else {
                            continuarBuscando = false;
                        }
                    } while (continuarBuscando);
                    nodosHijos.push(nodo)
                    nodosHijos.sort(dynamicSortAsc("NIVEL"))
                    nodosEncontrados.push(nodosHijos)
                }

            }
        })


    }

    return {
        openFiltroTree: nodosEncontrados.length > 0 ? true : false,
        registrosFiltro: nodosEncontrados
    }

}


export function obtenerLineasPorTipo(registros, tipoLinea) {
    if (registros.length === 0) {
        return []
    } else {
        registros.map((linea, i) => {
            linea.ID = i + 1
        })
        return registros.filter(registro => registro.TIPO_LINEA === tipoLinea)
    }
}


export function descargarPdfPasandoBase64(base64String, nomFichero) {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fechaFichero = obtenerFechaYHora();
    const fileName = nomFichero + fechaFichero + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


export function descargarFicheroPasandoBase64(base64String, nomFichero, extension) {
    const linkSource = "data:application/" + extension + ";base64," + base64String;
    const downloadLink = document.createElement("a");
    const fechaFichero = obtenerFechaYHora();
    const fileName = nomFichero + fechaFichero + "." + extension;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}




export function recursivaOEM(lista, objeto) {
    let objetoAInsertar = {
        ID_CATEGORIA: 0,
        CODIGO: "",
        NOMBRE: "",
        SSD: "",
        TIENE_NODOS: false,
        ID_CATEGORIA_PADRE: 0,
        NODOS: []
    }
    lista.map((item) => {
        objetoAInsertar.push(
            {
                ID_CATEGORIA: item.ID_CATEGORIA,
                CODIGO: item.CODIGO,
                NOMBRE: item.NOMBRE,
                SSD: item.SSD,
                TIENE_NODOS: item.TIENE_NODOS,
                ID_CATEGORIA_PADRE: item.ID_CATEGORIA_PADRE,
                NODOS: []
            }

        )
        if (item.TIENE_NODOS) {

        } else {
            let verificarSiExiste =
                objeto.push(objetoAInsertar);

        }


    })

}

export const esUsuarioFlotas = () => {
    const { PLANTILLA } = obtenerDatosUsuarioYLicenciaActual();
    let esFlotas = false
    if (PLANTILLA !== undefined && PLANTILLA !== null && PLANTILLA !== "") {
        if (PLANTILLA.toUpperCase() === "FLOTAS") { esFlotas = true }
    }
    return esFlotas
}

export const updatePapelera = (payload, esMultipleLinea, usuarioFlotas) => {

    if (usuarioFlotas) {
        SetPortapapelesPresupuestosFlotasEnAPI(0, payload).then((registros) => {
            if (registros !== undefined) {
                if (registros.OK) {
                    if (esMultipleLinea === undefined || !esMultipleLinea) {
                        MSGOK(traducirPagina("Referencia_agregada"))
                    }
                }
            }
        })
    } else {
        SetPortapapelesPresupuestosEnAPI(0, payload).then((registros) => {
            if (registros !== undefined) {
                if (registros.OK) {
                    if (esMultipleLinea === undefined || !esMultipleLinea) {
                        MSGOK(traducirPagina("Referencia_agregada"))
                    }
                }
            }
        })
    }

}



export const crearLineasParaElPresupuesto = async (registro, tipoDeLinea, esMultipleLinea, VEHICULO) => {
    let lineas = [];
    let obtenerReferencia = registro;
    //Verificar si no hay iva por defecto
    let datosFacturacion = store.getState().datosFacturacion;
    let ivaPorDefecto = datosFacturacion.ivaPorDefecto
    let precioManoObra = datosFacturacion.manoObraPorDecto
    let lineFacturacion = {};
    let payLoadArticulo = {};
    let payLoadManoObra = {};


    let VEHICULO_LOCAL = VEHICULO === undefined || VEHICULO === null ? await buscarVehiculoLocal() : VEHICULO;


    //console.log("LOCAL_VEH",VEHICULO_LOCAL)


    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = {};
    const usuarioFlotas = esUsuarioFlotas()

    if (getCookieUser !== null) {
        accesoLicencia = getCookieUser.LoginSertec

        if (tipoDeLinea === "A") {

            //ARTICULO
            let cantidadLocal = obtenerReferencia.CANTIDAD === undefined || obtenerReferencia.CANTIDAD === null ? 1 : obtenerReferencia.CANTIDAD

            const DESCUENTO_LINEA = usuarioFlotas ? obtenerReferencia.DESCUENTO : 0

            lineFacturacion = calculoLineaFacturacion(cantidadLocal, obtenerReferencia.PRECIO, DESCUENTO_LINEA, ivaPorDefecto)

            payLoadArticulo = {
                ID_USUARIO_SERTEC: accesoLicencia.ID,
                ID: 0,
                TIPO_LINEA: "A",
                REFERENCIA_TECDOC: obtenerReferencia.REFERENCIA,
                REFERENCIA_SOCIO: obtenerReferencia.REFERENCIA_PROVEEDOR,
                MARCA_SOCIO: obtenerReferencia.CODIGO_MARCA_ERP,
                MARCA_SOCIO_DESCRIPCION: obtenerReferencia.NOMRE_MARCA,
                MARCA_TECDOC: obtenerReferencia.CODIGO_MARCA,
                MARCA_TECDOC_DESCRIPCION: obtenerReferencia.NOMRE_MARCA,
                REFERENCIA_DESCRIPCION: obtenerReferencia.NOMBRE_GRUPO_MONTAJE,
                MANO_OBRA: "",
                IVA_VALOR: datosFacturacion.ivaPorDefecto,
                DTO_VALOR: DESCUENTO_LINEA,
                CANTIDAD: cantidadLocal,
                IMPORTE_BRUTO: lineFacturacion.importeBruto,
                IMPORTE_DESCUENTOS: lineFacturacion.importeDescuento,
                IMPORTE_BASE_IMPONIBLE: lineFacturacion.baseImp,
                IMPORTE_IMPUESTOS: lineFacturacion.impuesto,
                IMPORTE_TOTAL: lineFacturacion.total,
                PRECIO: obtenerReferencia.PRECIO,
                DESCUENTO_COMPRA_TALLER: obtenerReferencia.DESCUENTO


            }
            /*
            ORDEN_LINEA: 0,
                ID_PRESUPUESTO: null
             */


            const { MATRICULA, BASTIDOR, MARCA_VEHICULO, MODELO_VEHICULO, MOTOR_VEHICULO, VEHICULO_TECDOC } = VEHICULO_LOCAL;
            payLoadArticulo.MATRICULA_VEHICULO = MATRICULA;
            payLoadArticulo.BASTIDOR_VEHICULO = BASTIDOR;
            payLoadArticulo.MARCA_VEHICULO = MARCA_VEHICULO;
            payLoadArticulo.MODELO_VEHICULO = MODELO_VEHICULO;
            payLoadArticulo.MOTOR_VEHICULO = MOTOR_VEHICULO;
            payLoadArticulo.ID_VEHICULO_TECDOC = Object.keys(VEHICULO_TECDOC).length === 0 ? "" : VEHICULO_TECDOC.ID;
            payLoadArticulo.ES_NEUMATICO = registro.ES_NEUMATICO === undefined || registro.ES_NEUMATICO === null ? false : registro.ES_NEUMATICO


            /*
                        SetPortapapelesPresupuestosEnAPI(0, payLoadArticulo).then((registros) => {
                            if (registros !== undefined) {
                                if (registros.OK) {
                                    if (esMultipleLinea === undefined || !esMultipleLinea) {
                                        MSGOK(traducirPagina("Referencia_agregada"))
                                    }
                                }
                            }
                        })
                        */

            updatePapelera(payLoadArticulo, esMultipleLinea, false)
        } else {
            //MANO DE OBRRA
            let lineFacturacionManoObra = calculoLineaFacturacion(registro.Tiempo, precioManoObra, 0, ivaPorDefecto)
            let manoObraTotal = registro.Descripcion;

            if (registro.TAREAS_REPARACION.length > 0) {
                manoObraTotal = manoObraTotal + '<br/>' + '<ul>';
                registro.TAREAS_REPARACION.map(tarea => {
                    manoObraTotal = manoObraTotal + '<li>' + tarea.Descripcion + '</li>';
                })
                manoObraTotal = manoObraTotal + '</ul>';
            }


            payLoadManoObra = {
                ID_USUARIO_SERTEC: accesoLicencia.ID,
                ID: 0,
                TIPO_LINEA: "T",
                REFERENCIA_TECDOC: "",
                REFERENCIA_SOCIO: "",
                MARCA_SOCIO: "",
                MARCA_SOCIO_DESCRIPCION: "",
                MARCA_TECDOC: "",
                MARCA_TECDOC_DESCRIPCION: "",
                REFERENCIA_DESCRIPCION: "",
                MANO_OBRA: manoObraTotal,
                IVA_VALOR: ivaPorDefecto,
                DTO_VALOR: 0,
                CANTIDAD: registro.Tiempo,
                IMPORTE_BRUTO: lineFacturacionManoObra.importeBruto,
                IMPORTE_DESCUENTOS: lineFacturacionManoObra.importeDescuento,
                IMPORTE_BASE_IMPONIBLE: lineFacturacionManoObra.baseImp,
                IMPORTE_IMPUESTOS: lineFacturacionManoObra.impuesto,
                IMPORTE_TOTAL: lineFacturacionManoObra.total,
                PRECIO: precioManoObra

            }


            const { MATRICULA, BASTIDOR, MARCA_VEHICULO, MODELO_VEHICULO, MOTOR_VEHICULO, VEHICULO_TECDOC } = VEHICULO_LOCAL;
            payLoadManoObra.MATRICULA_VEHICULO = MATRICULA;
            payLoadManoObra.BASTIDOR_VEHICULO = BASTIDOR;
            payLoadManoObra.MARCA_VEHICULO = MARCA_VEHICULO;
            payLoadManoObra.MODELO_VEHICULO = MODELO_VEHICULO;
            payLoadManoObra.MOTOR_VEHICULO = MOTOR_VEHICULO;
            payLoadManoObra.ID_VEHICULO_TECDOC = Object.keys(VEHICULO_TECDOC).length === 0 ? "" : VEHICULO_TECDOC.ID;
            payLoadManoObra.ES_NEUMATICO = registro.ES_NEUMATICO === undefined || registro.ES_NEUMATICO === null ? false : registro.ES_NEUMATICO


            /*
                        SetPortapapelesPresupuestosEnAPI(0, payLoadManoObra).then((registros) => {
                            if (registros !== undefined) {
                                if (registros.OK) {
                                    if (esMultipleLinea === undefined || !esMultipleLinea) {
                                        MSGOK(traducirPagina("Referencia_agregada"))
                                    }
                                }
                            }
                        })
                        */

            updatePapelera(payLoadManoObra, esMultipleLinea, false)

        }
    }
}


export function crearLineasParaElPresupuestoLocal(registro, tipoDeLinea, esMultipleLinea) {
    let lineas = [];
    let obtenerReferencia = registro;
    let listaStore = store.getState().PresupuestoTallerSeleccionado;
    //Verificar si no hay iva por defecto
    let datosFacturacion = store.getState().datosFacturacion;
    let ivaPorDefecto = datosFacturacion.ivaPorDefecto
    let precioManoObra = datosFacturacion.manoObraPorDecto
    let lineFacturacion = {};
    let payLoadArticulo = {};
    let payLoadManoObra = {};


    let getCookieUser = JSON.parse(localStorage.getItem("usersertec"));
    let accesoLicencia = {};
    if (getCookieUser !== null) {
        accesoLicencia = getCookieUser.LoginSertec

        if (tipoDeLinea === "A") {
            //ARTICULO
            let cantidadLocal = obtenerReferencia.CANTIDAD === undefined || obtenerReferencia.CANTIDAD === null ? 1 : obtenerReferencia.CANTIDAD

            lineFacturacion = calculoLineaFacturacion(cantidadLocal, obtenerReferencia.PRECIO, 0, ivaPorDefecto)



            payLoadArticulo = {
                ID_USUARIO_SERTEC: accesoLicencia.ID,
                ID: 0,
                TIPO_LINEA: "A",
                REFERENCIA_TECDOC: obtenerReferencia.REFERENCIA,
                REFERENCIA_SOCIO: obtenerReferencia.REFERENCIA_PROVEEDOR,
                MARCA_SOCIO: obtenerReferencia.CODIGO_MARCA_ERP,
                MARCA_SOCIO_DESCRIPCION: obtenerReferencia.NOMRE_MARCA,
                MARCA_TECDOC: obtenerReferencia.CODIGO_MARCA,
                MARCA_TECDOC_DESCRIPCION: obtenerReferencia.NOMRE_MARCA,
                REFERENCIA_DESCRIPCION: obtenerReferencia.NOMBRE_GRUPO_MONTAJE,
                MANO_OBRA: "",
                IVA_VALOR: datosFacturacion.ivaPorDefecto,
                DTO_VALOR: 0,
                CANTIDAD: cantidadLocal,
                IMPORTE_BRUTO: lineFacturacion.importeBruto,
                IMPORTE_DESCUENTOS: lineFacturacion.importeDescuento,
                IMPORTE_BASE_IMPONIBLE: lineFacturacion.baseImp,
                IMPORTE_IMPUESTOS: lineFacturacion.impuesto,
                IMPORTE_TOTAL: lineFacturacion.total,

                PRECIO: obtenerReferencia.PRECIO,
            }
            /*
            ORDEN_LINEA: 0,
                ID_PRESUPUESTO: null
             */


            return payLoadArticulo;


        } else {
            //MANO DE OBRRA
            let lineFacturacionManoObra = calculoLineaFacturacion(registro.Tiempo, precioManoObra, 0, ivaPorDefecto)
            let manoObraTotal = registro.Descripcion;

            if (registro.TAREAS_REPARACION.length > 0) {
                manoObraTotal = manoObraTotal + '<br/>' + '<ul>';
                registro.TAREAS_REPARACION.map(tarea => {
                    manoObraTotal = manoObraTotal + '<li>' + tarea.Descripcion + '</li>';
                })
                manoObraTotal = manoObraTotal + '</ul>';
            }


            payLoadManoObra = {
                ID_USUARIO_SERTEC: accesoLicencia.ID,
                ID: 0,
                TIPO_LINEA: "T",
                REFERENCIA_TECDOC: "",
                REFERENCIA_SOCIO: "",
                MARCA_SOCIO: "",
                MARCA_SOCIO_DESCRIPCION: "",
                MARCA_TECDOC: "",
                MARCA_TECDOC_DESCRIPCION: "",
                REFERENCIA_DESCRIPCION: "",
                MANO_OBRA: manoObraTotal,
                IVA_VALOR: ivaPorDefecto,
                DTO_VALOR: 0,
                CANTIDAD: registro.Tiempo,
                IMPORTE_BRUTO: lineFacturacionManoObra.importeBruto,
                IMPORTE_DESCUENTOS: lineFacturacionManoObra.importeDescuento,
                IMPORTE_BASE_IMPONIBLE: lineFacturacionManoObra.baseImp,
                IMPORTE_IMPUESTOS: lineFacturacionManoObra.impuesto,
                IMPORTE_TOTAL: lineFacturacionManoObra.total,
                PRECIO: precioManoObra

            }

            return payLoadManoObra

        }
    }
}







export function convertirMonedaANumero(moneda) {
    let resultado = 0;
    if (moneda !== 0) {
        resultado = moneda.replace(".", "");
        resultado = resultado.replace(",", ".");
        resultado = resultado.replace("€", "");
        resultado = resultado.replace(" ", "");
    }
    return resultado;
}


export function verificarSiEsMostrador() {
    let esMostrador = false;
    esMostrador = store.getState().usuario.LoginSertec === undefined ? false : store.getState().usuario.LoginSertec.List_LicenciaSERTE !== null ? store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].MOSTRADOR : false;

    return esMostrador
}

export function visualizarStocksRemotos() {
    let esMostrador = false;
    esMostrador = store.getState().usuario.LoginSertec === undefined ? false : store.getState().usuario.LoginSertec.List_LicenciaSERTE !== null ? store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].VISUALIZAR_STOCKS_REMOTOS_GRUPO : false;

    return esMostrador
}

export function permisoMotos() {
    let PERMISO_MOTOS = false;
    PERMISO_MOTOS = store.getState().usuario.LoginSertec === undefined ? false : store.getState().usuario.LoginSertec.List_LicenciaSERTE !== null ? store.getState().usuario.LoginSertec.List_LicenciaSERTE[0].PERMISO_MOTOS : false;

    return PERMISO_MOTOS
}


export function credencialesHaynes() {
    let CREDENCIALES = {
        TOKEN_TECNICO_DIST: "",
        TOKEN_TECNICO_DIST_RM: "",
        TOKEN_TECNICO_SMART: ""
    }

    if (store.getState().usuario.LoginSertec !== undefined && store.getState().usuario.LoginSertec.HEYNES !== null && Object.keys(store.getState().usuario.LoginSertec.HEYNES).length > 0) {
        const { TOKEN_TECNICO_DIST, TOKEN_TECNICO_DIST_RM, TOKEN_TECNICO_SMART } = store.getState().usuario.LoginSertec.HEYNES
        CREDENCIALES.TOKEN_TECNICO_DIST = TOKEN_TECNICO_DIST
        CREDENCIALES.TOKEN_TECNICO_DIST_RM = TOKEN_TECNICO_DIST_RM
        CREDENCIALES.TOKEN_TECNICO_SMART = TOKEN_TECNICO_SMART
    }

    return CREDENCIALES
}

export function obtenerTipoDeBusquedaActual() {
    let tipoBusqueda = "C";
    let estadoLocal = store.getState().BusquedaPorModelo;

    if (Object.keys(estadoLocal).length > 0) {
        tipoBusqueda = estadoLocal.tipoBusqueda;
    }

    return tipoBusqueda;
}


export function recortarCadena(cadena, tamanyo, caracter) {
    if (cadena === undefined || cadena === null) {
        return "";
    }

    if (cadena.length < tamanyo) {
        return cadena;
    }

    let retorno = cadena.substring(0, tamanyo);

    if (caracter !== null || caracter !== "") {
        retorno = retorno + caracter;
    }
    return retorno;
}


export function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}

export function encode_utf8(s) {
    return unescape(encodeURIComponent(s));
}


export function filtarClientes(textoAFiltrar) {
    let clientesMostradorLocal = store.getState().ClientesMostrador;
    let resultado = clientesMostradorLocal.find((cliente) => cliente.Codigo === textoAFiltrar)
    if (resultado === undefined) {
        MSGERROR("No se encontró el cliente")
    } else {
        store.dispatch(clienteSeleccionado(resultado.Razon, resultado.Codigo, resultado.Cif, resultado));

        efectoBotonRemove(resultado.Razon, "closeBuscadorCliente");
        volverAMostrarReferenciaPorCliente();
    }
}

export function obtenerGraficos(TIPO_COMBUSTIBLE) {
    let resultado = ""
    switch (TIPO_COMBUSTIBLE) {
        case 1:
            resultado = grafico_necanica1
            break;
        case 2:
            resultado = grafico_necanica2
            break;
        case 3:
            resultado = grafico_necanica1
            break;
        case 4:
            resultado = grafico_necanica1
            break;
        default:
            resultado = grafico_necanica1
            break;
    }
    return resultado;
}

export function obtenerTipoConbustible(TIPO_COMBUSTIBLE) {
    let resultado = ""
    let verificarTipo = TIPO_COMBUSTIBLE === undefined && TIPO_COMBUSTIBLE === null ? 1 : typeof TIPO_COMBUSTIBLE === 'string' ?
        parseInt(TIPO_COMBUSTIBLE) :
        TIPO_COMBUSTIBLE;
    switch (verificarTipo) {
        case 1:
            resultado = traducirPagina("Gasolina")
            break;
        case 2:
            resultado = traducirPagina("Diesel")
            break;
        case 3:
            resultado = traducirPagina("Híbrido")
            break;
        case 4:
            resultado = traducirPagina("Hidrogeno")
            break;
        case 5:
            resultado = traducirPagina("Gas")
            break;
        case 6:
            resultado = traducirPagina("Electrico")
            break;
        default:
            resultado = traducirPagina("Desconocido")
            break;
    }
    return resultado;
}

export function soloNumeros(entrada) {
    const regex = /^[0-9]*$/;
    const onlyNumbers = regex.test(entrada);

    return onlyNumbers
}

export function buscarTipoCombustible() {
    let VerificarCombustible = Object.keys(store.getState().fichaBusquedaVehiculo).length > 0 ? store.getState().fichaBusquedaVehiculo.TIPO_COMBUSTIBLE : 1;
    let tipoCombustible = typeof VerificarCombustible === 'string' ? parseInt(VerificarCombustible) : VerificarCombustible
    return tipoCombustible
}


export function obtenerGraficosMecanica(level, key) {
    let tipoCombustible = buscarTipoCombustible()

    let result = {};
    let mecanica = obtenerGraficos(tipoCombustible);
    if (level === 1) {
        result = mecanica
    } else {
        let buscarNivel = mecanica.children.find(registro => registro.tooltip === key);
        if (buscarNivel !== undefined) {
            result = buscarNivel.children[0]
        } else {
            for (var i = 0; i <= mecanica.children.length; i++) {
                if (mecanica.children[i] !== undefined) {
                    let found = mecanica.children[i].children.length === 0 ? undefined : mecanica.children[i].children[0].children.find(element => element.tooltip === key);
                    if (found !== undefined) {
                        if (found.children[0] !== undefined) {
                            result = found.children[0]
                            break;
                        }
                    }
                }
            }
        }
    }
    return result;
}

export function obtenerGraficosCarroceria(level, key) {
    let result = {};
    let carroceria = grafico_carroceria0;

    if (level === 1) {
        result = carroceria
    } else {

        let buscarNivel = carroceria.children.find(registro => registro.tooltip === key);
        if (buscarNivel !== undefined) {
            result = buscarNivel.children[0]
        } else {
            //buscarNivel = carroceria.children.find(registro => registro.tooltip === (level-1));
            result = buscarNivel.children[0]
        }

    }
    return result;
}


export function objetoGraficoV2(itemNivel, atributo) {
    //let historial = store.getState().datosGraficos.historialGrafico;
    let listaNivelActual = [];
    let datosPiezas = grafico_necanica0;


    if (itemNivel === undefined) {
        return datosPiezas;
    } else {
        if (atributo === undefined || atributo === "") {
            listaNivelActual.push(datosPiezas)
        } else {
            let found = datosPiezas.children.find(element => element.tooltip === atributo);
            if (found !== undefined) {
                listaNivelActual.push(found)
            } else {
                for (var i = 0; i <= datosPiezas.children.length; i++) {
                    if (datosPiezas.children[i] !== undefined) {
                        found = datosPiezas.children[i].children.find(element => element.tooltip === atributo);
                        if (found !== undefined) {
                            if (found.children[0] !== undefined) {
                                listaNivelActual.push(found.children[0])
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    return listaNivelActual;
}

export function ordenarTablaLocal(tipoFiltro, registros, campoAFiltrar) {

    let lista = tipoFiltro === "ASC" ? registros.sort(dynamicSortAsc(campoAFiltrar)) : registros.sort(dynamicSortDesc(campoAFiltrar));

    return lista;
}


export const añadirPresupuestoNextControl = async (PRESUPUESTO) => {

    const { LINEAS, VEH_MATRICULA, BASTIDOR, ID_MARCA_MATRICULA, ID_MODELO_MATRICULA, ID_MOTOR_MATRICULA } = PRESUPUESTO;

    let fabricantes = await getFabricantesEnAPI();
    let modelos = []
    let motorizacion = []

    let DESCRIPCION_MARCA = "";
    let DESCRIPCION_MODELO = "";
    let DESCRIPCION_MOTOR = "";

    if (ID_MARCA_MATRICULA !== undefined && ID_MARCA_MATRICULA !== null && ID_MARCA_MATRICULA !== 0) {

        const REGISTROS_MARCA = fabricantes.find((registro) => registro.ManNo === ID_MARCA_MATRICULA);
        if (REGISTROS_MARCA !== undefined) {
            DESCRIPCION_MARCA = REGISTROS_MARCA.Term
        }
        modelos = await getModelosTurismosEnAPI(ID_MARCA_MATRICULA)
    }


    if (ID_MODELO_MATRICULA !== undefined && ID_MODELO_MATRICULA !== null && ID_MODELO_MATRICULA !== 0) {
        const REGISTROS_MODELOS = modelos.find((registro) => registro.ID === ID_MODELO_MATRICULA);
        if (REGISTROS_MODELOS !== undefined) {
            DESCRIPCION_MODELO = REGISTROS_MODELOS.DESCRIPCION
        }

        motorizacion = await getMotorizacionesTurismosEnAPI(ID_MODELO_MATRICULA);

    }

    if (ID_MOTOR_MATRICULA !== undefined && ID_MOTOR_MATRICULA !== null && ID_MOTOR_MATRICULA !== 0) {
        const REGISTROS_MOTORES = motorizacion.find((registro) => registro.ID === ID_MOTOR_MATRICULA);
        if (REGISTROS_MOTORES !== undefined) {
            DESCRIPCION_MOTOR = REGISTROS_MOTORES.DESCRIPCION
        }
    }





    let OBTENER_VEHICULO = {
        MATRICULA: VEH_MATRICULA,
        BASTIDOR: BASTIDOR,
        MARCA_VEHICULO: DESCRIPCION_MARCA === undefined ? "" : DESCRIPCION_MARCA,
        MODELO_VEHICULO: DESCRIPCION_MODELO === undefined ? "" : DESCRIPCION_MODELO,
        MOTOR_VEHICULO: DESCRIPCION_MOTOR === undefined ? "" : DESCRIPCION_MOTOR,
        VEHICULO_TECDOC: { ID: ID_MOTOR_MATRICULA }

    }


    let ManoObra = obtenerLineasPorTipo(LINEAS, "T")
    let Articulos = obtenerLineasPorTipo(LINEAS, "A")

    ManoObra.map((tarea) => {
        let Obra = {
            Tiempo: tarea.CANTIDAD,
            Descripcion: tarea.MANO_OBRA,
            TAREAS_REPARACION: []
        };
        crearLineasParaElPresupuesto(Obra, "T", true, OBTENER_VEHICULO);
    })

    const newArray = Articulos.map(async (articulo, indice) => {
        let itemArticulo = {};
        if (Object.keys(articulo).length > 0) {
            itemArticulo = {
                PRECIO: articulo.PRECIO,
                REFERENCIA_PROVEEDOR: articulo.REFERENCIA_SOCIO,
                REFERENCIA: articulo.REFERENCIA_TECDOC,
                CODIGO_MARCA_ERP: articulo.MARCA_SOCIO,
                NOMRE_MARCA: articulo.MARCA_TECDOC_DESCRIPCION,
                CODIGO_MARCA: articulo.MARCA_TECDOC,
                NOMBRE_GRUPO_MONTAJE: articulo.REFERENCIA_DESCRIPCION,
                DESCUENTO: articulo.DTO_VALOR,
                CANTIDAD: articulo.CANTIDAD
            }
        } else {
            itemArticulo = {
                PRECIO: 0,
                REFERENCIA_PROVEEDOR: "",
                REFERENCIA: "",
                CODIGO_MARCA_ERP: "",
                NOMRE_MARCA: "",
                CODIGO_MARCA: 0,
                NOMBRE_GRUPO_MONTAJE: articulo.Descripcion,
                DESCUENTO: 0
            }

        }

        if (itemArticulo.REFERENCIA_PROVEEDOR !== "") {
            try {
                const peticion = await ObtenerArticuloERPEnAPI(false, itemArticulo.REFERENCIA_PROVEEDOR, "");
                const DESCUENTO_TALLER = await peticion[0].descuento;
                itemArticulo.DESCUENTO = DESCUENTO_TALLER;
                crearLineasParaElPresupuesto(itemArticulo, "A", true, OBTENER_VEHICULO);

                return peticion;
            } catch (error) {
                //MSGERROR(error.message);
            }
        } else {
            crearLineasParaElPresupuesto(itemArticulo, "A", true, OBTENER_VEHICULO);
        }
    })


    Promise.all(newArray).then(data => {
        MSGOK(traducirPagina("Referencia_agregada"))
    });
}



export const buscarVehiculoLocal = async () => {
    let VEHICULO = {
        MATRICULA: "",
        BASTIDOR: "",
        MARCA_VEHICULO: "",
        MODELO_VEHICULO: "",
        MOTOR_VEHICULO: "",
        VEHICULO_TECDOC: {}
    }
    const { VEHICULO_SERTEC } = store.getState().fichaBusquedaVehiculo;

    let codmotorizacion = store.getState().codmotorizacion;
    codmotorizacion = codmotorizacion.toString();
    if (codmotorizacion !== undefined && codmotorizacion !== null && codmotorizacion !== "0" && codmotorizacion !== "") {
        const resultado = await getFichaCocheEnAPI(codmotorizacion);
        //const { MARCA, MODELO, MOTORIZACION } = await resultado;
        const { MARCA, MODELO, MOTORIZACION } = resultado === null ? { MARCA: "", MODELO: "", MOTORIZACION: "" } : resultado;

        VEHICULO.MARCA_VEHICULO = MARCA;
        VEHICULO.MODELO_VEHICULO = MODELO;
        VEHICULO.MOTOR_VEHICULO = MOTORIZACION;
        VEHICULO.VEHICULO_TECDOC = resultado;
    }

    if (Object.keys(VEHICULO_SERTEC).length > 0) {
        const { TIPO_BUSQUEDA, MATRICULA, OK, IDENTIFICADOR } = VEHICULO_SERTEC;
        if (OK) {
            VEHICULO.MATRICULA = TIPO_BUSQUEDA === "MATRICULA" ? MATRICULA.Matricula : "";
            VEHICULO.BASTIDOR = TIPO_BUSQUEDA === "MATRICULA" ? MATRICULA.Bastidor : IDENTIFICADOR;
        }
    }

    return VEHICULO;
}



export const condicionesAbrirCesta = (registro, CANTIDAD) => {
    let resultado = {
        ID_STOCK: 3,
        TIPO_STOCK: "",
        ABRIR_FICHA: false
    }

    if (registro.STOCK_ALMACEN > 0 && parseInt(CANTIDAD) <= registro.STOCK_ALMACEN) {
        //VERDE
        resultado.TIPO_STOCK = "VERDE";
        resultado.ID_STOCK = 1;
        //const {ABRIR_SIEMPRE_DESGLOSE_COMPRAR,COMPRAR_SIN_DISPONIBILIDAD} = obtenerDatosUsuarioYLicenciaActual()

        if (obtenerDatosUsuarioYLicenciaActual().ABRIR_SIEMPRE_DESGLOSE_COMPRAR) {
            //ABRIR FICHA
            resultado.ABRIR_FICHA = true;
        } else {
            resultado.ABRIR_FICHA = false;
        }
    } else if (registro.STOCK_ALMACEN > 0 && parseInt(CANTIDAD) > registro.STOCK_ALMACEN) {
        //VERDE SIN_STOCK_SUFIENTE
        //ABRIR FICHA
        resultado.TIPO_STOCK = "VERDE SIN_STOCK_SUFIENTE";
        resultado.ID_STOCK = 1;
        resultado.ABRIR_FICHA = true;
    }
    else if (registro.STOCK_ALMACEN === 0 && parseInt(CANTIDAD) <= registro.STOCK_TOTAL) {
        //else if (registro.STOCK_ALMACEN === 0 && parseInt(CANTIDAD) <= registro.STOCK_ALMACEN) {
        //(registro.STOCK_TOTAL > 0 && parseInt(CANTIDAD) <= registro.STOCK_TOTAL) {
        //NARANJA
        resultado.TIPO_STOCK = "NARANJA";
        resultado.ID_STOCK = 2;
        if (obtenerDatosUsuarioYLicenciaActual().CESTA_AUTOMATICA_DISPONIBILIDAD_NARANJA) {
            resultado.ABRIR_FICHA = false;
        } else {
            //ABRIR FICHA
            resultado.ABRIR_FICHA = true;
        }
    } else if (registro.STOCK_ALMACEN === 0 && parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
        //NARAJA SIN STOCK SUFICIENTE
        //ABRIR FICHA
        resultado.TIPO_STOCK = "NARANJA SIN_STOCK_SUFIENTE";
        resultado.ID_STOCK = 2;
        resultado.ABRIR_FICHA = true;
    } else {
        //ROJO
        //ABRIR FICHA
        resultado.TIPO_STOCK = "ROJO";
        resultado.ID_STOCK = 3;
        resultado.ABRIR_FICHA = true;
    }

    return resultado;
}






export const condicionesAbrirCestav2 = (registro, CANTIDAD) => {
    const {
        ABRIR_SIEMPRE_DESGLOSE_COMPRAR,
        CESTA_AUTOMATICA_DISPONIBILIDAD_NARANJA,
        COMPRAR_SIN_DISPONIBILIDAD
    } = obtenerDatosUsuarioYLicenciaActual()
    const { STOCK_ALMACEN, STOCK_TOTAL, PRECIO } = registro


    let resultado = {
        ID_STOCK: 3,
        TIPO_STOCK: "",
        ABRIR_FICHA: false
    }

    if (STOCK_ALMACEN > 0 && parseInt(CANTIDAD) <= STOCK_ALMACEN) {
        //VERDE
        resultado.TIPO_STOCK = "VERDE";
        resultado.ID_STOCK = 1;
        if (ABRIR_SIEMPRE_DESGLOSE_COMPRAR) {
            resultado.ABRIR_FICHA = true;
        } else {
            resultado.ABRIR_FICHA = false;
        }
    } else if (STOCK_ALMACEN > 0 && parseInt(CANTIDAD) > STOCK_ALMACEN) {
        //VERDE SIN_STOCK_SUFIENTE
        resultado.TIPO_STOCK = "VERDE SIN_STOCK_SUFIENTE";
        resultado.ID_STOCK = 1;
        resultado.ABRIR_FICHA = true;
    }
    else if (STOCK_ALMACEN === 0 && parseInt(CANTIDAD) <= STOCK_TOTAL) {
        //NARANJA
        resultado.TIPO_STOCK = "NARANJA";
        resultado.ID_STOCK = 2;
        if (CESTA_AUTOMATICA_DISPONIBILIDAD_NARANJA) {
            resultado.ABRIR_FICHA = false;
        } else {
            resultado.ABRIR_FICHA = true;
        }
    } else if (STOCK_ALMACEN === 0 && parseInt(CANTIDAD) > STOCK_TOTAL) {
        //NARAJA SIN STOCK SUFICIENTE
        resultado.TIPO_STOCK = "NARANJA SIN_STOCK_SUFIENTE";
        resultado.ID_STOCK = 2;

        if (PRECIO > 0 && COMPRAR_SIN_DISPONIBILIDAD) {
            resultado.ABRIR_FICHA = false;
        } else {
            resultado.ABRIR_FICHA = true;
        }

        //resultado.ABRIR_FICHA = true;
    } else {
        //ROJO
        resultado.TIPO_STOCK = "ROJO";
        resultado.ID_STOCK = 3;
        resultado.ABRIR_FICHA = true;
    }

    return resultado;
}



export const verificarCantidadCesta = (REGISTRO, COMPRAR_SIN_DISPONIBILIDAD) => {
    const { STOCK_ALMACEN, STOCK_TOTAL, PRECIO } = REGISTRO
    let PERMISO_CANTIDAD = false

    if (STOCK_ALMACEN === 0 && STOCK_TOTAL === 0) {
        PERMISO_CANTIDAD = true
    }


    if (PRECIO > 0 && COMPRAR_SIN_DISPONIBILIDAD) {
        PERMISO_CANTIDAD = false
    }


    return PERMISO_CANTIDAD;
}


export const cestaAutomatica = async (stock, TIPO_STOCK, CANTIDAD, REGISTRO, VEHICULO) => {
    const OBTENER_DATOS_USUARIO = datosLincenciaUsuario();
    const { ALMACEN } = OBTENER_DATOS_USUARIO === undefined ? { ALMACEN: "" } : OBTENER_DATOS_USUARIO;
    const { COMPRAR_SIN_DISPONIBILIDAD } = OBTENER_DATOS_USUARIO === undefined ? { COMPRAR_SIN_DISPONIBILIDAD: false } : OBTENER_DATOS_USUARIO;


    if (stock !== undefined && stock !== null && stock.length > 0) {

        if (TIPO_STOCK === "NARANJA") {
            let CANTIDAD_SELECCIONADA = CANTIDAD;
            stock.map((item, i) => {
                let result = CANTIDAD_SELECCIONADA - item.Stock
                if (CANTIDAD_SELECCIONADA === 0) {
                    item.CANTIDAD = 0;
                } else if (result < 0) {
                    item.CANTIDAD = CANTIDAD_SELECCIONADA;
                    CANTIDAD_SELECCIONADA = 0;
                } else {
                    CANTIDAD_SELECCIONADA = CANTIDAD_SELECCIONADA - item.Stock
                    item.CANTIDAD = item.Stock;
                }
            })
        }


        //Pruebas por defecto cliente o Permiso_Compras_Stock_Cero

        if (COMPRAR_SIN_DISPONIBILIDAD) {
            if (!verificarCantidadCesta(REGISTRO, COMPRAR_SIN_DISPONIBILIDAD)) {
                let INDEX_ALMACEN = stock.findIndex((item) => item.CodigoAlmacen === ALMACEN)

                if (INDEX_ALMACEN !== -1) {
                    stock[INDEX_ALMACEN].DefectoCliente = true;
                } else {
                    MSGERROR(`No se encontro el almacen ${ALMACEN}`)
                }
            }
        }


        const posts = await Promise.all(

            stock.map(async (almacen) => {
                const CONDICION = TIPO_STOCK === "NARANJA" ? almacen.CANTIDAD > 0 : almacen.DefectoCliente

                if (CONDICION) {
                    return await setCestaLineasEnAPI(
                        REGISTRO.REFERENCIA_PROVEEDOR,
                        REGISTRO.CODIGO_MARCA_ERP,
                        REGISTRO.REFERENCIA,
                        REGISTRO.CODIGO_MARCA,
                        REGISTRO.DESCRIPCION_CESTA,

                        TIPO_STOCK === "NARANJA" ? almacen.CANTIDAD : CANTIDAD,
                        0,//texto libre
                        REGISTRO.PRECIO,
                        REGISTRO.DESCUENTO,
                        REGISTRO.NETO,
                        almacen.CodigoAlmacen,//ALMACEN
                        almacen.DescripcionAlmacen,//DESCRIPCION ALMACEN
                        0,
                        "",
                        "",
                        VEHICULO
                    )

                }
            })

        )
        const RESPUESTA = await posts.filter((registro) => registro !== undefined);
        if (RESPUESTA.length > 0) {
            MSGOK(traducirPagina("Referencia_cesta"), "botton-right");
        }
    }
}



export const mostrarIcono = (anchuraMax) => {
    const { height, width } = store.getState().configApp;
    let mostrar = '';
    if (width < anchuraMax) {
        mostrar = 'none'
    }
    return mostrar;
}





export const generateUUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}



export const verificarExtension = (EXTENSION) => {
    let resultado = {
        ID_TIPO: 7,
        TIPO: "desconocido.png",
        ES_EXTENSION_PERMITIDA: true
    }
    switch (EXTENSION.toUpperCase()) {
        case "PNG": case "JPG": case "JPEG": case "GIF":
            resultado.ID_TIPO = 1
            resultado.TIPO = "imagen.png"
            break;
        case "PDF":
            resultado.ID_TIPO = 2
            resultado.TIPO = "pdf.png"
            break;
        case "XLSX": case "XLSM": case "XLSB": case "XLTX": case "CSV":
            resultado.ID_TIPO = 3
            resultado.TIPO = "excel.png"
            break;
        case "DOCX":
            resultado.ID_TIPO = 4
            resultado.TIPO = "word.png"
            break;
        case "MP4": case "MOV": case "WMV": case "MKV":
            resultado.ID_TIPO = 5
            resultado.TIPO = "video.png"
            break;
        case "ZIP": case "RAR":
            resultado.ID_TIPO = 6
            resultado.TIPO = "comprimido.png"
            break;
        default:
            resultado.ID_TIPO = 7
            resultado.TIPO = "desconocido.png"
            resultado.ES_EXTENSION_PERMITIDA = false
            break;
    }

    return resultado;
}


export function obtenerFechaLocalObjeto(esMesAnterior) {
    let resultado = {};
    let date = new Date();

    let day = getTwoDigits(date.getDate());
    let month = getTwoDigits(esMesAnterior ? date.getMonth() : date.getMonth() + 1);
    let year = date.getFullYear();
    resultado = { "DIA": day, "MES": month, "ANIO": year }

    return resultado;
}

export const construirFecha = (codigoMes, Anio, condiciones) => {
    let fechaLocal = obtenerFechaLocalObjeto(false);
    let crearFecha = ""
    if (condiciones.DESDE === 4 && condiciones.HASTA === 5) {
        crearFecha = fechaLocal.DIA + "/" + codigoMes + "/" + Anio
    } else if (condiciones.DESDE === 0 && condiciones.HASTA === 1) {
        crearFecha = "01" + "/" + codigoMes + "/" + Anio
    } else if (condiciones.DESDE === 2 && condiciones.HASTA === 3) {
        crearFecha = "01" + "/" + "01" + "/" + Anio
    }

    return crearFecha;
}

export function imprimirDivGenerico(NOMBRE_CONTENEDOR) {
    let data = document.getElementsByClassName(NOMBRE_CONTENEDOR);

    if (data.length > 0) {
        let myWindow = window.open('', 'Generico', 'height=700,width=1000');
        myWindow.document.write(data[0].innerHTML);
        myWindow.document.close();

        myWindow.onload = function () {
            myWindow.focus();
            myWindow.print();
            myWindow.close();
        };
    }
}


export function imprimirDocumentoHTML(HTML) {
    let myWindow = window.open('', '', 'height=700,width=1000');
    if (myWindow !== undefined || myWindow !== null) {
        myWindow.document.write(HTML);
        myWindow.document.close();
        myWindow.onload = function () {
            myWindow.focus();
            myWindow.print();
            myWindow.close();
        };
    }
}

export function convertirAMesACodigo(CODIGO) {
    let MES = ""
    switch (CODIGO.toUpperCase()) {
        case "ENERO":
            MES = "01";
            break;
        case "FEBRERO":
            MES = "02";
            break;
        case "MARZO":
            MES = "03";
            break;
        case "ABRIL":
            MES = "04";
            break;
        case "MAYO":
            MES = "05";
            break;
        case "JUNIO":
            MES = "06";
            break;
        case "JULIO":
            MES = "07";
            break;
        case "AGOSTO":
            MES = "08";
            break;
        case "SEPTIEMBRE":
            MES = "09";
            break;
        case "OCTUBRE":
            MES = "10";
            break;
        case "NOVIEMBRE":
            MES = "11";
            break;
        case "DICIEMBRE":
            MES = "12";
            break;
        default:
            MES = "01";
            break;
    }
    return MES;
}

export function convertirCodigoAMeses(CODIGO) {
    let MES = ""
    switch (CODIGO) {
        case "01":
            MES = "Enero";
            break;
        case "02":
            MES = "Febrero";
            break;
        case "03":
            MES = "Marzo";
            break;
        case "04":
            MES = "Abril";
            break;
        case "05":
            MES = "Mayo";
            break;
        case "06":
            MES = "Junio";
            break;
        case "07":
            MES = "Julio";
            break;
        case "08":
            MES = "Agosto";
            break;
        case "09":
            MES = "Septiembre";
            break;
        case "10":
            MES = "Octubre";
            break;
        case "11":
            MES = "Noviembre";
            break;
        case "12":
            MES = "Diciembre";
            break;
        default:
            MES = "Enero";
            break;
    }
    return MES;
}


export function obtenerServicio(usuario, PLATAFORMA) {
    let RESULTADO = {
        PERMITIR_VISUALIZAR: false,
        PLATAFORMA: "",
        USUARIO: "",
        PWD: ""
    }


    if (usuario.LoginSertec !== undefined) {
        let LISTA_SERVICIOS = usuario.LoginSertec.SERVICIOS;
        let BUSCAR_SERVICIO = LISTA_SERVICIOS.find((registro) => registro.PLATAFORMA === PLATAFORMA);
        if (BUSCAR_SERVICIO !== undefined) {
            RESULTADO.PLATAFORMA = BUSCAR_SERVICIO.PLATAFORMA
            RESULTADO.USUARIO = BUSCAR_SERVICIO.USUARIO
            RESULTADO.PWD = BUSCAR_SERVICIO.PWD

            if (BUSCAR_SERVICIO.USUARIO !== "" && BUSCAR_SERVICIO.PWD !== "") {
                RESULTADO.PERMITIR_VISUALIZAR = true
            }
        }

    }

    return RESULTADO;

}



export function quitarAcentos(cadena) {
    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };

    return cadena.split('').map(letra => acentos[letra] || letra).join('').toString();
}


export function actualizarNotificaciones() {
    ObtenerNotificacionesEnAPI().then(registro => {
        if (registro !== undefined) {
            if (registro.NOTIFICACIONES !== undefined && registro.NOTIFICACIONES !== null) {
                store.dispatch({ type: 'STATE_NOTIFICACIONES_APP', CHAT: registro.NOTIFICACIONES.MENSAJES_TELEGRAM_NUEVOS, CESTA: registro.NOTIFICACIONES.ARTICULOS_CESTA });
            } else {
                store.dispatch({ type: 'STATE_NOTIFICACIONES_APP', CHAT: 0, CESTA: 0 });
            }
        }
    })
}




export function OBTENER_TIPO_BUSQUEDA(REGISTROS) {
    const { VEHICULOS_TECDOC } = REGISTROS;

    if (VEHICULOS_TECDOC !== undefined && VEHICULOS_TECDOC !== null && VEHICULOS_TECDOC.length === 1) {

        const { TIPO_VEHICULO } = VEHICULOS_TECDOC[0];
        MODIFICAR_TIPO_BUSQUEDA(TIPO_VEHICULO);
    }
}


export function VEHICULO_SELECCIONADO_TIPO_BUSQUEDA(VEHICULOS_TECDOC) {
    if (VEHICULOS_TECDOC !== undefined && VEHICULOS_TECDOC !== null) {

        const { TIPO_VEHICULO } = VEHICULOS_TECDOC;
        MODIFICAR_TIPO_BUSQUEDA(TIPO_VEHICULO);
    }
}

export function OBTENER_ID_MOTO() {
    let codmotorizacion = store.getState().codmotorizacion;
    let HISTORIAL = store.getState().fichaBusquedaVehiculo.historicapp;
    let ID_MODELO_CATALOGO = null


    if (HISTORIAL !== undefined && HISTORIAL !== null && HISTORIAL !== "" && HISTORIAL.length === 3) {
        let VERIFICAR_ID = HISTORIAL[2].id === undefined || HISTORIAL[2].id === null || HISTORIAL[2].id === "" ? null : HISTORIAL[2].id
        ID_MODELO_CATALOGO = VERIFICAR_ID == codmotorizacion ? null : HISTORIAL[2].id
    }

    return ID_MODELO_CATALOGO;
}

export function MODIFICAR_TIPO_BUSQUEDA(TIPO_BUSQUEDA) {
    //  descripcion: "Búsqueda por vehiculo",
    /*
        if(TIPO_BUSQUEDA === undefined || TIPO_BUSQUEDA === null || TIPO_BUSQUEDA === ""){
            TIPO_BUSQUEDA = "C"
        }
        */

    switch (TIPO_BUSQUEDA) {
        case "C":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'C',
                descripcion: "CARROCERIA",
            })
            break;
        case "I":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'I',
                descripcion: "Búsqueda por camión",
            })
            break;
        case "MT":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'MT',
                descripcion: "Búsqueda por moto",
            })
            break;
        case "T":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'T',
                descripcion: "Búsqueda por moto",
            })
            break;
        case "M":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'M',
                descripcion: "Búsqueda por motor",
            })
            break;
        case "CODM":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'CODM',
                descripcion: "Búsqueda por codigo motor",
            })
            break;
        case "E":
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'E',
                descripcion: "Búsqueda por eje",
            })
            break;
        default:
            store.dispatch({
                type: 'UPDATE_TIPO_BUSQUEDA_MODELO',
                tipoBusqueda: 'C',
                descripcion: "CARROCERIA",
            })
            break;
    }
}


export const filtroLadoMontaje = (LADO, REGISTROS, LADOS_SELECCIONADOS) => {

    let TEXTO_LADO = ""
    let CANTIDAD = REGISTROS !== undefined && REGISTROS !== null && LADOS_SELECCIONADOS !== undefined ? REGISTROS.filter((registro) => LADOS_SELECCIONADOS.includes(registro.LADO_MONTAJE)) : []

    switch (LADO) {
        case "V":
            TEXTO_LADO = `${traducirPagina("txt_delantero")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
        case "D":
            TEXTO_LADO = `${traducirPagina("txt_fr_derecho")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
        case "L":
            TEXTO_LADO = `${traducirPagina("txt_fr_izquierdo")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
        case "H":
            TEXTO_LADO = `${traducirPagina("txt_trasero")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
    }
    return TEXTO_LADO

}



export const filtroLadoMontajev2 = (LADO, REGISTROS, LADOS_SELECCIONADOS) => {

    let TEXTO_LADO = ""
    let CANTIDAD = REGISTROS !== undefined && REGISTROS !== null ? REGISTROS.filter((registro) => LADOS_SELECCIONADOS.includes(registro.LADO_MONTAJE)) : []

    switch (LADO) {
        case 1:
            TEXTO_LADO = `${traducirPagina("txt_delantero")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
        case 2:
            TEXTO_LADO = `${traducirPagina("txt_fr_derecho")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
        case 3:
            TEXTO_LADO = `${traducirPagina("txt_fr_izquierdo")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
        case 4:
            TEXTO_LADO = `${traducirPagina("txt_trasero")}: ${CANTIDAD.length} ${traducirPagina("Referencias").toLowerCase()}`
            break;
    }
    return TEXTO_LADO

}


export const modelarTreeGenerico = (TREE_ESTRUCTURA) => {
    let crearTree = [];

    if (TREE_ESTRUCTURA !== null && TREE_ESTRUCTURA.length > 0) {
        TREE_ESTRUCTURA.map((i) => {
            let nivel2 = [];
            i.ESTRUCTURAS.map((x) => {
                let nivel3 = [];
                x.ESTRUCTURAS.map((z) => {
                    let nivel4 = [];
                    z.ESTRUCTURAS.map((y) => {
                        let nivel5 = [];
                        if (y.ESTRUCTURAS !== null) {

                            y.ESTRUCTURAS.map((m) => {
                                nivel5.push({ title: m.Term, key: m.NODE_ID, level: m.NIVEL, sortnr: m.NIVEL, children: [], ES_MOTO: m.ES_MOTO })
                            })

                        }
                        nivel4.push({ title: y.Term, key: y.NODE_ID, level: y.NIVEL, sortnr: y.NIVEL, children: nivel5, ES_MOTO: y.ES_MOTO })
                    })
                    nivel3.push({ title: z.Term, key: z.NODE_ID, level: z.NIVEL, sortnr: z.NIVEL, children: nivel4, ES_MOTO: z.ES_MOTO })
                })
                nivel2.push({ title: x.Term, key: x.NODE_ID, level: x.NIVEL, sortnr: x.NIVEL, children: nivel3, ES_MOTO: x.ES_MOTO })
            })
            crearTree.push({ title: i.Term, key: i.NODE_ID, level: i.NIVEL, sortnr: i.NIVEL, children: nivel2, ES_MOTO: i.ES_MOTO })
        })
    }
    return crearTree
}


export const comprobarNeumatico = (ID_VEHICULO_NEUMATICO) => {
    const SERVICIO_BASIC = datosServiciosSertec("NEXTDATA_BASIC");
    let ID_VEH = -1
    if (SERVICIO_BASIC.CONTRATADO) {
        switch (SERVICIO_BASIC.ORIGEN) {
            case "HAY":
                ID_VEH = parseInt(ID_VEHICULO_NEUMATICO)
                break;
            case "RMI":
                ID_VEH = -1
                break;
        }
    }
    return ID_VEH
}




export const redondeoHoras = (x) => {
    return Number.parseFloat(x).toFixed(2);
}


export const formatearFecha = (fecha = "") => {
    const fechaActual = new Date(fecha);
    const opciones = { month: 'numeric', day: 'numeric' };
    return fechaActual.toLocaleDateString('es-ES', opciones).toString()
}

export const idModeloLatam = () => {
    let resultado = null
    const registroVehiculo = store.getState().fichaBusquedaVehiculo;

    if (registroVehiculo !== undefined && registroVehiculo !== null && registroVehiculo.historicapp !== undefined && registroVehiculo.historicapp !== null && registroVehiculo.historicapp.length > 0) {

        const { historicapp } = registroVehiculo
        const objeto = historicapp[historicapp.length - 1].registros
        const { ID_MODELO_CATALOGO } = objeto
        resultado = ID_MODELO_CATALOGO === undefined ? null : ID_MODELO_CATALOGO
    }

    return resultado;
}

export const hayDocumentoTecnico = () => {
    let resultado = {
        hayDocumento: false,
        url: ""
    }

    const registroVehiculo = store.getState().fichaBusquedaVehiculo;
    if (registroVehiculo !== undefined && registroVehiculo !== null && registroVehiculo.historicapp !== undefined && registroVehiculo.historicapp !== null && registroVehiculo.historicapp.length > 0) {

        const { historicapp } = registroVehiculo
        const objeto = historicapp[historicapp.length - 1].registros
        const { DOCUMENTO } = objeto
        if (DOCUMENTO !== undefined && DOCUMENTO !== null && DOCUMENTO !== "") {
            resultado.hayDocumento = true
            resultado.url = DOCUMENTO
        }
    }

    return resultado;
}




export function descargarDocPasandoBase64Generico(base64String, extension) {
    const linkSource = `data:application/${extension};base64,${base64String}`;
    const downloadLink = document.createElement("a");
    const fechaFichero = obtenerFechaYHora();
    const fileName = "Documento" + fechaFichero + "." + extension;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}



export function textoEmailFlota(codigo_autorizacion = "xxxxxxxxxxxxxx") {

    if (codigo_autorizacion === "") { codigo_autorizacion = "xxxxxxxxxxxxxx" }

    let texto = "Adjunto le envío presupuesto con piezas relacionadas, por favor solicito confirmación por su parte de que el recambio este \n"
    texto += "debidamente documentado, dejando libertad de elección por su parte para realizar cualquier modificación en dicho \n"
    texto += "recambio, informándonos previamente de los cambios acontecidos, con el objeto de reducir el margen de error al mínimo \n"
    texto += "posible.\n\n"
    texto += "No enviar al taller indicado, hasta que confirmemos que la intervención está autorizada por parte de Northgate. \n"
    texto += `Enviaremos dicho correo indicando: INTERVENCION AUTORIZADA ${codigo_autorizacion}, PROCEDED CON EL ENVIO \n`
    texto += "Ruego se me conteste a este mail, indicando si se ha enviado o modificado alguna referencia. \n"
    texto += "Para cualquier aclaración en el siguiente email \n\n"
    texto += "Gracias anticipadas.\n"
    texto += "Un saludo."
    return texto;
}

export function textoEmailTaller(id = "xxxxxxxxxxxxxx", matricula = "xxxxxxxxxxxxxxx") {
    if (id === "") { id = "xxxxxxxxxxxxxx" }
    if (matricula === "") { matricula = "xxxxxxxxxxxxxx" }


    let texto = `Buenos días, adjuntamos presupuesto y N.º ${id} de autorización del vehículo ${matricula} con cita confirmada. \n`
    texto += "En el podéis ver los recambios que recibiréis antes de la cita para que podáis verificar que esta todo correcto.\n"
    texto += "Sirva este mismo documento para que realicéis la factura conforme a autorización, incluyendo en la factura estos datos, así \n"
    texto += "como vuestro N.º  de cuenta bancaria para realizar los pagos. \n"
    texto += "Nuestros datos de facturación son: \n"
    texto += "Razón social: Veturilo S.A. \n"
    texto += "CIF:  A60080728 \n"
    texto += "Domicilio: C/Maestre Joan Corrales, nº 59, 08950, Espluges de Llobregat (Barcelona) \n"
    texto += "La factura debe ser enviada al siguiente mail: \n"
    texto += "proveedoresryf@serca.es \n\n"
    texto += "Gracias por vuestra colaboración. \n"
    texto += "Un saludo. "
    return texto;
}

export function asuntoEmail(numeroDocumeto = "", matricula = "") {
    if (numeroDocumeto === "") { numeroDocumeto = "xxxxxxxxxxxxxx" }
    let texto = `Presupuesto N.º ${numeroDocumeto} `
    if (matricula !== "") {
        texto += `| Matrícula ${matricula}`
    }
    return texto;
}

export function etiquetaSinDisponibilidad() {
    //GPNC 89
    //GPN  91

    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { ID_SOCIO } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_SOCIO: 0 } : OBTENER_DATOS_USUARIO;

    let label = "Cette article peut être indisponible ou livrable sur commande"
    let textoRetorno = [89, 91].includes(ID_SOCIO) ? label : traducirPagina("Sin_disponibilidad");

    return textoRetorno;
}

export function etiquetaBtnSolicitar() {
    //GPNC 89
    //GPN  91

    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { ID_SOCIO } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_SOCIO: 0 } : OBTENER_DATOS_USUARIO;

    let label = "Je veux cet article! Nous cherchons cette pièce pour vous"
    let textoRetorno = [89, 91].includes(ID_SOCIO) ? label : traducirPagina("btn_solicitar");

    return textoRetorno;
}


export function etiquetaReferenciaSinStock() {
    //GPNC 89
    //GPN  91

    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { ID_SOCIO } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_SOCIO: 0 } : OBTENER_DATOS_USUARIO;

    let label = "Nous pouvons chercher pour vous cette référence"
    let textoRetorno = [89, 91].includes(ID_SOCIO) ? label : traducirPagina("Esta_referencia_no_stock");

    return textoRetorno;
}


export const gestionarDiccionarios = async (TOKEN) => {
    let respuesta = await getDiccionarioEnAPI(TOKEN)
    if (respuesta !== undefined && respuesta !== null && respuesta.REGISTROS !== null) {
        store.dispatch({ type: "STATE_DICCIONARIOS", DICCIONARIO: respuesta.REGISTROS })
    }
}


export const PermisosDocumentacionLatam = () => {
    let resultado = { MANUALES_REPARACION: true, TIEMPOS_REPARACION: true }



    if (esMercadoLatam()) {
        const REGISTROS_LATAM = store.getState().REGISTROS_LATAM;
        if (REGISTROS_LATAM !== undefined && REGISTROS_LATAM !== null) {

            const { MANUALES_REPARACION = true, TIEMPOS_REPARACION = true } = REGISTROS_LATAM
            resultado.MANUALES_REPARACION = MANUALES_REPARACION
            resultado.TIEMPOS_REPARACION = TIEMPOS_REPARACION
        }
    }


    return resultado;
}


export const esMercadoLatam = () => {
    let registros = datosLincenciaSertec()
    let esMercado = false

    if (registros !== undefined && registros !== null) {
        const { ID_MERCADO } = registros
        esMercado = ID_MERCADO === 2 ? true : false
    }
    return esMercado
}

export function obtenerTablasDocumento() {
    let numeroMaximoDeTablasABuscar = 30
    let listaTablas = []
    for (let i = 1; i <= numeroMaximoDeTablasABuscar; i++) {
        let containerTabla = document.getElementById(`tabla_${i}`);
        if (containerTabla === undefined || containerTabla === null) {
            break;
        } else {
            listaTablas.push(`tabla_${i}`)
        }
    }
    return listaTablas
}

export function gestionarPlanServicioHaynesPro(idContainerTabla) {
    let containerTabla = document.getElementById(idContainerTabla);
    if (containerTabla === undefined || containerTabla === null) { return }

    let nodoTabla = containerTabla.children[0];
    let bodyTabla = nodoTabla.children[1];
    let registrosTabla = bodyTabla.children;

    for (let i = 0; i < registrosTabla.length; i++) {
        let registro = registrosTabla[i].children;
        for (let x = 0; x < registro.length; x++) {
            let input = registro[x].children
            if (input.length > 0) {
                input[0].addEventListener('click', function (event) {
                    let checkbox = event.target
                    if (checkbox.checked) {
                        this.setAttribute("checked", "checked");
                        this.checked = true
                    } else {
                        this.removeAttribute("checked");
                        this.checked = false
                    }
                });
            }
        }
    }
}

export const ObtenerIDTipoVehiculo = () => {
    const { tipoBusqueda } = store.getState().BusquedaPorModelo;
    let ID_TIPO = 5
    ID_TIPO = tiposDeVehiculoPorLetra(tipoBusqueda)
    return ID_TIPO;
}

export const tiposDeVehiculoPorLetra = (tipoBusqueda) => {
    let ID_TIPO = 5
    switch (tipoBusqueda) {
        case "C":
            ID_TIPO = 1
            break;
        case "I":
            ID_TIPO = 2
            break;
        case "T": case "MT":
            ID_TIPO = 3
            break;
        case "M":
            ID_TIPO = 4
            break;
        case "E":
            ID_TIPO = 5
            break;
        default:
            ID_TIPO = 1
            break;
    }
    return ID_TIPO;
}

const createQueryUser = (registros) => {
    let cadena = ""

    registros.map((registro, i) => {
        if (![undefined, null, ""].includes(registro.value)) {
            if (cadena === "") {
                cadena = `?${registro.id}=${registro.value}`
            } else {
                cadena += `&${registro.id}=${registro.value}`
            }
        }
    })

    return cadena
}

export const obtenerHistorialUser = () => {
    const { pathname, search } = history.location;
    const query = new URLSearchParams(history.location.search);

    let urlActual = "/referencia"
    if (![undefined, null, ""].includes(search)) {
        const clientId = query.get('clientId');
        const quoteId = query.get('quoteId');
        const searchType = query.get('searchType');
        const searchValue = query.get('searchValue');

        let registros = [
            { id: "clientId", value: clientId },
            { id: "quoteId", value: quoteId },
            { id: "searchType", value: searchType },
            { id: "searchValue", value: searchValue }
        ]

        //&Plate=${Plate}
        //let params = `?ClientId=${ClientId}&QuoteId=${QuoteId}`

        let params = createQueryUser(registros)
        urlActual = `${[undefined, null, "/", ""].includes(pathname) ? urlActual : pathname}${params}`
    }

    return urlActual;
}
export const obtenerRegistroQuery = (ID_PARAM) => {
    const query = new URLSearchParams(history.location.search);
    let registro = query.get(ID_PARAM);
    let resultado = null;
    if (![undefined, null, ""].includes(registro)) {
        resultado = registro;
    }
    return resultado;
}


export const buscarMatriculaAuto = (MATRICULA) => {

    limpiarCocheStore();
    store.dispatch(showItemReferencia(""));
    volverAlHome()
    if (document.getElementById("imputdropcoche") !== null) {
        document.getElementById("imputdropcoche").value = MATRICULA;
    }
    let resultado = "";
    let obtenerBuscadores = store.getState().buscadorCoche;
    if (Object.keys(obtenerBuscadores).length > 0) {
        if (obtenerBuscadores.txtBuscadorCoche === undefined) {
            obtenerBuscadores["txtBuscadorCoche"] = MATRICULA
            obtenerBuscadores["actual"] = { key: "dropcoche", value: MATRICULA }
            resultado = obtenerBuscadores;
        } else {
            obtenerBuscadores.txtBuscadorCoche = MATRICULA;
            obtenerBuscadores.actual = { key: "dropcoche", value: MATRICULA };
            resultado = obtenerBuscadores;
        }

    } else {
        resultado = { "txtBuscadorCoche": MATRICULA, "actual": { key: "dropcoche", value: MATRICULA } };
    }
    store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: resultado })
    store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Matricula"), IDENTIFICADOR: "MAT" })

    busquedaCochePorMatriculaOBastiidor(false, {});
    store.dispatch(DrawerHistoricoCoche(false));
}

export const buscarBastidorAuto = (BASTIDOR) => {
    if (document.getElementById("imputdropcoche") !== null) {
        document.getElementById("imputdropcoche").value = BASTIDOR;

    }
    let resultado = "";
    let obtenerBuscadores = store.getState().buscadorCoche;
    if (Object.keys(obtenerBuscadores).length > 0) {
        if (obtenerBuscadores.txtBuscadorCoche === undefined) {
            obtenerBuscadores["txtBuscadorCoche"] = BASTIDOR
            obtenerBuscadores["actual"] = { key: "dropcoche", value: BASTIDOR }
            resultado = obtenerBuscadores;
        } else {
            obtenerBuscadores.txtBuscadorCoche = BASTIDOR;
            obtenerBuscadores.actual = { key: "dropcoche", value: BASTIDOR };
            resultado = obtenerBuscadores;
        }

    } else {
        resultado = { "txtBuscadorCoche": BASTIDOR, "actual": { key: "dropcoche", value: BASTIDOR } };
    }
    store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: resultado })
    store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Bastidor"), IDENTIFICADOR: "BAS" })

    busquedaCochePorMatriculaOBastiidor(false, {});
    store.dispatch(DrawerHistoricoCoche(false));
}

export const buscarKtypeAuto = async (KTYPE) => {
    const respuesta = await getFichaCocheEnAPI(KTYPE, 1);

    if ([undefined, null].includes(respuesta)) {
       // MSGERROR(traducirPagina("No_hay_vehiculo_seleccionado"))
    } else {
        const { ID, ID_MARCA, ID_MODELO, MARCA, MODELO, MOTORES, MOTORIZACION } = respuesta


        volverAlHome()
        let item = { ID_MARCA: ID_MARCA, MARCA: MARCA, ID_MODELO: ID_MODELO, MODELO: MODELO, ID_MOTORIZACION: ID, MOTOR: MOTORIZACION }

        store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: {} })
        store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
        store.dispatch(showChildrenDrawerLevel2(false));

        //Agregar tipo vehiculo C,I
        //MODIFICAR_TIPO_BUSQUEDA(item.TIPO_VEHICULO)

        let marca = {
            ID: item.ID_MARCA,
            DESCRIPCION: item.MARCA.substring(0, item.MARCA.length > 7 ? 7 : item.MARCA.length) + " ..."
        }

        let modelo = {
            ID: item.ID_MODELO,
            DESCRIPCION: item.MODELO.substring(0, item.MODELO.length > 7 ? 7 : item.MODELO.length) + " ..."
        }

        let objetoVehiculo = {
            ID: item.ID_MOTORIZACION,
            DESCRIPCION: item.MOTOR.substring(0, item.MOTOR.length > 12 ? 12 : item.MOTOR.length) + " ...",
            ID_MODELO_CATALOGO: 0
        };

        store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
        store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
        store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));
        gestionarContenedorCoche(true);
        store.dispatch(updateVisualizacionFiltro(true));

        setHistorialBusqueda({
            MARCA: item.ID_MARCA,
            MODELO: item.ID_MODELO,
            MOTORIZACION: item.ID_MOTORIZACION,
            NOMBRE_MARCA: item.MARCA,
            NOMBRE_MODELO: item.MODELO,
            NOMBRE_MOTOR: item.MOTOR
        })
        store.dispatch(DrawerHistoricoCoche(false));
        store.dispatch(getIdTecdocMotorByIdHaynes(item.ID_MOTORIZACION, item));
        //store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: item.TIPO_COMBUSTIBLE })
        store.dispatch(showItemReferencia("tree"))
    }
}



export const gestionarBusquedaSertec = () => {
    let registro = obtenerRegistroQuery("searchType")
    let registroValue = obtenerRegistroQuery("searchValue")

    if (registro !== null && registroValue !== null) {

        let TIPO_BUSQUEDA = registro.toUpperCase()

        switch (TIPO_BUSQUEDA) {
            case "PLATE":
                buscarMatriculaAuto(registroValue)
                break;
            case "VIN":
                buscarBastidorAuto(registroValue)
                break;
            case "KTYPE":
                buscarKtypeAuto(registroValue)
                break;
        }

    }
}