import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { historialGrupoMontaje, traducirPagina, copiarAlPortapapeles, volverAlHome, obtenerHistorialUser } from "../librerias/funciones"
import {  getFichaCoche, getIdVehicleByTecdocNumber, abortFetch } from "../action/actionCreatorsRequests";
import store from "../store";

import { Card, Input, Row, Col, Drawer, Tabs, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const FichaVehiculoGenerico = ({ props, configApp }) => {
    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("1");

    const datosCochePorMatricula = props.DATOS_VEHICULO


    /*
        eventoClickLineaCoche = (row) => {
            if (props.fichaVehiculoTallerAbierta) {
                store.dispatch(onChildrenDrawerCloseIntegraciones(false));
                store.dispatch({ type: "UPDATE_VEHICULO_TALLER_POR_KTYPE", vehiculoPorApi: row })
            } else {
                let obtenerIdentidficador = props.vehiculo.vehiculoPorMatricula.IDENTIFICADOR !== null ? props.vehiculo.vehiculoPorMatricula.IDENTIFICADOR : ""
                row.MATRICULA = obtenerIdentidficador;
    
                store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                store.dispatch(showChildrenDrawerLevel2(false));
    
                store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: row.TIPO_COMBUSTIBLE })
    
                store.dispatch(showItemReferencia("tree"));
                historialGrupoMontaje(row);
                gestionarContenedorCoche(true);
                //store.dispatch(getFichaCoche(row.ID.toString())); 
                store.dispatch(updateVisualizacionFiltro(true));
                store.dispatch(showChildrenDrawerIntegraciones(false));
                store.dispatch(getIdVehicleByTecdocNumber(row.ID))
                store.dispatch(DrawerHistoricoCoche(false));
    
    
    
    
               let VEHICULO =  {...props.vehiculo.vehiculoPorMatricula};
               VEHICULO.VEHICULOS_TECDOC = [row];
               store.dispatch({ type: "VEHICULO_SERTEC", VEHICULO_SERTEC: VEHICULO })
            }
        };
        */

    const eventoClickLineaCoche = (row) => {

    }



    return (
        <Drawer
            width={1300}
            onClose={() => {
               
                props.CERRAR_FICHA(false)

            }}
            visible={props.ABRIR_FICHA}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '9999999999999', color: '#fff' }}>

            <div className="flex-container-Grow-Tercero" >
                <div style={{ textAlign: 'inherit' }}>
                    <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }}
                        onClick={(e) => {
                        

                            props.CERRAR_FICHA(false)
                        }}>
                        <i aria-label="icon: close" className="anticon anticon-close">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                        </i>
                    </MDBBtn>
                </div>
                <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                    {props.title}
                </div>
</div>
              

                <div className="container-fluid">
                    <MDBRow>
                        <MDBCol md="5">
                            <MDBNav tabs className="nav-justified mt-3" color="primary">

                                <MDBNavItem>
                                    <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "1"} onClick={() => setActiveItemOuterTabs("1")} role="tab">{traducirPagina("Identificación")}</MDBNavLink>
                                </MDBNavItem>

                                <MDBNavItem>
                                    <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "2"} onClick={() => setActiveItemOuterTabs("2")} role="tab" >DGT</MDBNavLink>
                                </MDBNavItem>

                                <MDBNavItem>
                                    <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "3"} onClick={() => setActiveItemOuterTabs("3")} role="tab">{traducirPagina("Ficha_Técnica")}</MDBNavLink>
                                </MDBNavItem>
                            </MDBNav>
                            <MDBTabContent className="card" activeItem={activeItemOuterTabs} style={{ height: (configApp.height - 130).toString() + "px", overflow: 'auto', backgroundColor: "#eee" }}>
                                <MDBTabPane tabId="1" role="tabpanel">
                                    <div style={{ color: '#000', fontSize: '16px' }}>
                                        <div className="row">
                                            <MDBCol md="12">
                                                <h4 className="mt-1 ">{traducirPagina("Datos técnicos")}</h4>
                                                <MDBTable responsive hover>
                                                    {Object.keys(datosCochePorMatricula).length > 0 ?
                                                        datosCochePorMatricula.IDENTIFICACION_VEHICULO !== null && datosCochePorMatricula.IDENTIFICACION_VEHICULO !== undefined ?
                                                            <MDBTableBody>
                                                                {datosCochePorMatricula.MATRICULA !== null ?
                                                                    <tr>
                                                                        <td style={{ textAlign: 'left' }}>{traducirPagina("Matricula")}</td>
                                                                        <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.MATRICULA.Matricula}</td>
                                                                    </tr>
                                                                    : null
                                                                }
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Bastidor")}</td>
                                                                    <td id="textoACopiar" style={{ textAlign: 'left' }}> {datosCochePorMatricula.IDENTIFICACION_VEHICULO.BASTIDOR} </td>
                                                                    <td style={{ textAlign: 'left' }}>{<button onClick={e => { copiarAlPortapapeles('textoACopiar') }} style={{ border: '0', cursor: 'pointer' }} > <CopyOutlined style={{ fontSize: '16px', color: '#000', verticalAlign: 'text-bottom' }} /> </button>}</td>
                                                                </tr>


                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>Fecha 1º matricula </td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO["FECHA 1ª MATRÍCULA"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Fecha_matricula")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO["FECHA MATRÍCULA"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MARCA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Mercado")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MERCADO}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.MODELO}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo_genérico")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO["MODELO GENÉRICO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Número_de_transmisiones")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO["NUMERO DE TRANSMISIONES"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Procedencia")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.PROCEDENCIA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Provincia_matricula")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO["PROVINCIA MATRÍCULA"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Segmento")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.IDENTIFICACION_VEHICULO.SEGMENTO}</td>
                                                                </tr>
                                                            </MDBTableBody>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    }
                                                </MDBTable>
                                            </MDBCol>
                                        </div>
                                    </div>
                                </MDBTabPane>

                                <MDBTabPane tabId="2" role="tabpanel">
                                    <div style={{ color: '#000', fontSize: '16px' }}>
                                        <div className="row">
                                            <MDBCol md="12">
                                                <h4 className="mt-1">{traducirPagina("Datos técnicos")}</h4>
                                                <MDBTable responsive hover>
                                                    {Object.keys(datosCochePorMatricula).length > 0 ?
                                                        datosCochePorMatricula.DATOSTECNICOSDGT !== null && datosCochePorMatricula.DATOSTECNICOSDGT !== undefined ?
                                                            <MDBTableBody>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.CILINDRADA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>CO2</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.CO2}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.COMBUSTIBLE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>KW</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.KW}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.MARCA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.MODELO}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Peso_maximo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT["PESO MAXIMO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Plazas")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.PLAZAS}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_fiscal")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT["POTENCIA FISCAL"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_aproximada")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT["POTENCIA REAL APROXIM"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Tara")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.TARA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.TIPO}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Variante")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.VARIANTE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Versión")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.DATOSTECNICOSDGT.VERSION}</td>
                                                                </tr>
                                                            </MDBTableBody>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    }
                                                </MDBTable>
                                            </MDBCol>
                                        </div>
                                    </div>
                                </MDBTabPane>

                                <MDBTabPane tabId="3" role="tabpanel">
                                    <div style={{ color: '#000', fontSize: '16px' }}>
                                        <div className="row">
                                            <MDBCol md="12">
                                                <h4 className="mt-1">{traducirPagina("Datos_técnicos")}</h4>
                                                <MDBTable responsive hover>
                                                    {Object.keys(datosCochePorMatricula).length > 0 ?
                                                        datosCochePorMatricula.FICHATECNICA !== null && datosCochePorMatricula.FICHATECNICA != undefined ?
                                                            <MDBTableBody>

                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Carroceria")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CARROCERIA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("CAT_HOMOLOGACIÓN")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["CAT HOMOLOGACION"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Cilindrada")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CILINDRADA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>Clasif. Industria</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["CLASIF INDUSTRIA"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>CO2</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CO2}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Codigos_ECO")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["CODIGOS ECO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Combustible")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.COMBUSTIBLE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Consumo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.CONSUMO}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Contraseña_homologación")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["CONTRASEÑA HOLOMOGACION"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Denominacion_comercial")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["DENOMINACION COMERCIAL"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>Dist. entre ejes</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["DIST ENTRE EJES"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>ECO innova</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["ECO INNOVA"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Fabricante")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.FABRICANTE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>KW</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.KW}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Marca")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.MARCA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Masa_max_carga")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["MASA MAX CARGA"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Masa_vehiculo_servicio")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["MASA VEHICULO SERVICIO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Nivel_emisiones")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["NIVEL EMISIONES"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>Num. plazas de pie</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["NUM PLAZAS DE PIE"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Peso_maximo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["PESO MAXIMO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Plazas")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.PLAZAS}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Potencia_fiscal")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["POT FISCAL"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>Potencia real aprox.</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["POTENCIA REAL APROX"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Procedencia")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.PROCEDENCIA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Reducción_eco")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["REDUCCION ECO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Relación_potencia_peso")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["RELACION POTENCIA PESO"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Tara")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.TARA}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.TIPO}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Tipo_alimentación")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["TIPO ALIMENTACION"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Variante")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.VARIANTE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Versión")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA.VERSION}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Via_anterior")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["VIA ANTERIOR"]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'left' }}>{traducirPagina("Via_posterior")}</td>
                                                                    <td style={{ textAlign: 'left' }}>{datosCochePorMatricula.FICHATECNICA["VIA POSTERIOR"]}</td>
                                                                </tr>
                                                            </MDBTableBody>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    }
                                                </MDBTable>
                                            </MDBCol>
                                        </div>
                                    </div>
                                </MDBTabPane>

                            </MDBTabContent>
                        </MDBCol>

                        <MDBCol md="7" style={{ marginTop: '3.4rem' }} className="mdb-skin">

                            <MDBRow>
                                <MDBCol md="12">
                                  
                                    <div style={{ height: (configApp.height - 130).toString() + "px", overflow: 'auto' }}>

                                        <div style={{ color: '#000', fontSize: '16px' }}>

                                            <p className="mt-1">{traducirPagina("Seleccion_vehiculo")}</p>
                                            {Object.keys(datosCochePorMatricula).length > 0 ?
                                                datosCochePorMatricula.VEHICULOS_TECDOC !== undefined && datosCochePorMatricula.VEHICULOS_TECDOC !== null ?
                                                    <MDBTable responsive hover>
                                                        <MDBTableHead>
                                                            <tr>
                                                                <th style={{ textAlign: 'left' }}>{traducirPagina("Motor")}</th>
                                                                <th style={{ textAlign: 'left' }}>{traducirPagina("Codigo_cliente")}</th>
                                                                <th style={{ textAlign: 'left' }}>{traducirPagina("Modelo")}</th>


                                                                <th>{traducirPagina("Año")}</th>
                                                                <th>KW</th>
                                                                <th>CV</th>
                                                                <th>CC</th>
                                                                <th>{traducirPagina("Carroceria")}</th>
                                                                <th></th>
                                                            </tr>
                                                        </MDBTableHead>

                                                        <MDBTableBody>
                                                            {datosCochePorMatricula.VEHICULOS_TECDOC.map((row, i) =>
                                                                <tr key={row.ID} id={row.ID} style={{ padding: '.40rem 1.20rem', cursor: 'pointer' }}>
                                                                    <td style={{ textAlign: 'left' }} onClick={() => {
                                                                        props.MODIFICAR_COCHE(row)
                                                                    }} >{row.MOTORIZACION}</td>

                                                                    <td style={{ textAlign: 'left' }} onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.MOTORES === "" ? "..." : row.MOTORES}</td>

                                                                    <td style={{ textAlign: 'left' }} onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.MODELO === "" ? "..." : row.MODELO}</td>


                                                                    <td onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.DESDE === "" ? "..." : row.DESDE}</td>

                                                                    <td onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.KW === "" ? "..." : row.KW}</td>
                                                                    <td onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.CV === "" ? "..." : row.CV}</td>
                                                                    <td onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.CC === "" ? "..." : row.CC}</td>
                                                                    <td onClick={() => {
                                                                         props.MODIFICAR_COCHE(row)
                                                                    }}>{row.CARROCERIA === "" ? "..." : row.CARROCERIA}
                                                                    </td>
                                                                    <td>
                                                                        {/* 
                                                                        <MDBBtn onClick={e => {
                                                                            createRipple(e);

                                                                            store.dispatch(showChildrenDrawerLevel2(false));
                                                                            store.dispatch({
                                                                                type: "SET_ID_MOTORIZACION_FICHA_HISTORIAL",
                                                                                codMotorizacionFichaHistorial: row.ID,
                                                                            })
                                                                           
                                                                            store.dispatch(DrawerFichaCocheDGT(true))

                                                                        }} className="btn-matricula  estiloBotonRemove paddindDeTres" >
                                                                            <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px' }} className="" alt={"coche"} ></img>
                                                                        </MDBBtn>

 */}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                    : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}>  <div className="spinner-border  fast colorSubAzul" role="status" disabled /></div>

                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                        </MDBCol>
                    </MDBRow>
                </div>
        </Drawer>
    )

}




FichaVehiculoGenerico.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    DATOS_VEHICULO: PropTypes.object,
    MODIFICAR_COCHE: PropTypes.func,

    GUARDAR_DOCUMENTO: PropTypes.func,
    FECHA_LOCAL: PropTypes.object,
    MODIFICAR_DOCUMENTO_SELECCIONADO: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        props: props,
        configApp: state.configApp,
        openDrawerFichaMantenimientoPresupuesto: state.estadoDrawer.openDrawerFichaMantenimientoPresupuesto,
        openDrawerFichaClienteTaller: state.estadoDrawer.openDrawerFichaClienteTaller,
        PresupuestoTallerSeleccionado: state.PresupuestoTallerSeleccionado,
        VehiculoTallerSeleccionado: state.VehiculoTallerSeleccionado
    };
}

export default connect(mapStateToProps)(FichaVehiculoGenerico);
