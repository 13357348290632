import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBContainer, MDBSelect } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import { pad, direccionesByMarca, documentosByRefMarca, getModelosByReferenciaMarcaAndFabricante, referenciaOEMasInfo, fabricantesByReferenciaMarca, buscarDetallesReferencia, ObtenerArticuloByArtNoBrandNoEnAPI, GetVehiculosHaynesByIdTecdocEnAPI, GetTiemposReparacionByGMAndVehicleEnAPI, referenciaOEMasInfov2EnAPI, getPriceOEMEnAPI } from "../action/actionCreatorsRequests"

import ImageView from './CptImageView';
import TablaGenerica from './tablaGenerica'
import ContainerReferenciaGenerico from './ContainerReferenciaGenerico'
import { changeStateImageViewer, DrawerMasInformacion, createRipple, efectoBotonRemove, DrawerFichaEquivalenciaArticulo } from "../action/actionCreators";
import store from '../store'
import { copiarAlPortapapeles, traducirPagina, efectoRemoveBuscador, crearLineasParaElPresupuesto, verificarSiEsMostrador, buscarVehiculoLocal, datosLincenciaSertec, convertirMoneda, esMercadoLatam, idModeloLatam, ObtenerIDTipoVehiculo, obtenerHistorialUser } from "../librerias/funciones"
import {
    CloseOutlined,
    SearchOutlined,
    FileAddOutlined,
    CopyOutlined,
    ShoppingOutlined

} from '@ant-design/icons';
import { Tabs, Skeleton, Space, Divider, Drawer, Row, List, Select, Card, Col, Button, Collapse } from 'antd';
import { FichaDisponibilidad } from './Compra/FIchaDisponibilidad.jsx';
import CPSearch from './utils/CPSearch.jsx';
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;
const MasInformacion = ({ props, masInformacion, configApp, openDrawerMasInfo, mostrarComponente, propiedadesVisor, fichaBusquedaVehiculo, referenciaSeleccionada, codmotorizacion, buscadorTree, DIRECTORIO_DOCUMENTO, usuario }) => {

    const OBTENER_DATOS_USUARIO = datosLincenciaSertec();
    const { ID_SOCIO } = OBTENER_DATOS_USUARIO === undefined || OBTENER_DATOS_USUARIO === null ? { ID_SOCIO: 0 } : OBTENER_DATOS_USUARIO;

    const { CATALOGO } = referenciaSeleccionada === undefined || referenciaSeleccionada === null || Object.keys(referenciaSeleccionada).length === 0 ? { CATALOGO: null } :
        referenciaSeleccionada.REGISTROS === undefined || referenciaSeleccionada.REGISTROS === null ? { CATALOGO: null } : referenciaSeleccionada.REGISTROS


    const { PERMISO_DOCUMENTACION_TECNICA } = usuario.LoginSertec.List_LicenciaSERTE[0];

    const [abrirFichaDisponibilidad, setAbrirFichaDisponibilidad] = React.useState(false);


    const [vehiculoActual, setVehiculoActual] = React.useState({
        EXISTE_VEHICULO: false,
        VEHICULO: {}
    });

    const [disponibilidadSeleccionada, setDisponibilidadSeleccionada] = React.useState({
        REFERENCIA: "",
        MCR: "",
        MARCA_VEH: ""
    });


    const default_referenciaOE = {
        TEXTO_BUSCADOR: "",
        MENSAJE_ERROR: "",
        OK: false,
        PERMITIR_COMPRAR: false,
        REFERENCIAS: []
    }
    const [registrosReferenciaOE, setRegistrosReferenciaOE] = React.useState(default_referenciaOE);


    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("1");
    const [datosReferencia, setDatosReferencia] = React.useState({});
    const [detalles, setDetalles] = React.useState({});
    const [hayReferencia, setHayReferencia] = React.useState(false);
    const [hayReferenciaOE, setHayReferenciaOE] = React.useState(false);
    const [hayDetalles, setHayDetalles] = React.useState(false);
    const [textoPorDefectoTiempos, settextoPorDefectoTiempos] = React.useState(0);
    const [referenciaOE, setReferenciaOE] = React.useState([]);
    const [referenciaOEAux, setReferenciaOEAux] = React.useState([]);
    const [vehiculosHaynes, setVehiculosHaynes] = React.useState([]);
    const [tiempoReparacion, setTiempoReparacion] = React.useState([]);

    if (masInformacion.refOE !== null && masInformacion.refOE !== undefined) {

        if (!hayReferenciaOE && Object.keys(masInformacion.refOE).length > 0) {
            setHayReferenciaOE(true)

            setReferenciaOE(Object.keys(masInformacion.refOE).length === 0 ? null : masInformacion.refOE)
            setReferenciaOEAux(Object.keys(masInformacion.refOE).length === 0 ? null : masInformacion.refOE)

        } else {
            if (Object.keys(masInformacion.refOE).length === 0 && hayReferenciaOE) {
                setHayReferenciaOE(false)
                setReferenciaOE([])
                setReferenciaOEAux([])
            }
        }
    }

    if (!hayReferencia && openDrawerMasInfo) {
        setHayReferencia(true)

        buscarVehiculoLocal().then((vehiculo) => {

            if (vehiculo.MATRICULA !== "" || vehiculo.BASTIDOR !== "") {

                vehiculoActual.EXISTE_VEHICULO = true;
                vehiculoActual.VEHICULO = vehiculo;
                setVehiculoActual({ ...vehiculoActual })
            } else {
                vehiculoActual.EXISTE_VEHICULO = false;
                vehiculoActual.VEHICULO = {};
                setVehiculoActual({ ...vehiculoActual })
            }
        })
        ObtenerArticuloByArtNoBrandNoEnAPI(props.referencia, props.marca, CATALOGO).then(registro => {
            if (registro !== undefined) {
                //Se ejecuta una sola vez la llamada a la API
                //let referencia = undefined;
                if (registro !== null) {
                    // referencia = registros.find((referencia) => referencia.REFERENCIA === props.referencia)
                    setDatosReferencia(registro);
                } else {
                    setDatosReferencia(null);
                }
            }
        })
    } else {
        if (!openDrawerMasInfo && hayReferencia) {
            setDatosReferencia({})
            setHayReferencia(false)
            setActiveItemOuterTabs("1")
            setVehiculosHaynes([])
            setTiempoReparacion([])
        }
    }


    if (!hayDetalles && openDrawerMasInfo) {
        setHayDetalles(true)
        buscarDetallesReferencia({ REFERENCIA: props.referencia, CODIGO_MARCA: props.marca, CATALOGO: CATALOGO }).then(data => {
            if (data !== undefined) {
                setHayDetalles(true)
                setDetalles(data)

                if (referenciaSeleccionada.VERTIEMPOS !== undefined) {
                    setActiveItemOuterTabs("9")



                    GetVehiculosHaynesByIdTecdocEnAPI(codmotorizacion, CATALOGO).then(registro => {
                        if (registro !== undefined) {
                            if (registro !== null && registro.length > 0) {
                                setVehiculosHaynes(registro);

                                const COD_MOTOR = registro[0].IdTiempoReparacion

/*
                                const ID_VEHICULO_CATALOGO = idModeloLatam()
                                const COD_MOTOR = codmotorizacion === undefined || codmotorizacion === null || codmotorizacion == 0 ? ID_VEHICULO_CATALOGO : codmotorizacion
*/
                                valorPorDectoTiempos(registro, COD_MOTOR)
                                cambiosCocheTecdoc(COD_MOTOR, referenciaSeleccionada.GRUPO_MONTAJE);
                            } else {
                                setVehiculosHaynes(null);
                            }
                        }

                    })

                }
                if (referenciaSeleccionada.VERDESPIECE !== undefined) {
                    setActiveItemOuterTabs("6")
                }
                if (referenciaSeleccionada.NUMBERTAG !== undefined) {
                    store.dispatch(documentosByRefMarca(props.referencia, props.marca))
                    setActiveItemOuterTabs(referenciaSeleccionada.NUMBERTAG)
                }

            } else {
                setDetalles(undefined)
                setHayDetalles(true)
            }
        })
    } else {
        if (!openDrawerMasInfo && hayDetalles) {
            setHayDetalles(false)
            setDetalles({})
        }
    }

    const buscarEnTabla = (palabra, registros) => {
        let referenciaEncontrado = [];
        if (palabra !== "") {
            if (registros !== null) {
                registros.find(function (registro, i) {
                    if (registro.NOMBRE_FABRICANTE.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = referenciaEncontrado.find(ref => ref.REFERENCIA_ORIGINAL === registro.REFERENCIA_ORIGINAL && ref.EUCON_ID === registro.REFERENCIA_ORIGINAL)
                        if (existeRegistro === undefined) {
                            referenciaEncontrado.push(registro)
                        }
                    }
                })

                registros.find(function (registro, i) {
                    if (registro.REFERENCIA_ORIGINAL.toLowerCase().includes(palabra.toLowerCase())) {
                        let existeRegistro = referenciaEncontrado.find(ref => ref.REFERENCIA_ORIGINAL === registro.REFERENCIA_ORIGINAL && ref.EUCON_ID === registro.REFERENCIA_ORIGINAL)
                        if (existeRegistro === undefined) {
                            referenciaEncontrado.push(registro)
                        }
                    }
                })

            }

            if (referenciaEncontrado.length > 0 && referenciaEncontrado !== null) {
                setReferenciaOE(referenciaEncontrado);
            } else {
                setReferenciaOE(null);
            }
        } else {
            setReferenciaOE(referenciaOEAux);
        }
    }

    const valorPorDectoTiempos = (vehiculoByHaynes, codmotorizacion) => {
        vehiculoByHaynes.map((vehiculo) => {
            if (vehiculo.IdTiempoReparacion === parseInt(codmotorizacion)) {
                settextoPorDefectoTiempos(vehiculo.IdTiempoReparacion)
            }
        })
    }

    const obtenerPrecioParaReferenciaOE = async (referencias = []) => {
        const registros = referencias === null ? [] : referencias.map((item) => {
            return { PartNo: item.REFERENCIA_ORIGINAL, EuconId: item.EUCON_ID, NOM_FABRICANTE: item.NOMBRE_FABRICANTE,ID_FABRICANTE:item.FABRICANTE }
        })
        const respuesta = await getPriceOEMEnAPI(registros)
        if (respuesta !== undefined && respuesta !== null && respuesta.ListaPreciosOEM !== null && respuesta.ListaPreciosOEM.length > 0) {
            registrosReferenciaOE.REFERENCIAS.map((item) => {
                let find = respuesta.ListaPreciosOEM.find(element => element.PartNo === item.REFERENCIA_ORIGINAL && element.EuconId == item.EUCON_ID)
                if (find !== undefined) {
                    item.PRECIO = find.Price
                }
            })
        }

        setRegistrosReferenciaOE({ ...registrosReferenciaOE })
    }

    const obtenerReferenciasOE = async (referencia, marca, catalogo,codmotorizacion) => {
        setRegistrosReferenciaOE({ ...default_referenciaOE })

        const respuesta = await referenciaOEMasInfov2EnAPI(referencia, marca, catalogo,codmotorizacion)

        if (respuesta !== undefined && respuesta !== null && respuesta.REFERENCIAS !== null && respuesta.REFERENCIAS.length > 0) {
            const registros = respuesta.REFERENCIAS.map((item, i) => { return { VISIBLE: true, ...item } })
            respuesta.REFERENCIAS = registros
        } else {
            respuesta.REFERENCIAS = null
        }

        const { MENSAJE_ERROR, OK, PERMITIR_COMPRAR, REFERENCIAS } = respuesta

        registrosReferenciaOE.PERMITIR_COMPRAR = PERMITIR_COMPRAR
        registrosReferenciaOE.MENSAJE_ERROR = MENSAJE_ERROR
        registrosReferenciaOE.REFERENCIAS = REFERENCIAS
        registrosReferenciaOE.OK = OK
        setRegistrosReferenciaOE({ ...registrosReferenciaOE })

        if (REFERENCIAS !== null) {
            obtenerPrecioParaReferenciaOE(REFERENCIAS)
        }
    }

    const filtroLocalRegistros = (textoAFiltrar, registros) => {
        if (textoAFiltrar.trim() !== "") {
            if (registros !== null) {
                registros.map((item) => {
                    if (item.NOMBRE_FABRICANTE.toUpperCase().includes(textoAFiltrar.toUpperCase()) || item.NOMBRE_FABRICANTE.toUpperCase() === textoAFiltrar.toUpperCase() ||
                        item.REFERENCIA_ORIGINAL.toUpperCase().includes(textoAFiltrar.toUpperCase()) || item.REFERENCIA_ORIGINAL.toUpperCase() === textoAFiltrar.toUpperCase() ||
                        item.PRECIO.toUpperCase().includes(textoAFiltrar.toUpperCase()) || item.PRECIO.toUpperCase() === textoAFiltrar.toUpperCase()
                    ) {
                        item.VISIBLE = true
                    } else {
                        item.VISIBLE = false
                    }
                    return item
                })
            }
        } else {
            registros.map((item) => { return item.VISIBLE = true })
        }

        if (registros !== null) {
            registrosReferenciaOE.REFERENCIAS = registros
            setRegistrosReferenciaOE({ ...registrosReferenciaOE })
        }
    }


    const toggleOuterTabs = (tab) => {
        if (props.marca !== undefined && props.referencia !== undefined) {

            switch (tab) {
                case "1":
                    break;
                case "2":
                    store.dispatch(direccionesByMarca(props.marca, CATALOGO));
                    break;
                case "3":
                    store.dispatch(documentosByRefMarca(props.referencia, props.marca, CATALOGO))
                    break;
                case "4":
                    store.dispatch(documentosByRefMarca(props.referencia, props.marca, CATALOGO))
                    break;
                case "5":
                    //Refactorizacion de las referenciasOE
                    registrosReferenciaOE.TEXTO_BUSCADOR = ""
                    setRegistrosReferenciaOE({ ...registrosReferenciaOE })

                    obtenerReferenciasOE(props.referencia, props.marca, CATALOGO,codmotorizacion);
                    //store.dispatch(referenciaOEMasInfo(props.referencia, props.marca, CATALOGO));
                    break;
                case "6":
                    break;
                case "8":
                    //store.dispatch(fabricantesByReferenciaMarca(props.referencia, props.marca))
                    break;
                case "7":
                    store.dispatch(fabricantesByReferenciaMarca(props.referencia, props.marca, CATALOGO,codmotorizacion))
                    break;
                case "9":
                    GetVehiculosHaynesByIdTecdocEnAPI(codmotorizacion, CATALOGO).then(registro => {
                        if (registro !== undefined) {
                            if (registro !== null && registro.length > 0) {
                                setVehiculosHaynes(registro);
                                const COD_MOTOR = registro[0].IdTiempoReparacion
/*
                                const ID_VEHICULO_CATALOGO = idModeloLatam()
                                const COD_MOTOR = codmotorizacion === undefined || codmotorizacion === null || codmotorizacion == 0 ? ID_VEHICULO_CATALOGO : codmotorizacion
*/
                                valorPorDectoTiempos(registro, COD_MOTOR)
                                cambiosCocheTecdoc(COD_MOTOR, datosReferencia.GRUPO_MONTAJE);
                            } else {
                                setVehiculosHaynes(null);
                            }
                        }

                    })
                    break;
            }
        }
        if (activeItemOuterTabs !== tab) {
            setActiveItemOuterTabs(tab)
        }
    };

    const cambiosCocheTecdoc = (idTiempo, grupoMontaje) => {
        settextoPorDefectoTiempos(idTiempo)

        GetTiemposReparacionByGMAndVehicleEnAPI(idTiempo, grupoMontaje, undefined, CATALOGO).then(registro => {
            if (registro !== undefined) {
                if (registro !== null && registro.length > 0) {
                    setTiempoReparacion(registro);
                } else {
                    setTiempoReparacion(null);
                }
            }

        })
    }



    const closeImageView = () => {
        let nodo = document.getElementById("container_viewer");
        if (nodo !== null) {
            nodo.style.display = "none";
        }
        store.dispatch(changeStateImageViewer(false, ""))
    }


    const imagenReferencia = (registro) => {
        let imagen = ""
        const { IMAGEN_URL, IMAGEN } = registro

        if (IMAGEN_URL === null || IMAGEN_URL === "") {
            imagen = `${DIRECTORIO_DOCUMENTO}${pad(parseInt(datosReferencia.CODIGO_MARCA), 4).toString()}/${IMAGEN}`
        } else {
            imagen = IMAGEN_URL
        }
        return imagen
    }

    const documentoReferencia = (registro) => {
        let documento = ""
        let nuevoDirectorio = ""
        const { DOCUMENTO_URL, DOCUMENTO, TIPO_DOCUMENTO } = registro

        if (TIPO_DOCUMENTO === "PDF") {
            nuevoDirectorio = DIRECTORIO_DOCUMENTO.replace("IMG", "DOC")
        } else {
            nuevoDirectorio = DIRECTORIO_DOCUMENTO
        }

        if (DOCUMENTO_URL === null || DOCUMENTO_URL === "") {
            documento = `${nuevoDirectorio}${pad(parseInt(datosReferencia.CODIGO_MARCA), 4).toString()}/${DOCUMENTO}`
        } else {
            documento = DOCUMENTO_URL
        }

        return documento
    }

    const PERMITIR_TAB_LATAM = esMercadoLatam()
    console.log("obtenerHistorialUser()",obtenerHistorialUser())

    return (

        <>
            <Drawer
                width={activeItemOuterTabs === "6" || activeItemOuterTabs === "8" ? 1200 : 900}
                onClose={() => {

                    store.dispatch(DrawerMasInformacion(false));
                    closeImageView()

                }}
                visible={openDrawerMasInfo}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={activeItemOuterTabs === "8" ? { color: '#fff' } : { zIndex: '99999999', color: '#fff' }}>

                <div className="flex-container-Grow-Tercero">
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            store.dispatch(DrawerMasInformacion(false));
                            createRipple(e)
                            closeImageView()
                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div style={{ padding: '0px 20px' }}>
                    <MDBNav tabs className="nav-justified mt-3" color="primary">
                        <MDBNavItem>
                            <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "1"} onClick={() => { toggleOuterTabs("1") }} role="tab" >
                                {traducirPagina("Informacion")}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "2"} onClick={() => { toggleOuterTabs("2") }} role="tab" >
                                {traducirPagina("Direccion")}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "3"} onClick={() => { toggleOuterTabs("3") }} role="tab" >
                                {traducirPagina("Imagenes")}
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "4"} onClick={() => { toggleOuterTabs("4") }} role="tab" >
                                {traducirPagina("Documentos")}
                            </MDBNavLink>
                        </MDBNavItem>
                        {!PERMITIR_TAB_LATAM && (

                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "8"} onClick={() => { toggleOuterTabs("8") }} role="tab" >
                                    {traducirPagina("Equivalencias")}
                                </MDBNavLink>
                            </MDBNavItem>
                        )}

                        {!PERMITIR_TAB_LATAM && (
                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "5"} onClick={() => { toggleOuterTabs("5") }} role="tab" >
                                    Refs. OE
                                </MDBNavLink>
                            </MDBNavItem>
                        )}
                        <MDBNavItem style={detalles !== undefined ? Object.keys(detalles).length > 0 ? detalles.CON_DESGLOSE ? {} : { display: 'none' } : {} : {}}>
                            <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "6"} onClick={() => { toggleOuterTabs("6") }} role="tab" >
                                {traducirPagina("Despiece")}
                            </MDBNavLink>
                        </MDBNavItem>
                        {!PERMITIR_TAB_LATAM && (
                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "7"} onClick={() => { toggleOuterTabs("7") }} role="tab" >
                                    {traducirPagina("Vehiculos")}
                                </MDBNavLink>
                            </MDBNavItem>
                        )}
                        <MDBNavItem style={codmotorizacion !== undefined ? codmotorizacion !== "" && PERMISO_DOCUMENTACION_TECNICA ? {} : { display: 'none' } : {}} >
                            <MDBNavLink disabled={ObtenerIDTipoVehiculo() === 2} className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "9"} onClick={() => { toggleOuterTabs("9") }} role="tab" >
                                {traducirPagina("Tiempos")}
                            </MDBNavLink>
                        </MDBNavItem>

                    </MDBNav>
                    <MDBTabContent className="card" activeItem={activeItemOuterTabs} style={{ height: (configApp.height - 130).toString() + "px", overflow: 'auto', backgroundColor: "#eee" }}>
                        <MDBTabPane tabId="1" role="tabpanel">

                            <div style={{ color: '#000', fontSize: '16px' }} id="tab_Informacion">
                                <div className="row">
                                    <MDBCol md="9">
                                        <h4>{traducirPagina("General")} </h4>
                                        {datosReferencia !== undefined && datosReferencia !== null ?
                                            Object.keys(datosReferencia).length > 0 ?
                                                <div>
                                                    <span style={{ fontWeight: "bold" }}>{traducirPagina("Articulo")}: {datosReferencia.REFERENCIA} </span>
                                                    <br />
                                                    <span style={{ fontWeight: "bold" }}>{traducirPagina("Marca")}: {datosReferencia.NOMRE_MARCA} </span>
                                                </div>
                                                : null
                                            :
                                            null
                                        }
                                        <br />
                                        {detalles !== undefined && detalles !== null ?
                                            Object.keys(detalles).length > 0 ?
                                                <div style={{ fontWeight: "bold" }}>
                                                    <span>EAN: {detalles.EAN} </span>
                                                    <br />
                                                    <span>
                                                        {traducirPagina("Referencias_Comerciales")} : <span className="colorPrincipal">{detalles.REFERENCIA_COMERCIAL}</span>
                                                    </span>
                                                    <br />
                                                    {detalles.SUSTITUIDO_POR === null || detalles.SUSTITUIDO_POR === "" ?
                                                        null
                                                        :
                                                        <div>
                                                            <span>
                                                                {traducirPagina("Sustituido_Por")}: <span className="colorPrincipal"> {detalles.SUSTITUIDO_POR}</span>
                                                            </span>
                                                            <br />
                                                        </div>
                                                    }
                                                    {detalles.SUSTITUYE_A === null || detalles.SUSTITUYE_A === "" ?
                                                        null
                                                        :
                                                        <div>
                                                            <span>
                                                                {traducirPagina("Sustituye_A")}: <span className="colorPrincipal"> {detalles.SUSTITUYE_A}</span>
                                                            </span>
                                                            <br />
                                                        </div>
                                                    }
                                                    <span>{traducirPagina("Estado")}: {detalles.DESCRIPCION_ESTADO}</span><br />
                                                </div>
                                                : null
                                            :
                                            null}
                                    </MDBCol>

                                    <MDBCol md="3" style={{ margin: "auto" }}>
                                        <MDBCard >
                                            <MDBCardBody style={{ textAlign: 'center' }}>
                                                {datosReferencia !== undefined && datosReferencia !== null ?
                                                    (Object.keys(datosReferencia).length > 0) ?
                                                        <img src={datosReferencia.IMAGEN_MARCA === "" ? "./imagenes/sinimagen.jpg" : DIRECTORIO_DOCUMENTO + pad(parseInt(datosReferencia.CODIGO_MARCA), 4).toString() + "/" + datosReferencia.IMAGEN_MARCA} alt="" onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} />
                                                        : null
                                                    :
                                                    null
                                                }
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </div>
                                <br />
                                <div className="row">
                                    {detalles !== undefined && detalles !== null ?
                                        Object.keys(detalles).length > 0 ?
                                            <MDBCol md="12">
                                                <h4>{traducirPagina("Criterios")}</h4>
                                                <MDBTable responsive hover borderless>
                                                    <MDBTableHead>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </MDBTableHead>
                                                    <MDBTableBody>
                                                        {detalles.PROPIEDADES !== null && (
                                                            detalles.PROPIEDADES.split("|").map((row, i) =>
                                                                <tr key={i} id={i}>
                                                                    {row.split(":").map((item, i) =>
                                                                        <td key={i} style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold", width: i === 0 ? '350px' : '' }}>{item} </td>
                                                                    )}
                                                                </tr>
                                                            )
                                                        )}
                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCol>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>

                                        : null
                                    }
                                </div>
                            </div>
                        </MDBTabPane>

                        <MDBTabPane tabId="2" role="tabpanel">
                            <MDBRow>
                                <MDBCol md="9">
                                    {Object.keys(masInformacion).length > 0 ?
                                        Object.keys(masInformacion.direccion).length > 0 ?
                                            <MDBTable responsive hover >
                                                <MDBTableHead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </MDBTableHead>

                                                <MDBTableBody>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Nombre")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.NOMBRE_MARCA_CORTO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Direccion")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.DIRECCION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Pais")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.NOMBRE_PAIS}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Codigo_postal")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.CODIGO_POSTAL}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Ciudad")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.POBLACION}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Telefono")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.TELEFONO}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Fax")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.FAX}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Web")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{<a href={"http://" + masInformacion.direccion.WEB} target="_blank">{masInformacion.direccion.WEB}</a>}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Email")}</td>
                                                        <td style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{masInformacion.direccion.EMAIL}</td>
                                                    </tr>


                                                </MDBTableBody>

                                            </MDBTable>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                        :
                                        null
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                            <MDBContainer style={{ textAlign: 'center' }}>
                                {masInformacion.imagenes !== null && masInformacion.imagenes !== undefined ?
                                    Object.keys(masInformacion.imagenes).length > 0 ?
                                        <div className="mdb-lightbox no-margin">
                                            <div className="row">
                                                {masInformacion.imagenes.map((row, i) =>
                                                    <MDBCol key={i} md="4" className={"pr-2 pt-2"} >

                                                        <MDBCard style={{ cursor: "pointer" }} onClick={() => {
                                                            store.dispatch(changeStateImageViewer(true, { titulo: datosReferencia.NOMRE_MARCA, url: imagenReferencia(row) }))
                                                            document.getElementById("container_viewer") !== null ? document.getElementById("container_viewer").style.display = "" : document.getElementById("container_viewer")

                                                        }}>

                                                            <MDBCardBody style={{ textAlign: 'center' }}>
                                                                {datosReferencia !== undefined && datosReferencia !== null ?
                                                                    <img
                                                                        src={imagenReferencia(row)}
                                                                        alt=""
                                                                        className="img-fluid"
                                                                        style={{ height: '140px', cursor: 'pointer' }}
                                                                    />
                                                                    :
                                                                    null
                                                                }
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </MDBCol>
                                                )}
                                            </div>
                                        </div>
                                        :
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                    :
                                    <h4>{traducirPagina("Sin_imagenes")}</h4>
                                }
                            </MDBContainer>
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            <MDBContainer style={{ textAlign: 'center' }}>
                                {masInformacion.documentos !== null && masInformacion.documentos !== undefined ?
                                    masInformacion.imagenes !== null && masInformacion.imagenes !== undefined ?
                                        Object.keys(masInformacion.documentos).length > 0 ?
                                            <div className="mdb-lightbox no-margin">
                                                <div className="row ">
                                                    {masInformacion.documentos.map((row, i) =>
                                                        <MDBCol key={i} md="4" className={"pr-2 pt-2"} >
                                                            <MDBCard style={{ cursor: "pointer" }} onClick={() => {
                                                                store.dispatch(changeStateImageViewer(row.TIPO_DOCUMENTO === "" || row.TIPO_DOCUMENTO === "ZIP" ? false : true,
                                                                    { titulo: datosReferencia.NOMRE_MARCA, url: documentoReferencia(row) }

                                                                ));

                                                                document.getElementById("container_viewer") !== null ? document.getElementById("container_viewer").style.display = "" : document.getElementById("container_viewer")
                                                            }}>
                                                                <MDBCardBody style={{ textAlign: 'center' }}>
                                                                    {datosReferencia !== undefined && datosReferencia !== null ?
                                                                        <img
                                                                            src={row.TIPO_DOCUMENTO === "PDF" ?
                                                                                "./imagenes/pdf_image.png"
                                                                                : row.TIPO_DOCUMENTO === "" ?
                                                                                    "./imagenes/sinimagen.jpg"
                                                                                    : row.TIPO_DOCUMENTO === "ZIP" ?
                                                                                        "./imagenes/icono_zip.png"
                                                                                        :
                                                                                        documentoReferencia(row)
                                                                            }
                                                                            alt=""
                                                                            className="img-fluid"
                                                                            style={{ height: '140px' }}
                                                                        />
                                                                        :
                                                                        null}
                                                                </MDBCardBody>
                                                            </MDBCard>
                                                        </MDBCol>
                                                    )}
                                                </div>
                                            </div>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>

                                        :
                                        <h4>{traducirPagina("Sin_documentos")}</h4>
                                    : null
                                }
                            </MDBContainer>
                        </MDBTabPane>

                        <MDBTabPane tabId="5" role="tabpanel">
                            {

                                registrosReferenciaOE.REFERENCIAS === null ?
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <h3>{traducirPagina("No_resultados")}</h3> </div>
                                    :
                                    registrosReferenciaOE.REFERENCIAS.length === 0 ?
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                        :
                                        <>
                                            <div style={{ paddingLeft: '15px' }}>
                                                <CPSearch
                                                    className='mdb-skin mb-2 searchMasinfo'
                                                    TEXTO_BUSCADOR={registrosReferenciaOE.TEXTO_BUSCADOR}
                                                    placeholder={traducirPagina("Buscar_por_referencia")}
                                                    onChangeInput={(e) => {
                                                        let txtBuscadorLoal = e.target.value;
                                                        registrosReferenciaOE.TEXTO_BUSCADOR = txtBuscadorLoal
                                                        setRegistrosReferenciaOE({ ...registrosReferenciaOE })
                                                        filtroLocalRegistros(txtBuscadorLoal, registrosReferenciaOE.REFERENCIAS)

                                                    }}
                                                    onCloseInput={() => {
                                                        let txtBuscadorLoal = "";
                                                        registrosReferenciaOE.TEXTO_BUSCADOR = txtBuscadorLoal
                                                        setRegistrosReferenciaOE({ ...registrosReferenciaOE })
                                                        filtroLocalRegistros(txtBuscadorLoal, registrosReferenciaOE.REFERENCIAS)
                                                    }}

                                                />
                                            </div>

                                            <div style={{ padding: "0px 15px" }}>
                                                <MDBTable responsive hover >
                                                    <MDBTableHead>
                                                        <tr>
                                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Marca")}</th>
                                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold" }}>{traducirPagina("Referencia_original")}</th>
                                                            <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold", textAlign: 'right' }}>{traducirPagina("Precio")}</th>

                                                            {registrosReferenciaOE.PERMITIR_COMPRAR && vehiculoActual.EXISTE_VEHICULO ? <th style={{ paddingLeft: 0, fontSize: "16px", fontWeight: "bold", textAlign: 'center', width: '125px' }}>{traducirPagina("Comprar")}</th> : null}

                                                        </tr>
                                                    </MDBTableHead>

                                                    <MDBTableBody>
                                                        {registrosReferenciaOE.REFERENCIAS.filter((registro) => registro.VISIBLE === true).map((item, key) =>
                                                            <tr key={key}>
                                                                <td style={{ paddingLeft: 0, fontSize: "16px" }} onClick={() => {
                                                                    store.dispatch({ type: "STATE_UPDATE_EQUIVALENCIA_ARTICULO_SELECCIONADO", marca: item.MARCA, referencia: item.REFERENCIA_ORIGINAL, artno: item.ARTICULO });
                                                                    store.dispatch(DrawerFichaEquivalenciaArticulo(true));
                                                                }} >{item.NOMBRE_FABRICANTE}</td>

                                                                <td style={{ paddingLeft: 0, fontSize: "16px" }}>
                                                                    <CopyOutlined onClick={(e) => copiarAlPortapapeles("refCopiarAPortapapel" + item.REFERENCIA_ORIGINAL)} style={{ fontSize: '18px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer', paddingRight: '10px' }} />
                                                                    <span id={"refCopiarAPortapapel" + item.REFERENCIA_ORIGINAL} onClick={() => {
                                                                        store.dispatch({ type: "STATE_UPDATE_EQUIVALENCIA_ARTICULO_SELECCIONADO", marca: item.MARCA, referencia: item.REFERENCIA_ORIGINAL, artno: item.ARTICULO });
                                                                        store.dispatch(DrawerFichaEquivalenciaArticulo(true));
                                                                    }}
                                                                    > {item.REFERENCIA_ORIGINAL} </span>
                                                                </td>

                                                                <td style={{ paddingLeft: 0, fontSize: "16px", textAlign: 'right', color: item.PRECIO.toString().includes("0.00") ? "#999" : "" }} onClick={() => {
                                                                    store.dispatch({ type: "STATE_UPDATE_EQUIVALENCIA_ARTICULO_SELECCIONADO", marca: item.MARCA, referencia: item.REFERENCIA_ORIGINAL, artno: item.ARTICULO });
                                                                    store.dispatch(DrawerFichaEquivalenciaArticulo(true));
                                                                }}>{item.PRECIO === 0 ? <span className="skeleton-box" style={{ width: '70px' }}></span> : item.PRECIO}</td>
                                                                

                                                                {registrosReferenciaOE.PERMITIR_COMPRAR && vehiculoActual.EXISTE_VEHICULO ?
                                                                    <td style={{ paddingLeft: 0, fontSize: "16px", textAlign: 'center' }} onClick={() => {
                                                                        disponibilidadSeleccionada.REFERENCIA = item.REFERENCIA_ORIGINAL
                                                                        disponibilidadSeleccionada.MCR = item.MCR_ID
                                                                        disponibilidadSeleccionada.MARCA_VEH = item.NOMBRE_FABRICANTE

                                                                        setDisponibilidadSeleccionada({ ...disponibilidadSeleccionada })

                                                                        setAbrirFichaDisponibilidad(true)

                                                                    }}>{<ShoppingOutlined style={{ fontSize: '22px' }}></ShoppingOutlined>}</td>
                                                                    :
                                                                    null
                                                                }

                                                            </tr>
                                                        )}
                                                    </MDBTableBody>

                                                </MDBTable>
                                            </div>
                                        </>


                            }

                        </MDBTabPane>

                        <MDBTabPane tabId="6" role="tabpanel">
                            {
                                <ContainerReferenciaGenerico tabActivado={activeItemOuterTabs} tipoComponente={"masInfo"} referencia={props.referencia} marca={props.marca}></ContainerReferenciaGenerico>
                            }
                        </MDBTabPane>

                        <MDBTabPane tabId="8" role="tabpanel">
                            {
                                <ContainerReferenciaGenerico tabActivado={activeItemOuterTabs} tipoComponente={"Equivalencia"} referencia={props.referencia} marca={props.marca} eventoStockGrupo={() => { }} catalogo={CATALOGO}> </ContainerReferenciaGenerico>
                            }
                        </MDBTabPane>

                        <MDBTabPane tabId="7" role="tabpanel">
                            {masInformacion.vehiculos !== null && masInformacion.vehiculos !== undefined ?
                                Object.keys(masInformacion.vehiculos).length > 0 ?
                                    <div className="mdb-lightbox no-margin">
                                        <div className="row" id="maInfoMarcas" style={{ padding: "0px 15px" }}>
                                            {masInformacion.vehiculos.map((row, i) =>
                                                <MDBCol key={i} md="4" className={"pr-2 pt-2"} onClick={() => {
                                                    document.getElementById("maInfoModelos").style.display = ""; document.getElementById("maInfoMarcas").style.display = "none";
                                                    store.dispatch(getModelosByReferenciaMarcaAndFabricante(datosReferencia.REFERENCIA, datosReferencia.CODIGO_MARCA, row.ID))
                                                    store.dispatch({ type: "REPLACE_VEHICULO_IDMARCA", idmarca: row })
                                                }}>
                                                    <MDBCard style={{ cursor: "pointer" }}>
                                                        <MDBCardBody style={{ textAlign: 'center' }}>
                                                            <span style={{ fontSize: '20px', color: '#000' }}>{row.FABRICANTE}</span>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                </MDBCol>
                                            )}
                                        </div>

                                        <div style={{ display: "none" }} id="maInfoModelos">
                                            <TablaGenerica fichaBusquedaVehiculo={fichaBusquedaVehiculo} tipoTabla={"MODELOS"} detalles={datosReferencia}></TablaGenerica>
                                        </div>

                                        <div style={{ display: "none" }} id="maInfoMotorizacion">
                                            <TablaGenerica fichaBusquedaVehiculo={fichaBusquedaVehiculo} tipoTabla={"MOTORIZACION"} detalles={datosReferencia}></TablaGenerica>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '80vh' }}> <div style={{ color: 'black' }} color="mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                :
                                null
                            }
                        </MDBTabPane>

                        <MDBTabPane tabId="9" role="tabpanel">
                            {vehiculosHaynes !== null ?
                                <select value={textoPorDefectoTiempos} className="custom-select" style={{ width: "400px", marginTop: '20px' }} onChange={(e) => cambiosCocheTecdoc(e.target.value, datosReferencia.GRUPO_MONTAJE)}>
                                    {vehiculosHaynes.length > 0 ?
                                        vehiculosHaynes.map((vehiculo) =>
                                            <option key={vehiculo.IdTiempoReparacion} value={vehiculo.IdTiempoReparacion}>{vehiculo.Fabricante + " & " + vehiculo.Modelo}</option>
                                        )
                                        :
                                        null
                                    }
                                </select>
                                : null}


                            {tiempoReparacion !== null ?
                                tiempoReparacion.length > 0 ?
                                    <div>

                                        <br />

                                        <Collapse>
                                            {tiempoReparacion.map((tiempo, indice) =>
                                                <Panel header={"[ " + tiempo.Tiempo + " " + traducirPagina("label_horas") + " " + " ] " + tiempo.Descripcion}
                                                    extra={<button onClick={(e) => {
                                                        crearLineasParaElPresupuesto(tiempo, "T");
                                                    }}
                                                        className="activebtnMenu2 col-md-12"
                                                        style={{ height: '40px', width: '210px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: 'dcdcdc', marginBottom: '5px', display: '' }}
                                                    >
                                                        <FileAddOutlined style={{ fontSize: '20px' }} />
                                                        <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("Añadir_a_presupuesto")}</span>
                                                    </button>
                                                    }
                                                    key={(indice + 1).toString()}>

                                                    {tiempo.ARTICULOS.length === 0 ?
                                                        null
                                                        :
                                                        <div>
                                                            <strong>{traducirPagina("Articulos_implicados")}</strong>
                                                            {tiempo.ARTICULOS.map((articulo) =>
                                                                <p>{articulo.Descripcion}</p>
                                                            )}
                                                        </div>
                                                    }


                                                    {tiempo.TAREAS_REPARACION.length === 0 ?
                                                        null
                                                        :
                                                        <div>
                                                            <hr />
                                                            <strong>{traducirPagina("Tareas_a_realizar")}</strong>
                                                            {tiempo.TAREAS_REPARACION.map((tareas, i) =>
                                                                <p key={i}>{tareas.Descripcion}</p>
                                                            )}
                                                        </div>
                                                    }


                                                </Panel>
                                            )
                                            }
                                        </Collapse>
                                    </div>
                                    :
                                    null
                                : null}
                        </MDBTabPane>

                    </MDBTabContent>
                    {
                        mostrarComponente ? <ImageView name={propiedadesVisor.url} config={configApp} titulo={propiedadesVisor.titulo} /> : null
                    }
                </div>
            </Drawer>

            {
                abrirFichaDisponibilidad &&
                <FichaDisponibilidad
                    title={traducirPagina("Comprar").toUpperCase()}
                    ABRIR_FICHA={abrirFichaDisponibilidad}
                    CERRAR_FICHA={setAbrirFichaDisponibilidad}
                    REFERENCIA={disponibilidadSeleccionada.REFERENCIA}
                    MCR_ID={disponibilidadSeleccionada.MCR}
                    VEHICULO={vehiculoActual.VEHICULO}
                    MARCA_VEH={disponibilidadSeleccionada.MARCA_VEH}

                >
                </FichaDisponibilidad>
            }

        </>

    )
}

MasInformacion.propTypes = {
    onClick: PropTypes.func,
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,

    activeItemOuterTabs: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        fichaBusquedaVehiculo: state.fichaBusquedaVehiculo,
        masInformacion: state.masInformacion,
        configApp: state.configApp,
        props: props,
        allCourse: state.allCourse,
        openDrawerMasInfo: state.estadoDrawer.openDrawerMasInfo,
        mostrarComponente: state.mostrarComponente,
        propiedadesVisor: state.propiedadesVisor,
        referenciaSeleccionada: state.referenciaSeleccionada,
        codmotorizacion: state.codmotorizacion,
        buscadorTree: state.buscadorTree,
        DIRECTORIO_DOCUMENTO: state.DIRECTORIO_DOCUMENTO,
        usuario: state.usuario
    };
}

export default connect(mapStateToProps)(MasInformacion);