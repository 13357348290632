import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import { MDBContainer, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBListGroup, MDBListGroupItem } from 'mdbreact';
import { Drawer } from 'antd';
import { DrawerHistoricoCoche, createRipple, estadoAgrupacionDeMarcas, showChildrenDrawerLevel2, showItemReferencia, setHistoricApp, gestionarContenedorCoche, updateVisualizacionFiltro, efectoBotonRemove, DrawerFichaCocheDGT, showChildrenDrawerIntegraciones, activeItemTipoIntegracion } from "../action/actionCreators";
import store from "../store";
import { getHistorialBusqueda, getIdTecdocMotorByIdHaynes, setHistorialBusqueda, ObtenerEngNoByEngCodeEnAPI } from "../action/actionCreatorsRequests";
import { List, Avatar } from 'antd';
import { traducirPagina, busquedaCochePorMatriculaOBastiidor, MSGERROR, obtenerToken, buscarReferenciaYMostrarEnPantalla, limpiarCocheStore, copiarAlPortapapeles, obtenerDatosUsuarioYLicenciaActual, volverAlHome, MODIFICAR_TIPO_BUSQUEDA, esMercadoLatam, obtenerHistorialUser, buscarMatriculaAuto, buscarBastidorAuto } from "../librerias/funciones"
import { CopyOutlined } from '@ant-design/icons';
import FichaCodigoMotor from './FichaCodigoMotor';
import DetallesCocheDGT from './DetallesCocheDGT';
import InformacionDetalladaCoche from './Latam/InformacionDetalladaCoche';

const FichaHistoricoCoche = ({ props, configApp, eventFichaDetallesLatam = () => { } }) => {
    const [fichaAbierta, setFichaAbierta] = React.useState(false);
    const [motores, setMotores] = React.useState([]);


    const [activeItemOuterTabs, setActiveItemOuterTabs] = React.useState("1");
    const [todosLosVehiculos, setTodosLosVehiculos] = React.useState([]);
    const [todasLasReferencia, setTodasLasReferencia] = React.useState([]);
    const [todosLosBastidores, setTodosLosBastidores] = React.useState([]);
    const [todasLasMatriculas, setTodasLasMatriculas] = React.useState([]);
    const [buacarUnaSolaVez, setBuacarUnaSolaVez] = React.useState(true);
    const [todasLosMotores, setTodasLosMotores] = React.useState([]);



    const [abrirFichaDGT, setAbrirFichaDGT] = React.useState(false);
    const [abrirFichaLatam, setAbrirFichaLatam] = React.useState(false);
    const [idVehiculoLatam, setIdVehiculoLatam] = React.useState("");

    const [registrosVehDGT, setRegistrosVehDGT] = React.useState({});




    const toggleOuterTabs = (tab) => {
        switch (tab) {
            case "1":
                getHistorialBusqueda(1).then((data) => {
                    setTodasLasMatriculas(data === null ? [] : data)
                })


                break;
            case "2":
                getHistorialBusqueda(4).then((data) => {
                    setTodosLosVehiculos(data === null ? [] : data)
                })


                break;
            case "3":
                getHistorialBusqueda(3).then((data) => {
                    setTodasLasReferencia(data === null ? [] : data)
                })

                break;
            case "4":
                getHistorialBusqueda(2).then((data) => {
                    setTodosLosBastidores(data === null ? [] : data)
                })
                break;
            case "5":
                getHistorialBusqueda(5).then((data) => {
                    setTodasLosMotores(data === null ? [] : data)
                })
                break;
        }

        if (activeItemOuterTabs !== tab) {
            setActiveItemOuterTabs(tab)
        }
    };



    const ObtenerEngNoByEngCode = (textoABuscar) => {
        ObtenerEngNoByEngCodeEnAPI(textoABuscar).then(registro => {
            if (registro !== undefined) {
                if (registro.REGISTROS !== null && registro.REGISTROS.length > 0) {
                    setMotores(registro.REGISTROS)
                    setFichaAbierta(true)
                } else {
                    setMotores(null)
                    MSGERROR(traducirPagina("msgMotor"))
                }
            }
        })
    }



    useEffect(() => {
        getHistorialBusqueda(1).then((data) => {
            setTodasLasMatriculas(data === null ? [] : data)
        })
    }, [])

    /*
        let previaToken = obtenerToken();
        if (todosLosVehiculos !== undefined && previaToken !== null && props.verFichaHistorico) {
    
            if (todosLosVehiculos.length === 0 && buacarUnaSolaVez) {
                setBuacarUnaSolaVez(false)
                getHistorialBusqueda(1).then((data) => {
                    setTodosLosVehiculos(data === null ? [] : data)
                    setBuacarUnaSolaVez(false)
                })
            }
        } else {
            if (!props.verFichaHistorico && todosLosVehiculos.length > 0) {
                if (activeItemOuterTabs !== "1") {
                    setActiveItemOuterTabs("1");
                }
                setTodosLosVehiculos([]);
                setBuacarUnaSolaVez(true)
            }
        }
    */



    const verificarAtributo = (atributo) => {
        return atributo === "" ? "" : " / ";
    }

    return (
        <>
            <Drawer
                width={"50%"}
                closable={false}
                onClose={() => { store.dispatch(DrawerHistoricoCoche(false)); }}
                visible={props.verFichaHistorico}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ zIndex: '999', color: '#fff' }}>
                <div>
                    <div className="flex-container-Grow-Tercero">
                        <div style={{ textAlign: 'inherit' }}>
                            <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerHistoricoCoche(false)); createRipple(e) }} >
                                <i aria-label="icon: close" className="anticon anticon-close">
                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                    </svg>
                                </i>
                            </MDBBtn>
                        </div>

                        <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                            {props.title}
                        </div>
                    </div>


                    <MDBContainer>
                        <MDBNav tabs className="nav-justified mt-3" color="primary">
                            <MDBNavItem style={{ display: obtenerDatosUsuarioYLicenciaActual().PERMISO_MATRICULAS ? '' : 'none' }}>
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "1"} onClick={() => toggleOuterTabs("1")} role="tab"> {traducirPagina("Matriculas")}</MDBNavLink>
                            </MDBNavItem>

                            <MDBNavItem className="mr-1">
                                <MDBNavLink className="headerTabReferencia " link to={obtenerHistorialUser()} active={activeItemOuterTabs === "2"} onClick={() => toggleOuterTabs("2")} role="tab"> {traducirPagina("Vehiculos")} </MDBNavLink>
                            </MDBNavItem>

                            <MDBNavItem className="mr-1">
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "3"} onClick={() => toggleOuterTabs("3")} role="tab"> {traducirPagina("Referencias")}</MDBNavLink>
                            </MDBNavItem>

                            <MDBNavItem className="mr-1" style={{ display: obtenerDatosUsuarioYLicenciaActual().PERMISO_BASTIDORES ? '' : 'none' }}>
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "4"} onClick={() => toggleOuterTabs("4")} role="tab"> {traducirPagina("Bastidores")}</MDBNavLink>
                            </MDBNavItem>



                            <MDBNavItem>
                                <MDBNavLink className="headerTabReferencia" link to={obtenerHistorialUser()} active={activeItemOuterTabs === "5"} onClick={() => toggleOuterTabs("5")} role="tab"> {traducirPagina("Cod_motor")}</MDBNavLink>
                            </MDBNavItem>

                        </MDBNav>

                        <MDBTabContent className="card" activeItem={activeItemOuterTabs} style={{ height: (props.config.height - 130).toString() + "px", overflow: 'auto', backgroundColor: "#eee" }}>
                            <MDBTabPane tabId="1" role="tabpanel">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={todasLasMatriculas}

                                    renderItem={item => (
                                        <List.Item
                                            actions={[<a key="list-loadmore-edit">
                                                <button onClick={() => {
                                                    if (esMercadoLatam()) {
                                                        setIdVehiculoLatam(item.MATRICULA)
                                                        setAbrirFichaLatam(true)
                                                    } else {


                                                        let resultado = "";
                                                        let obtenerBuscadores = store.getState().buscadorCoche;
                                                        if (Object.keys(obtenerBuscadores).length > 0) {

                                                            if (obtenerBuscadores.txtBuscadorCoche === undefined) {
                                                                obtenerBuscadores["txtBuscadorCoche"] = item.MATRICULA
                                                                obtenerBuscadores["actual"] = { key: "dropcoche", value: item.MATRICULA }
                                                                resultado = obtenerBuscadores;
                                                            } else {
                                                                obtenerBuscadores.txtBuscadorCoche = item.MATRICULA;
                                                                obtenerBuscadores.actual = { key: "dropcoche", value: item.MATRICULA };
                                                                resultado = obtenerBuscadores;
                                                            }
                                                        } else {
                                                            resultado = { "txtBuscadorCoche": item.MATRICULA, "actual": { key: "dropcoche", value: item.MATRICULA } };
                                                        }

                                                        store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: resultado })
                                                        store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Matricula"), IDENTIFICADOR: "MAT" })

                                                        busquedaCochePorMatriculaOBastiidor(true);

                                                    }

                                                }}
                                                    style={{ height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: "#bbb", marginRight: '10px' }}>
                                                    <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px', marginRight: '10px' }} alt={"coche"} ></img>

                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}></span>{traducirPagina("VER_FICHA")}</button>  </a>]}
                                            style={{ cursor: 'pointer' }} >
                                            <List.Item.Meta
                                                onClick={() => {
                                                    buscarMatriculaAuto(item.MATRICULA)

                                                    /*
                                                                                                        limpiarCocheStore();
                                                                                                        store.dispatch(showItemReferencia(""));
                                                                                                        volverAlHome()
                                                                                                        if (document.getElementById("imputdropcoche") !== null) {
                                                                                                            document.getElementById("imputdropcoche").value = item.MATRICULA;
                                                                                                        }
                                                                                                        let resultado = "";
                                                                                                        let obtenerBuscadores = store.getState().buscadorCoche;
                                                                                                        if (Object.keys(obtenerBuscadores).length > 0) {
                                                                                                            if (obtenerBuscadores.txtBuscadorCoche === undefined) {
                                                                                                                obtenerBuscadores["txtBuscadorCoche"] = item.MATRICULA
                                                                                                                obtenerBuscadores["actual"] = { key: "dropcoche", value: item.MATRICULA }
                                                                                                                resultado = obtenerBuscadores;
                                                                                                            } else {
                                                                                                                obtenerBuscadores.txtBuscadorCoche = item.MATRICULA;
                                                                                                                obtenerBuscadores.actual = { key: "dropcoche", value: item.MATRICULA };
                                                                                                                resultado = obtenerBuscadores;
                                                                                                            }
                                                    
                                                                                                        } else {
                                                                                                            resultado = { "txtBuscadorCoche": item.MATRICULA, "actual": { key: "dropcoche", value: item.MATRICULA } };
                                                                                                        }
                                                                                                        store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: resultado })
                                                    
                                                                                                        store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Matricula"), IDENTIFICADOR: "MAT" })
                                                    
                                                                                                        busquedaCochePorMatriculaOBastiidor(false, item);
                                                                                                        store.dispatch(DrawerHistoricoCoche(false));
                                                    */

                                                }}
                                                avatar={<Avatar src="./imagenes/enlace.svg" />}
                                                title={<div style={{ maxWidth: ((configApp.width / 2) - 300) + "px", color: '#000', fontSize: '16px', fontWeight: '500' }}>
                                                    <span>
                                                        {item.MATRICULA}
                                                    </span>
                                                    <br></br>
                                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>{item.MARCA + verificarAtributo(item.MARCA) + item.MODELO + verificarAtributo(item.MOTOR) + item.MOTOR}</span>
                                                </div>}
                                                description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </MDBTabPane>

                            <MDBTabPane tabId="2" role="tabpanel">
                                <List
                                    className="vehiculoReferencia"
                                    itemLayout="horizontal"
                                    dataSource={todosLosVehiculos}
                                    renderItem={item => (
                                        <List.Item
                                            actions={[<a key="list-loadmore-edit">
                                                <button onClick={() => {
                                                    store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: {} })
                                                    store.dispatch({
                                                        type: "REPLACE_VEHICULO_MATRICULA",
                                                        vehiculoPorMatricula: {}
                                                    })
                                                    store.dispatch(showChildrenDrawerLevel2(false));
                                                    store.dispatch({
                                                        type: "SET_ID_MOTORIZACION_FICHA_HISTORIAL",
                                                        codMotorizacionFichaHistorial: item.ID_MOTORIZACION,
                                                    })

                                                    store.dispatch(DrawerFichaCocheDGT(true))
                                                }}
                                                    style={{ height: '40px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: "#bbb", marginRight: '10px' }}>
                                                    <img src="./imagenes/cocheGris.svg" style={{ height: '26px', color: 'rgb(102, 102, 102)', cursor: 'pointer', fontSize: '20px', marginRight: '10px' }} alt={"coche"} ></img>
                                                    <span style={{ verticalAlign: 'middle', fontSize: '16px' }}></span>{traducirPagina("VER_FICHA")}
                                                </button>

                                            </a>]}

                                            style={{ cursor: 'pointer' }} >
                                            <List.Item.Meta
                                                style={{}}
                                                onClick={() => {
                                                    volverAlHome()

                                                    store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: {} })
                                                    store.dispatch(estadoAgrupacionDeMarcas({ marcas: [] }));
                                                    store.dispatch(showChildrenDrawerLevel2(false));

                                                    //Agregar tipo vehiculo C,I
                                                    MODIFICAR_TIPO_BUSQUEDA(item.TIPO_VEHICULO)



                                                    let marca = {
                                                        ID: item.ID_MARCA,
                                                        DESCRIPCION: item.MARCA.substring(0, item.MARCA.length > 7 ? 7 : item.MARCA.length) + " ..."
                                                    }

                                                    let modelo = {
                                                        ID: item.ID_MODELO,
                                                        DESCRIPCION: item.MODELO.substring(0, item.MODELO.length > 7 ? 7 : item.MODELO.length) + " ..."
                                                    }

                                                    let objetoVehiculo = {
                                                        ID: item.ID_MOTORIZACION,
                                                        DESCRIPCION: item.MOTOR.substring(0, item.MOTOR.length > 12 ? 12 : item.MOTOR.length) + " ...",

                                                        ID_MODELO_CATALOGO: item.ID_VEHICULO_CATALOGO
                                                    };



                                                    store.dispatch(setHistoricApp(marca, "/allbrands", "brand"));
                                                    store.dispatch(setHistoricApp(modelo, "/modelos", "modelo"));
                                                    store.dispatch(setHistoricApp(objetoVehiculo, "/motorizacion", "tipomotor"));
                                                    gestionarContenedorCoche(true);
                                                    store.dispatch(updateVisualizacionFiltro(true));

                                                    setHistorialBusqueda({
                                                        MARCA: item.ID_MARCA,
                                                        MODELO: item.ID_MODELO,
                                                        MOTORIZACION: item.ID_MOTORIZACION,
                                                        NOMBRE_MARCA: item.MARCA,
                                                        NOMBRE_MODELO: item.MODELO,
                                                        NOMBRE_MOTOR: item.MOTOR
                                                    })
                                                    store.dispatch(DrawerHistoricoCoche(false));




                                                    store.dispatch(getIdTecdocMotorByIdHaynes(item.ID_MOTORIZACION, item));
                                                    store.dispatch({ type: "SET_TIPO_COMBUSTIBLE", TIPO_COMBUSTIBLE: item.TIPO_COMBUSTIBLE })
                                                    store.dispatch(showItemReferencia("tree"))

                                                }}
                                                avatar={<img style={{ width: '30px' }} src="./imagenes/cocheGris.svg" />}
                                                title={<div style={{ maxWidth: ((configApp.width / 2) - 300) + "px" }}> <span style={{ fontSize: '16px', fontWeight: '500' }}>{item.MARCA + " / " + item.MODELO + " / " + item.MOTOR}</span></div>}
                                                description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}

                                            />
                                        </List.Item>
                                    )}
                                />
                            </MDBTabPane>

                            <MDBTabPane tabId="3" role="tabpanel">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={todasLasReferencia}
                                    renderItem={item => (
                                        <List.Item style={{ cursor: 'pointer' }} onClick={() => {
                                            document.getElementById("buscadorReferencia").value = item.REFERENCIA
                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: item.REFERENCIA });
                                            buscarReferenciaYMostrarEnPantalla(item.REFERENCIA)
                                            efectoBotonRemove(item.REFERENCIA, "closeBuscadorReferencia")
                                            store.dispatch(DrawerHistoricoCoche(false));
                                        }}>
                                            <List.Item.Meta
                                                avatar={<Avatar src="./imagenes/enlace.svg" />}
                                                title={<div><span style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{item.REFERENCIA.toUpperCase()}</span></div>}
                                                description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </MDBTabPane>


                            <MDBTabPane tabId="4" role="tabpanel">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={todosLosBastidores}
                                    renderItem={item => (
                                        <List.Item style={{ cursor: 'pointer' }}>
                                            <List.Item.Meta onClick={(e) => {
                                                if (e.target.nodeName !== "svg") {
                                                    buscarBastidorAuto(item.BASTIDOR)
                                                    
                                                    /*
                                                        if (document.getElementById("imputdropcoche") !== null) {
                                                            document.getElementById("imputdropcoche").value = item.BASTIDOR;
    
                                                        }
                                                        let resultado = "";
                                                        let obtenerBuscadores = store.getState().buscadorCoche;
                                                        if (Object.keys(obtenerBuscadores).length > 0) {
                                                            if (obtenerBuscadores.txtBuscadorCoche === undefined) {
                                                                obtenerBuscadores["txtBuscadorCoche"] = item.BASTIDOR
                                                                obtenerBuscadores["actual"] = { key: "dropcoche", value: item.BASTIDOR }
                                                                resultado = obtenerBuscadores;
                                                            } else {
                                                                obtenerBuscadores.txtBuscadorCoche = item.BASTIDOR;
                                                                obtenerBuscadores.actual = { key: "dropcoche", value: item.BASTIDOR };
                                                                resultado = obtenerBuscadores;
                                                            }
    
                                                        } else {
                                                            resultado = { "txtBuscadorCoche": item.BASTIDOR, "actual": { key: "dropcoche", value: item.BASTIDOR } };
                                                        }
                                                        store.dispatch({ type: "STATE_SEARCH_COCHE", buscadorCoche: resultado })
                                                        store.dispatch({ type: "STATE_SELECCION_DROP", NAME_Drop: traducirPagina("Bastidor"), IDENTIFICADOR: "BAS" })
    
                                                        busquedaCochePorMatriculaOBastiidor(false, item);
                                                        store.dispatch(DrawerHistoricoCoche(false));
                                                    */
                                                }

                                            }}
                                                avatar={<Avatar src="./imagenes/enlace.svg" />}
                                                title={<div style={{ cursor: 'pointer' }}> <span id="textoACopiarHistorial" style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{item.BASTIDOR}<CopyOutlined onClick={(e) => { copiarAlPortapapeles("textoACopiarHistorial") }} style={{ fontSize: '16px', color: '#000', verticalAlign: 'text-bottom', cursor: 'pointer' }} /> </span>

                                                    <br></br>
                                                    <span style={{ fontSize: '16px', fontWeight: '400' }}>{item.MARCA + verificarAtributo(item.MARCA) + item.MODELO + verificarAtributo(item.MOTOR) + item.MOTOR}</span>


                                                </div>}
                                                description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </MDBTabPane>



                            <MDBTabPane tabId="5" role="tabpanel">
                                <List
                                    itemLayout="horizontal"
                                    dataSource={todasLosMotores}
                                    renderItem={item => (
                                        <List.Item style={{ cursor: 'pointer' }} onClick={() => {

                                            /*
                                            document.getElementById("buscadorReferencia").value = item.REFERENCIA
                                            store.dispatch({ type: "STATE_TXT_BUSCAR_REFERENCIA", txtBuscadorReferencia: item.REFERENCIA });
    
                                            buscarReferenciaYMostrarEnPantalla(item.REFERENCIA)
                                            efectoBotonRemove(item.REFERENCIA, "closeBuscadorReferencia")
                                            store.dispatch(DrawerHistoricoCoche(false));
                                            */
                                            ObtenerEngNoByEngCode(item.CODIGO_MOTOR);
                                            setFichaAbierta(true);
                                        }}>
                                            <List.Item.Meta
                                                avatar={<Avatar src="./imagenes/enlace.svg" />}
                                                title={<div><span style={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{item.CODIGO_MOTOR.toUpperCase()}</span></div>}
                                                description={<span style={{ color: '#999' }}>{item.FECHA_ALTA}</span>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </MDBTabPane>

                        </MDBTabContent>
                    </MDBContainer>
                </div>
            </Drawer>




            {fichaAbierta ?
                <FichaCodigoMotor
                    title={traducirPagina("Cod_motor").toUpperCase()}
                    FICHA_ABIERTA={fichaAbierta}
                    CERRAR_ABIERTA={setFichaAbierta}
                    MOTORES={motores}
                    MODIFICAR_MOTORES={setMotores}
                >
                </FichaCodigoMotor>
                :
                null
            }

            {
                abrirFichaDGT && (
                    <DetallesCocheDGT
                        title={traducirPagina("FICHA_DEL_VEHÍCULO")}
                        ABRIR_FICHA={abrirFichaDGT}
                        CERRAR_FICHA={() => { setAbrirFichaDGT(false) }}
                        REGISTROS={registrosVehDGT}


                    >

                    </DetallesCocheDGT>
                )
            }

            {
                abrirFichaLatam && (
                    <InformacionDetalladaCoche
                        abrirFicha={abrirFichaLatam}
                        cerrarFicha={(estado) => {
                            setAbrirFichaLatam(estado)
                        }}
                        titulo={traducirPagina("FICHA_DEL_VEHÍCULO")}
                        esBuscarMatricula={true}
                        matricula={idVehiculoLatam}

                    >

                    </InformacionDetalladaCoche>
                )
            }

        </>

    )
}

FichaHistoricoCoche.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    config: PropTypes.object,
    widthFicha: PropTypes.number,
    verFichaHistorico: PropTypes.bool,
    detallesReferencia: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,

    };
}


export default connect(mapStateToProps)(FichaHistoricoCoche);