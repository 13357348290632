import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBDropdown, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle } from 'mdbreact';
import 'antd/dist/antd.css';

import { Select, Drawer, Tabs, Collapse } from 'antd';
import { DrawerStockAlmacen, createRipple, DrawerMasInformacion, actulizarReferenciaSeleccionada } from "../action/actionCreators";
import {  ObtenerReferenciasByVehicleFASTEnAPI, GetVehiculosHaynesByIdTecdocEnAPI, GetTiemposReparacionByGMAndVehicleEnAPI, getStockDesglose,setHistorialDocumentacionTecnicaEnAPI } from "../action/actionCreatorsRequests";

import { MSGERROR, MSGOK, traducirPagina, obtenerDatosUsuarioYLicenciaActual, convertirMoneda, referenciaActualSelect, verificarSiEsMostrador, buscarVehiculoLocal, condicionesAbrirCesta, cestaAutomatica, generateUUID, crearLineasParaElPresupuesto, condicionesAbrirCestav2 } from "../librerias/funciones"
import {
    EyeOutlined,
    LikeOutlined,
    ShoppingOutlined,
    DownOutlined,
    DislikeOutlined
} from '@ant-design/icons';
import FichaAñadirCesta from './FichaAñadirCesta'
import store from '../store'
import IframeIntegraciones from './IframeIntegracion';

const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const FichaFast = ({ props, configApp, codmotorizacion, clienteSeleccionado }) => {
    const [hayRegistros, setHayRegistros] = React.useState(false);
    const [referenciaSeleccionada, setReferenciaSeleccionada] = React.useState({});
    const [fichaCesta, setFichaCesta] = React.useState(false);

    const [cardFast, setCardFast] = React.useState([
        {
            ID_CARD: "1",
            NOMBRE_CARD:traducirPagina("Motor_arranque").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_arranque.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",

            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,

            LISTA_REFERENCIAS: [],
            PRECIO: null,

            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "2",
            NOMBRE_CARD: traducirPagina("Alternador").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_alternador.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "3",
            NOMBRE_CARD: traducirPagina("Kit_distribucion").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_kit_distribucion.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "4",
            NOMBRE_CARD: traducirPagina("Embrague").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_embrague.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "5",
            NOMBRE_CARD: traducirPagina("Disco_trasero").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_disco_freno.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "6",
            NOMBRE_CARD:  traducirPagina("Disco_delantero").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_disco_freno.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "7",
            NOMBRE_CARD: traducirPagina("Pastilla_delantero").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_embrague.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "8",
            NOMBRE_CARD: traducirPagina("Pastilla_trasero").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_embrague.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "9",
            NOMBRE_CARD: traducirPagina("Amortiguador_delantera").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_amortiguador.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "10",
            NOMBRE_CARD: traducirPagina("Amortiguador_trasero").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_amortiguador.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        },
        {
            ID_CARD: "11",
            NOMBRE_CARD: traducirPagina("Bateria").toUpperCase(),
            IMAGEN: "./imagenes/iconos_fast/f_bateria.png",
            REFERENCIA: "",
            NOMRE_MARCA: "",
            CODIGO_MARCA: 0,
            DESCUENTO: 0,
            GRUPO_MONTAJE: 0,
            NETO: 0,
            PVP_RECOMENDADO: 0,
            REFERENCIA_PROVEEDOR: "",
            STOCK_ALMACEN: 0,
            STOCK_TOTAL: 0,
            LISTA_REFERENCIAS: [],
            PRECIO: 0,
            TIEMPO: "",
            DESCRIPCION_TIEMPO: "",
            LISTA_TIEMPOS: [],
            TAREAS_REPARACION: [],
            LOADER: 1,
            LOADER_PRESUPUESTO: 1,
        }
    ]);




    const ObtenerReferenciasByVehicleFAST = async (ID_VEHICULO) => {
        const VEHICULO_HAYNES = await GetVehiculosHaynesByIdTecdoc(ID_VEHICULO);

        const respuesta = await cardFast.map(async (registro) => {

            const registros = await ObtenerReferenciasByVehicleFASTEnAPI(ID_VEHICULO, registro.ID_CARD)
            if (registros !== undefined && registros !== null && registros.ARTICULOS !== undefined && registros.ARTICULOS !== null && registros.ARTICULOS.length > 0) {
                const primerRegistro = registros.ARTICULOS[0]

                registro.REFERENCIA = primerRegistro.REFERENCIA;
                registro.NOMRE_MARCA = primerRegistro.NOMRE_MARCA;
                registro.LISTA_REFERENCIAS = registros.ARTICULOS;
                registro.PRECIO = primerRegistro.PRECIO;

                registro.CODIGO_MARCA = primerRegistro.CODIGO_MARCA;
                registro.DESCUENTO = primerRegistro.DESCUENTO;
                registro.GRUPO_MONTAJE = primerRegistro.GRUPO_MONTAJE;
                registro.NETO = primerRegistro.NETO;
                registro.PVP_RECOMENDADO = primerRegistro.PVP_RECOMENDADO;
                registro.REFERENCIA_PROVEEDOR = primerRegistro.REFERENCIA_PROVEEDOR;
                registro.STOCK_ALMACEN = primerRegistro.STOCK_ALMACEN;
                registro.STOCK_TOTAL = primerRegistro.STOCK_TOTAL;
                registro.LOADER = 0;

            } else {
                registro.LOADER = 2;
            }
            
            setCardFast([...cardFast])

            
            if (VEHICULO_HAYNES !== null) {
                const { IdTiempoReparacion } = VEHICULO_HAYNES[0];
    
                    const RESULT_TIEMPO = await GetTiemposReparacionByGMAndVehicle(IdTiempoReparacion, registro.GRUPO_MONTAJE,true)
    
                    if (RESULT_TIEMPO !== undefined && RESULT_TIEMPO !== null && RESULT_TIEMPO.length > 0) {
                        const { Tiempo, Descripcion, TAREAS_REPARACION } = RESULT_TIEMPO[0]
    
                        RESULT_TIEMPO.map((registro) => {
                            registro.ID_TIEMPO = generateUUID()
                        })
    
                        registro.TIEMPO = Tiempo;
                        registro.DESCRIPCION_TIEMPO = Descripcion;
                        registro.TAREAS_REPARACION = TAREAS_REPARACION;
                        registro.LISTA_TIEMPOS = RESULT_TIEMPO;
                        registro.LOADER_PRESUPUESTO = 0;
    
                    }else{
                        registro.LOADER_PRESUPUESTO = 2;
                    }
                    setCardFast([...cardFast])
            }

            return registro;
        })



        
/*
        Promise.all(respuesta).then(async (registros) => {

            const VEHICULO_HAYNES = await GetVehiculosHaynesByIdTecdoc(ID_VEHICULO);
            if (VEHICULO_HAYNES !== null) {
                const { IdTiempoReparacion } = VEHICULO_HAYNES[0];

                const TIEMPO = await cardFast.map(async (registro) => {
                    const RESULT_TIEMPO = await GetTiemposReparacionByGMAndVehicle(IdTiempoReparacion, registro.GRUPO_MONTAJE)

                    if (RESULT_TIEMPO !== undefined && RESULT_TIEMPO !== null && RESULT_TIEMPO.length > 0) {
                        const { Tiempo, Descripcion, TAREAS_REPARACION } = RESULT_TIEMPO[0]

                        RESULT_TIEMPO.map((registro) => {
                            registro.ID_TIEMPO = generateUUID()
                        })

                        registro.TIEMPO = Tiempo;
                        registro.DESCRIPCION_TIEMPO = Descripcion;
                        registro.TAREAS_REPARACION = TAREAS_REPARACION;
                        registro.LISTA_TIEMPOS = RESULT_TIEMPO;
                        registro.LOADER_PRESUPUESTO = 0;

                    }else{
                        registro.LOADER_PRESUPUESTO = 2;
                    }

                    setCardFast([...cardFast])
                })
            }

        })
        */
    }

    const GetVehiculosHaynesByIdTecdoc = async (ID_VEHICULO) => {
        const registros = await GetVehiculosHaynesByIdTecdocEnAPI(ID_VEHICULO);
        let resultado = null;
        if (registros !== undefined && registros !== null && registros.length > 0) {
            resultado = registros;
        }

        return resultado;
    }


    const GetTiemposReparacionByGMAndVehicle = async (ID_VEHICULO, GRUPO_MONTAJE,RAPID) => {
        const registros = await GetTiemposReparacionByGMAndVehicleEnAPI(ID_VEHICULO, GRUPO_MONTAJE,RAPID);
        let resultado = null;
        if (registros !== undefined && registros !== null && registros.length > 0) {
            resultado = registros;
        }
        return resultado;
    }

    const setHistorialDocumentacionTecnica = async (ID_VEHICULO_TECDOC, TIPO_BUSQUEDA) => {
        const registros = await setHistorialDocumentacionTecnicaEnAPI(ID_VEHICULO_TECDOC, TIPO_BUSQUEDA);
        let resultado = null;
        if (registros !== undefined && registros !== null && registros.length > 0) {
            resultado = registros;
        }
        return resultado;
    }



    

    const abrirDesglose = (item) => {
        if (!obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE) {
            store.dispatch(DrawerStockAlmacen(true));
            referenciaActualSelect({ REFERENCIA: item.REFERENCIA, CODIGO_MARCA: item.CODIGO_MARCA, REFERENCIA_PROVEEDOR: item.REFERENCIA_PROVEEDOR, REGISTROS: item });
        }
    }


    const actualizarReferencia = (referencia) => {
        cardFast.map((tarjeta) => {
            tarjeta.LISTA_REFERENCIAS.map((registro) => {
                if (registro.REFERENCIA === referencia.REFERENCIA) {
                    tarjeta.REFERENCIA = referencia.REFERENCIA;
                    tarjeta.NOMRE_MARCA = referencia.NOMRE_MARCA;
                    tarjeta.PRECIO = referencia.PRECIO;


                    tarjeta.CODIGO_MARCA = referencia.CODIGO_MARCA;
                    tarjeta.DESCUENTO = referencia.DESCUENTO;
                    tarjeta.GRUPO_MONTAJE = referencia.GRUPO_MONTAJE;
                    tarjeta.NETO = referencia.NETO;
                    tarjeta.PVP_RECOMENDADO = referencia.PVP_RECOMENDADO;
                    tarjeta.REFERENCIA_PROVEEDOR = referencia.REFERENCIA_PROVEEDOR;
                    tarjeta.STOCK_ALMACEN = referencia.STOCK_ALMACEN;
                    tarjeta.STOCK_TOTAL = referencia.STOCK_TOTAL;

                    setCardFast([...cardFast]);
                }
            })
        })
    }


    const actualizarTiempo = (referencia) => {
        cardFast.map((tarjeta) => {
            tarjeta.LISTA_TIEMPOS.map((registro) => {
                if (registro.ID_TIEMPO === referencia.ID_TIEMPO) {

                    tarjeta.TIEMPO = referencia.Tiempo;
                    tarjeta.DESCRIPCION_TIEMPO = referencia.Descripcion;
                    tarjeta.TAREAS_REPARACION = referencia.TAREAS_REPARACION;


                    setCardFast([...cardFast]);
                }
            })
        })
    }



    const añadirALaCesta = async (registro, CANTIDAD) => {
        if (CANTIDAD === "") {
            MSGERROR(traducirPagina("ingrese_cantidad"))
        } else if (registro.STOCK_TOTAL === 0 ? false : parseInt(CANTIDAD) > registro.STOCK_TOTAL) {
            MSGERROR(`${traducirPagina("cantidad_maxima")} ${registro.STOCK_TOTAL}`)
        } else {
            registro.NOMBRE_GRUPO_MONTAJE = registro.NOMBRE_CARD;

            let esMostrador = verificarSiEsMostrador()
            if (esMostrador && clienteSeleccionado.codigo === "") {
                //setVisible(true)
                MSGERROR(traducirPagina("Msg_vender"))
            } else {
                const VEHICULO = await buscarVehiculoLocal();
                const { TIPO_STOCK, ABRIR_FICHA } = condicionesAbrirCestav2(registro, CANTIDAD);
                if (ABRIR_FICHA) {
                   
                    registro.CANTIDAD_SELECCIONADA = CANTIDAD
                    registro.VEHICULO = VEHICULO

                    setReferenciaSeleccionada(registro)
                    setFichaCesta(true)
                } else {
                    getStockDesglose(registro.REFERENCIA, registro.CODIGO_MARCA, registro.REFERENCIA_PROVEEDOR,registro.NOMRE_MARCA).then(stock => {

                        registro.DESCRIPCION_CESTA = "[ " + registro.NOMRE_MARCA.toUpperCase() + " ] - " + registro.NOMBRE_GRUPO_MONTAJE.toUpperCase();
                        cestaAutomatica(stock, TIPO_STOCK, CANTIDAD, registro, VEHICULO)
                    })

                }
            }
        }
    }




    if (!hayRegistros && props.ABRIR_FICHA) {
        setHayRegistros(true);
        //ObtenerManualesReparacion(codmotorizacion, props.REFERENCIA_SELECCIONADA.GRUPO_MONTAJE)
        ObtenerReferenciasByVehicleFAST(codmotorizacion)
        setHistorialDocumentacionTecnica(codmotorizacion,"RAPID")
    } else {
        if (!props.ABRIR_FICHA && hayRegistros) {
            setHayRegistros(false);
        }
    }

    return (
        <div>
            <Drawer
                width={1400}
                onClose={() => {
                    if (props.TIPO === "MASTER") {
                        props.CERRAR.setState({ ABRIR_FICHA_TRAKING: false })
                    } else {
                        props.CERRAR_FICHA(false)
                    }
                }}
                closable={false}
                visible={props.ABRIR_FICHA}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => {
                            createRipple(e)

                            if (props.TIPO === "MASTER") {
                                props.CERRAR.setState({ ABRIR_FICHA_FAST: false })
                            } else {
                                props.CERRAR_FICHA(false)
                            }

                        }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>

                <div className='container_fast'>

                    {cardFast.map((registro) =>
                        <div className='item_fast' style={{ backgroundImage: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url(${registro.IMAGEN})` }}>
                            <div className='item_fast_header'>
                                <h4>{registro.NOMBRE_CARD}</h4>
                            </div>
                            <div className='item_fast_body '>
                                <div className='item_fast_body_container' style={{ minHeight: '50px' }}>
                                    <div className='item_fast_body_container_item'>
                                        <div className={`referencia ${(registro.PRECIO === null ? "skeleton-box loader_container_fast" : ``)}`}>
                                            {registro.REFERENCIA}
                                        </div>
                                        <div className='marca'> {registro.NOMRE_MARCA}</div>
                                    </div>

                                    <MDBDropdown className='item_fast_body_container_item_botones'>
                                        <MDBDropdownToggle nav>
                                            <DownOutlined ></DownOutlined>
                                        </MDBDropdownToggle>

                                        <MDBDropdownMenu right style={{height:'300px',overflow:'auto'}}>
                                            {registro.LISTA_REFERENCIAS.map((registro) =>
                                                <MDBDropdownItem onClick={() => {
                                                    actualizarReferencia(registro)
                                                }}>   {   `${registro.REFERENCIA} [${registro.NOMRE_MARCA}] ${traducirPagina("Precio").toUpperCase()}: ${convertirMoneda(registro.PRECIO, "€")} `}
                                                      {<div  style={{display:'inline-block',marginLeft:'5px',fontSize:'18px', color: registro.STOCK_ALMACEN > 0 ? '#25c281' : registro.STOCK_ALMACEN === 0 && registro.STOCK_TOTAL > 0 ? '#ff8000' : 'red' }}> {registro.STOCK_ALMACEN === 0 && registro.STOCK_TOTAL === 0 ? <DislikeOutlined></DislikeOutlined> : <LikeOutlined style={{verticalAlign:'text-bottom'}}></LikeOutlined> }</div>}
                                                </MDBDropdownItem>
                                            )}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>

                                <div className="texto_resaltado"> <span>{`${traducirPagina("Precio").toUpperCase()}: `} </span>  {convertirMoneda(registro.PRECIO, "€")}    </div>

                                <div className='item_fast_body_container' style={{ minHeight: '64px' }}>
                                    <div className='item_fast_body_container_item'>
                                        <div className='texto_resaltado'> <span>{`${traducirPagina("Tiempos_de_sustitucion").toUpperCase()}: ` }  </span> {registro.TIEMPO + (registro.TIEMPO === "" ? "" : " h")}  </div>
                                        <div className='marca estilo_tiempo' style={{ fontSize: '12px' }}>{registro.DESCRIPCION_TIEMPO}</div>
                                    </div>


                                    <MDBDropdown className='item_fast_body_container_item_botones'>
                                        <MDBDropdownToggle nav>
                                            <DownOutlined ></DownOutlined>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu right>
                                            {registro.LISTA_TIEMPOS.map((tiempo) =>
                                                <MDBDropdownItem onClick={() => {
                                                    actualizarTiempo(tiempo)
                                                }}> {"[" + tiempo.Tiempo + traducirPagina("label_horas") + "]" + " " + tiempo.Descripcion}</MDBDropdownItem>
                                            )}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                                <div className={`btn_fast_presupuesto ${(registro.LOADER_PRESUPUESTO === 1  || registro.LOADER_PRESUPUESTO === 2 ? "disabled_container" : ``)}`}   onClick={() => {
                                    const data = { Tiempo: registro.TIEMPO, Descripcion: registro.DESCRIPCION_TIEMPO, TAREAS_REPARACION: registro.TAREAS_REPARACION }

                                    if(registro.DESCRIPCION_TIEMPO !== null){
                                        crearLineasParaElPresupuesto(data, "T",true);
                                    }

                                    const ARTICULO = 
                                    {
                                      CANTIDAD: 1,
                                      PRECIO:registro.PRECIO,
                                      REFERENCIA:registro.REFERENCIA,
                                      REFERENCIA_PROVEEDOR:registro.REFERENCIA_PROVEEDOR,
                                      CODIGO_MARCA_ERP:"",
                                      NOMRE_MARCA:registro.NOMRE_MARCA,
                                      CODIGO_MARCA:registro.CODIGO_MARCA,
                                      NOMBRE_GRUPO_MONTAJE:registro.NOMBRE_CARD,
                                      DESCUENTO:registro.DESCUENTO
                                      
                                    }
                                   
                                    crearLineasParaElPresupuesto(ARTICULO, "A",true);

                                    MSGOK(traducirPagina("Referencia_agregada"));


                                }}> <span>{`${traducirPagina("Añadir_a_presupuesto")}`}</span> </div>

                                {registro.LOADER || registro.LOADER === 2 ?
                                    <div className="item_fast_body_loader_mask">
                                        <div className="item_fast_body_loader_mask_image">
                                            {registro.LOADER === 2 ?
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: '#fff' }}>  <span style={{ fontSize: '18px', fontWeight: '500', color: '#fff' }}>{traducirPagina("No_resultados")}</span> </div>
                                                :
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: '#fff' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>


                            <div className='item_fast_footer'>
                                <div className={`item_fast_footer_item ${(registro.LOADER === 1  || registro.LOADER === 2 ? "disabled_container" : ``)}`} style={{ width: '80px' }} onClick={() => {
                                    añadirALaCesta(registro, 1);
                                }}>
                                    <ShoppingOutlined ></ShoppingOutlined>
                                </div>

                                <div className="item_fast_footer_barraLateral"></div>

                                <div className={`item_fast_footer_item ${(registro.LOADER === 1  ||registro.LOADER === 2 ? "disabled_container" : ``)}`} style={{ width: '150px', color: registro.STOCK_ALMACEN > 0 ? '#25c281' : registro.STOCK_ALMACEN === 0 && registro.STOCK_TOTAL > 0 ? '#ff8000' : 'red' }} onClick={() => {
                                    abrirDesglose(registro)
                                }}>
                                    {registro.STOCK_ALMACEN === 0 && registro.STOCK_TOTAL === 0 ?
                                        <DislikeOutlined></DislikeOutlined>
                                        :
                                        <LikeOutlined ></LikeOutlined>
                                    }
                                    <span style={{ fontSize: '14px', verticalAlign: 'sub' }}>
                                        {registro.STOCK_ALMACEN === 0 && registro.STOCK_TOTAL === 0 ? " " + traducirPagina("No_disponible") + " " : traducirPagina("Stock") + " " + registro.STOCK_ALMACEN + "/" + registro.STOCK_TOTAL}
                                    </span>
                                </div>

                                <div className="item_fast_footer_barraLateral"></div>

                                <div className={`item_fast_footer_item ${(registro.LOADER === 1  || registro.LOADER === 2 ? "disabled_container" : ``)}`} style={{ width: '80px' }} onClick={() => {
                                    store.dispatch(DrawerMasInformacion(true));
                                    store.dispatch(actulizarReferenciaSeleccionada({ REFERENCIA: registro.REFERENCIA, CODIGO_MARCA: registro.CODIGO_MARCA }));
                                }}>
                                    <EyeOutlined></EyeOutlined>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>

            {fichaCesta ?
                <FichaAñadirCesta
                    title={traducirPagina("Añadir_referencia_a_la_cesta").toUpperCase()}
                    ABRIR_FICHA={fichaCesta}
                    CERRAR_FICHA={setFichaCesta}
                    DATOS_REFERENCIA={referenciaSeleccionada}
                    ES_CESTA_COMPRA={false}
                >
                </FichaAñadirCesta>
                :
                null
            }

        </div>

    )
}

FichaFast.propTypes = {
    title: PropTypes.string,
    ABRIR_FICHA: PropTypes.bool,
    CERRAR_FICHA: PropTypes.func,
    CERRAR: PropTypes.object,
    REFERENCIA_SELECCIONADA: PropTypes.object
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        codmotorizacion: state.codmotorizacion,
        clienteSeleccionado: state.clienteSeleccionado,



    };
}

export default connect(mapStateToProps)(FichaFast);