import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBTabPane, MDBTabContent, MDBIcon, MDBCol, MDBCardBody, MDBCard, MDBCardText, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBInput } from 'mdbreact';
import { addDefaultSrc } from "../librerias/herramientas.js"
import 'antd/dist/antd.css';
import { Select, Card, Drawer, Modal, Button, Form, Input, Checkbox, Row, Col, Tabs, Menu, Switch, Divider, Collapse } from 'antd';
import { DrawerFichaTiemposReparacion, createRipple, DrawerFichaGMVehiculoHaynes } from "../action/actionCreators";
import { GetUsuariosEnAPI, SetUsuarioEnAPI, GetIdiomasEnAPI, GetNodosRaizTiemposReparacionEnAPI, GetSubNodosTiempoReparacioEnAPI, ObtenerTareasTiempoReparacionEnnAPI, GetVehiculosHaynesByIdTecdocEnAPI, ObtenerInfoTareasTiempoReparacionEnAPI, ModificarCestaTiempoReparacionEnAPI } from "../action/actionCreatorsRequests";
import { convertirMoneda, MSGERROR, MSGOK, traducirPagina, dynamicSortAsc, crearLineasParaElPresupuesto } from "../librerias/funciones"
import FichaGMVehiculoHaynesPro from '../components/FichaGMYVehiculoHaynesPro'

import {
    MailOutlined,
    SaveOutlined,
    EditOutlined,
    DeleteOutlined,
    FileAddOutlined,
    PlusCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined,
    EyeOutlined
} from '@ant-design/icons';
import store from '../store'

const { Option } = Select;
const { TabPane } = Tabs;
const { Meta } = Card;
const { Panel } = Collapse;
const FichaTiemposReparacion = ({ props, configApp, openDrawerFichaTiemposReparacion, codmotorizacion }) => {
    const [mode, setMode] = React.useState('inline');
    const [theme, setTheme] = React.useState('light');

    const [verDetalles, setVerDetalles] = React.useState(false);
    const [hayNodos, setHayNodods] = React.useState(false);
    const [totales, setTotales] = React.useState({ trabajos: 0, tiempoTotal: 0, piezasNecesarias: 0 });
    const [nodos, setNodos] = React.useState([]);
    const [nodoActivo, setNodoActivo] = React.useState("");
    const [tareasCesta, setTareasCesta] = React.useState([]);

    const [tareasTiempoReparacion, setTareasTiempoReparacion] = React.useState([]);
    const [tiempoReparacion, setTiempoReparacion] = React.useState("");
    const [grupoMontaje, setGrupoMontaje] = React.useState("");
    const [Vivid, setVivid] = React.useState(0);
    const [visible, setVisible] = React.useState(false);



    if (!hayNodos && openDrawerFichaTiemposReparacion) {
        setHayNodods(true);
        //setNodos([])
        GetVehiculosHaynesByIdTecdocEnAPI(codmotorizacion).then(tiempos => {
            if (tiempos !== undefined) {
                if (tiempos !== null && tiempos.length > 0) {
                    setTiempoReparacion(tiempos[0].IdTiempoReparacion)
                    setVivid(tiempos[0].Vivid)



                    GetNodosRaizTiemposReparacionEnAPI(tiempos[0].IdTiempoReparacion).then(nodos => {
                        if (nodos !== undefined) {
                            if (nodos !== null && nodos.length > 0) {
                                setNodos(nodos)

                                obtnerSubNodos(tiempos[0].IdTiempoReparacion, nodos[0].Id);
                            } else {
                                obtnerSubNodos(null)
                                setNodos(null)
                            }
                        }
                    })
                }
            }

        })
    } else {
        if (!openDrawerFichaTiemposReparacion && hayNodos) {
            setVivid(0)
            setTiempoReparacion("")
            setNodoActivo("")
            setVisible(false)
            setGrupoMontaje("")
            setTareasCesta([])
            setVerDetalles(false)
            setHayNodods(false);
            setNodos([])
            setTotales({ trabajos: 0, tiempoTotal: 0, piezasNecesarias: 0 })
            setTareasTiempoReparacion([])
        }
    }

    const obtnerSubNodos = (tiempo, nodo) => {
        setNodoActivo(nodo);
        let buscarSubNodo = verificarSiDebeBuscarUnSubnodo(nodo);
        if (buscarSubNodo) {
            GetSubNodosTiempoReparacioEnAPI(tiempo, nodo).then(nodos => {
                if (nodos !== undefined) {
                    if (nodos !== null && nodos.length > 0) {
                        nodos = nodos.map(obj => ({ ...obj, articuloSeleccionado: false, tareas: [], Nodo: nodo }))
                        let concatenarEstadoLocal = tareasTiempoReparacion.concat(nodos);

                        setTareasTiempoReparacion(concatenarEstadoLocal)
                    } else {
                        setTareasTiempoReparacion(null)
                    }
                }
            })
        }
    }


    const verificarSiDebeBuscarUnSubnodo = (nodoId) => {
        let buscarNodo = true;
        let existeElSubnodo = tareasTiempoReparacion.find(subodo => subodo.Nodo === nodoId);
        if (existeElSubnodo === undefined) {
            buscarNodo = true;
        } else {
            buscarNodo = false;
        }

        return buscarNodo;
    }



    const actualizarItemSeleccionado = (nodo, padre) => {
        let listaTemporal = [];
        let resultPadre = tareasTiempoReparacion.find(registro => registro.Id === padre);

        resultPadre.tareas.map((tarea, index) => {
            if (tarea.Id === nodo.Id) {
                if (tarea.Nivel === 1) {
                    tarea.Seleccionado = tarea.Seleccionado ? false : true;
                    tarea.Valor = tarea.ValorOrigen;
                    tarea.Descripcion2 = "";

                    if (nodo.Nivel === 1) {
                        for (let i = (index + 1); i < resultPadre.tareas.length; i++) {
                            if (resultPadre.tareas[i].Nivel === 2) {
                                resultPadre.tareas[i].visible = nodo.Seleccionado;

                            } else {
                                break;
                            }
                        }
                    }


                } else {
                    tarea.Enabled = tarea.Enabled ? false : true;
                }
            }
        });

        listaTemporal = [...tareasTiempoReparacion];

        setTareasTiempoReparacion(listaTemporal)
        obtenerCestaDeLaAPI(tiempoReparacion, Vivid, listaTemporal, nodo)

    }

    const buscarTareaActivadaPorId = (idTarea, padre) => {
        let tarea = false;
        let resultPadre = tareasTiempoReparacion.find(registro => registro.Id === padre);
        /*
        let filtro = resultPadre.tareas.filter(tarea => (tarea.Nivel === 1 && tarea.Id === idTarea))
        if(filtro.length > 0){
            tarea = filtro.Seleccionado
        }
        */
        for (let i = 0; i < resultPadre.tareas.length; i++) {
            if (resultPadre.tareas[i].Nivel === 1) {
                if (resultPadre.tareas[i].Id === idTarea) {
                    tarea = resultPadre.tareas[i].Seleccionado
                    break;
                }
            }
        }
        return tarea;
    }



    const ObtmnerHijosDeLasTareas = (tiempo, nodo, padre) => {
        let listaTemporal = [];
        let unirTareas = []


        //si es falso ir a buscar tareas
        if (nodo.BuscarTareas && !nodo.Seleccionado) {
            actualizarItemSeleccionado(nodo, padre)
            nodo.Seleccionado = true;

            ObtenerInfoTareasTiempoReparacionEnAPI(tiempo, nodo.Id, nodo.TieneInfo).then(tareas => {
                if (tareas !== null) {
                    nodo.BuscarTareas = false;
                    nodo.Enabled = false;

                    if (tareas !== undefined) {
                        nodo.TieneSubNodos = true;
                        let TareasPosteriores = tareas.TareasPosteriores === null ? [] : tareas.TareasPosteriores
                        let TareasIncluidas = tareas.TareasIncluidas === null ? [] : tareas.TareasIncluidas
                        TareasPosteriores = TareasPosteriores.map(obj => ({ ...obj, Enabled: false, Seleccionado: false, Nivel: 2, Orden: 0, BuscarTareas: false, TieneSubNodos: false, TipoTrabajo: "", visible: true, ValorOrigen: obj.Valor, Descripcion2: "" }))
                        TareasIncluidas = TareasIncluidas.map(obj => ({ ...obj, Enabled: false, Seleccionado: false, Nivel: 2, Orden: 0, BuscarTareas: false, TieneSubNodos: false, TipoTrabajo: "", visible: true, ValorOrigen: obj.Valor, Descripcion2: "" }))

                        unirTareas = TareasIncluidas.concat(TareasPosteriores);

                        let ordenContador = nodo.Orden + 1;
                        unirTareas.map(tarea => {
                            tarea.Orden = ordenContador;
                            ordenContador = ordenContador + 1;
                            tarea.Enabled = tarea.Direccion === "F" ? buscarTareaActivadaPorId(tarea.Numero, padre) : false

                        })

                        let resultPadre = tareasTiempoReparacion.find(registro => registro.Id === padre);
                        unirTareas.map((registro, index) => {
                            resultPadre.tareas.push({
                                Seleccionado: false,
                                Articulos: registro.Articulos,
                                Descripcion: registro.Descripcion,
                                Id: registro.Numero,
                                Numero: registro.Numero,
                                Orden: registro.Orden,
                                TieneInfo: registro.TieneInfo,
                                TieneSubNodos: registro.TieneSubNodos,
                                TipoTrabajo: registro.TipoTrabajo,
                                Valor: registro.Valor,
                                Direccion: registro.Direccion,
                                Enabled: registro.Enabled,
                                visible: registro.visible,
                                Nivel: registro.Nivel,
                                ValorOrigen: registro.ValorOrigen,
                                Descripcion2: registro.Descripcion2
                            })
                        }
                        )
                        listaTemporal = [...tareasTiempoReparacion];
                        setTareasTiempoReparacion(listaTemporal)
                    }
                }
                //calcularTotales(listaTemporal, padre)
                obtenerCestaDeLaAPI(tiempoReparacion, Vivid, listaTemporal, nodo)

            })
        } else {
            actualizarItemSeleccionado(nodo, padre)
        }


    }


    const añadirItemDentroDeArticulo = (articulos) => {
        let modificarArticulo = [];
        articulos.map((articulo) => {
            let nuevoArticulo = { Id: articulo.Id, Descripcion: articulo.Descripcion, Obligatorio: articulo.Obligatorio, Precio: 0, ReferenciaSeleccionado: {}, Referencia: "", Marca: "" }
            modificarArticulo.push(nuevoArticulo);
        })

        return modificarArticulo;
    }

    const ObtenerTareasTiempoReparacion = (tiempo, nodo) => {
        let listaTemporal = [];
        if (nodo !== undefined) {
            let buscarNodoPadre = tareasTiempoReparacion.find((registro) => registro.Id === nodo);

            if (buscarNodoPadre.tareas.length === 0) {
                ObtenerTareasTiempoReparacionEnnAPI(tiempo, nodo, false).then(tareas => {
                    if (tareas !== undefined) {
                        if (tareas !== null && tareas.length > 0) {
                            tareas = tareas.map((obj, index) => (
                                {
                                    ...obj, Articulos: añadirItemDentroDeArticulo(obj.Articulos), Seleccionado: false, Nivel: 1, Orden: ((index + 1) * 100), BuscarTareas: true, Enabled: false, visible: true, ValorOrigen: obj.Valor, Descripcion2: ""
                                }
                            ))
                            tareasTiempoReparacion.map((registro) => {
                                if (registro.Id === nodo) {
                                    registro.tareas = tareas;
                                }
                            });

                            listaTemporal = [...tareasTiempoReparacion];

                            setTareasTiempoReparacion(listaTemporal)
                        } else {
                            setTareasTiempoReparacion(null)
                        }
                    }
                })
            }
        }
    }


    const obtenerCestaDeLaAPI = (tiempoReparacion, Vivid, tareas, nodo) => {
        let listaIds = [];
        tareas.map((registro) => {
            registro.tareas.map((tarea, index) => {
                if (tarea.Nivel === 1 && tarea.Seleccionado) {
                    listaIds.push(tarea.Id)

                }
            })
        })

        if (listaIds.length > 0) {
            ModificarCestaTiempoReparacionEnAPI(tiempoReparacion, Vivid, listaIds).then(cesta => {
                if (cesta !== undefined) {

                    if (cesta !== null) {
                        totales.trabajos = cesta.Tareas.length;
                        totales.tiempoTotal = cesta.TiempoTotal;
                        totales.piezasNecesarias = cesta.PiezasNecesarias;
                        setTareasCesta(cesta.Tareas);
                        setTotales({ ...totales });
                    }


                    if (nodo !== undefined) {
                        if (cesta !== null) {
                            let nuevoCantidad = modificarPrecioCestaPorId(nodo.Id, nodo.Valor, cesta.Tareas);
                            tareasTiempoReparacion.map((registro, index) => {
                                if (registro.tareas.length > 0) {
                                    registro.tareas.map(tarea => {
                                        if (nodo.Id === tarea.Id && nodo.Nivel === 1) {
                                            if (tarea !== undefined) {
                                                if (tarea.Valor !== nuevoCantidad) {
                                                    tarea.Valor = nuevoCantidad;
                                                    tarea.Descripcion2 = traducirPagina("El_tiempo_predeterminado") + " " + tarea.ValorOrigen + " " + traducirPagina("ha_cambiado")
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                            setTareasTiempoReparacion([...tareasTiempoReparacion])
                        }
                    }

                }
            })
        } else {
            setVerDetalles(false)
            setTotales({ trabajos: 0, tiempoTotal: 0, piezasNecesarias: 0 })
        }

    }

    const modificarPrecioCestaPorId = (idTarea, precio, tareasCesta) => {
        let precioTarea = precio
        if (tareasCesta !== null) {
            if (tareasCesta.length > 0) {
                let buscarTarea = tareasCesta.find(tarea => tarea.Id === idTarea)
                if (buscarTarea !== undefined) {
                    precioTarea = buscarTarea.TiempoCalculado
                }

            }
        }
        return precioTarea;
    }


    const obtenerTrabajos = () => {
        let trabajos = [];
        if (tareasTiempoReparacion !== null) {

            tareasTiempoReparacion.map((registro, index) => {
                registro.tareas.map((tarea, indice) => {
                    let nuevoCantidad = modificarPrecioCestaPorId(tarea.Id, tarea.Valor, tareasCesta);
                    if (tarea.Valor !== nuevoCantidad) {
                        tarea.Valor = nuevoCantidad;
                        tarea.Descripcion2 = traducirPagina("El_tiempo_predeterminado") + " " + tarea.ValorOrigen + " " + traducirPagina("ha_cambiado")
                    }

                    tarea.NodoPadre = registro.Id;
                    trabajos.push(tarea);
                })
            })

        }
        return trabajos;
    }

    const obtenerArticulos = () => {
        let trabajos = [];
        if (tareasTiempoReparacion !== null) {

            tareasTiempoReparacion.map((registro, index) => {
                registro.tareas.map((tarea, indice) => {
                    if (tarea.Nivel === 1 && tarea.Seleccionado) {
                        tarea.Articulos.map((articulo, ind) => {
                            trabajos.push(articulo);
                        })
                    }

                })
            })
        }
        return trabajos;
    }

    const comprobarSiHayPiezasSinReferencia = () => {
        let articulos = obtenerArticulos();
        let continuar = false;
        let verficarSiHayPrecio = articulos.filter(articulo => Object.keys(articulo.ReferenciaSeleccionado).length === 0)
        if (verficarSiHayPrecio.length === 0) {
            continuar = true;
        }
        return continuar
    }


    const añadirApresupuesto = () => {
        let manoObra = obtenerTrabajos();
        let articulos = obtenerArticulos();


        manoObra.map((tarea, indice) => {
            if (tarea.Nivel === 1 && tarea.Seleccionado) {
                let Obra = {
                    Tiempo: tarea.Valor,
                    Descripcion: tarea.Descripcion,
                    TAREAS_REPARACION: manoObra.filter((tar) => tar.Nivel === 2 && tar.NodoPadre === tarea.NodoPadre)
                };
                crearLineasParaElPresupuesto(Obra, "T", true);
            }
        })


        articulos.map((articulo, indice) => {
            let itemArticulo = {};
            if (Object.keys(articulo.ReferenciaSeleccionado).length > 0) {
                itemArticulo = {
                    PRECIO: articulo.ReferenciaSeleccionado.PRECIO,
                    REFERENCIA_PROVEEDOR: articulo.ReferenciaSeleccionado.REFERENCIA_PROVEEDOR,
                    REFERENCIA: articulo.ReferenciaSeleccionado.REFERENCIA,
                    CODIGO_MARCA_ERP: articulo.ReferenciaSeleccionado.CODIGO_MARCA_ERP,
                    NOMRE_MARCA: articulo.ReferenciaSeleccionado.NOMRE_MARCA,
                    CODIGO_MARCA: articulo.ReferenciaSeleccionado.CODIGO_MARCA,
                    NOMBRE_GRUPO_MONTAJE: articulo.ReferenciaSeleccionado.NOMBRE_GRUPO_MONTAJE,
                    DESCUENTO: articulo.ReferenciaSeleccionado.DESCUENTO,
                }
            } else {
                itemArticulo = {
                    PRECIO: 0,
                    REFERENCIA_PROVEEDOR: "",
                    REFERENCIA: "",
                    CODIGO_MARCA_ERP: "",
                    NOMRE_MARCA: "",
                    CODIGO_MARCA: 0,
                    NOMBRE_GRUPO_MONTAJE: articulo.Descripcion,
                    DESCUENTO: 0
                }

            }
            crearLineasParaElPresupuesto(itemArticulo, "A", true);
        })

        MSGOK(traducirPagina("Referencia_agregada"))
    }


    return (
        <div>
            <Drawer
                width={1200}
                onClose={() => {
                    store.dispatch(DrawerFichaTiemposReparacion(false));
                    store.dispatch(DrawerFichaGMVehiculoHaynes(false));
                }}
                visible={openDrawerFichaTiemposReparacion}
                placement={"right"}
                bodyStyle={{ padding: '0px' }}
                style={{ color: '#fff' }}>
                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerFichaTiemposReparacion(false)); store.dispatch(DrawerFichaGMVehiculoHaynes(false)); createRipple(e) }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ textAlign: 'left', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>

                    <div style={{ flexGrow: '1', textAlign: 'end', color: '#fff', verticalAlign: 'middle', paddingRight: '10px', fontSize: '16px' }}>
                        <button className={`col-md-12 ${!totales.trabajos > 0  ? 'colorBloqueoBotoneraFacturacion':'btnColor'}`} disabled={totales.trabajos > 0 ? false : true}
                            style={{
                                height: '40px', width: '300px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'text-top',
                                color: totales.trabajos ? '#fff' : '#999',
                                /*backgroundColor: totales.trabajos > 0 ? '#4285f4' : '#f7f7f7',*/
                                marginBottom: '5px', marginRight: '5px'
                            }} onClick={(e) => {
                                let verificar = comprobarSiHayPiezasSinReferencia();
                                if (verificar) {
                                    añadirApresupuesto();
                                } else {
                                    setVisible(true);
                                }
                            }}>
                            <FileAddOutlined style={{ fontSize: '20px' }} />
                            <span style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '10px' }}>{traducirPagina("Añadir_presupuesto").toUpperCase()}</span>
                        </button>
                    </div>


                </div>



                <div style={{ height: (configApp.height - 150).toString() + "px", }} className="ml-2 mr-2 mt-2" >
                    <Card
                        type="inner"
                        bordered={false}
                        style={{ height: (configApp.height - 85).toString() + "px", overflow: 'auto' }}
                    >
                        <div>
                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', marginTop: '10px' }}>
                                <Col md={7}>
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{traducirPagina("Total_tiempos_reparacion")} </span>
                                </Col>

                                <Col md={3}>
                                    <span>{traducirPagina("Trabajos")} </span>
                                    <br />
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{totales.trabajos} </span>
                                </Col>

                                <Col md={4}>
                                    <span>{traducirPagina("Tiempo_total_H")} </span>
                                    <br />
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{convertirMoneda(totales.tiempoTotal)} </span>
                                </Col>

                                <Col md={5}>
                                    <span>{traducirPagina("Piezas_necesarias")} </span>
                                    <br />
                                    <span style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold'
                                    }}>{totales.piezasNecesarias} </span>
                                </Col>

                                {totales.trabajos > 0 ?
                                    <Col md={3} style={verDetalles ? { backgroundColor: '#f6f6f6', borderTop: '1px solid rgb(219, 219, 219)', borderLeft: '1px solid rgb(219, 219, 219)', borderRight: '1px solid rgb(219, 219, 219)', paddingLeft: '10px' } : { paddingLeft: '10px' }}>
                                        <span style={{ cursor: 'pointer' }} onClick={(e) => { setVerDetalles(verDetalles ? false : true) }}><EyeOutlined style={{ fontSize: '20px', verticalAlign: 'text-top' }} /> {traducirPagina("Ver_detalles")} </span>
                                    </Col>

                                    : null
                                }
                            </Row>


                            <div className={verDetalles ? "repair-times-detail active" : "repair-times-detail"} >
                                {verDetalles ?
                                    <div>
                                        {obtenerTrabajos().map((tarea, indice) =>
                                            <div>
                                                {indice === 0 ?
                                                    <h4 style={{ margin: '0px 20px 10px 20px' }}>{traducirPagina("Trabajos")}</h4>
                                                    : null
                                                }

                                                {tarea.Nivel === 1 && tarea.Seleccionado ?
                                                    tarea.visible ?
                                                        <li key={indice} className={"mt-1 ml-3 mr-3 mb-1"} style={{ listStyle: 'none' }}>
                                                            <div className={"site-collapse-custom-tareas"}>
                                                                <Row gutter={[8, 8]}>
                                                                    <Col md={19}>
                                                                        {tarea.Direccion === undefined ?
                                                                            <div>{tarea.Numero + " - " + tarea.Descripcion} </div>
                                                                            :
                                                                            <Row gutter={[8, 8]}>
                                                                                <Col md={4}>
                                                                                    {tarea.Direccion === "U" ?
                                                                                        <span className="included">Incluido</span>
                                                                                        :
                                                                                        <span className="followup">Secuencial</span>
                                                                                    }
                                                                                </Col>
                                                                                <Col md={20}>
                                                                                    <div>
                                                                                        {tarea.Direccion === "U" ?
                                                                                            tarea.Descripcion
                                                                                            :
                                                                                            tarea.Numero + " - " + tarea.Descripcion
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                    <Col md={4} style={{ textAlign: 'right' }}>
                                                                        {tarea.Direccion === undefined ?
                                                                            <div>{convertirMoneda(tarea.Valor, traducirPagina("label_horas"))}</div>
                                                                            :
                                                                            tarea.Direccion === "U" ?
                                                                                <div>{ }</div>
                                                                                :
                                                                                <div><Checkbox disabled={tarea.Enabled} checked={tarea.Enabled} onChange={(e) => { actualizarItemSeleccionado(tarea, tarea.NodoPadre) }} style={{ marginRight: '10px' }} /> {convertirMoneda(tarea.Valor, traducirPagina("Horas").toLowerCase())}</div>
                                                                        }
                                                                    </Col>
                                                                    <Col md={1} style={{ textAlign: 'right' }}>
                                                                        {tarea.Direccion === undefined ?
                                                                            <div style={{ cursor: 'pointer' }} onClick={(e) => { ObtmnerHijosDeLasTareas(tiempoReparacion, tarea, tarea.NodoPadre); /*ObtenerInfoTareasTiempoReparacion(tiempoReparacion, tarea);*/ }}> {tarea.Seleccionado ? <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '20px', color: 'green' }} />} </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </li>
                                                        : null
                                                    : null
                                                }
                                            </div>
                                        )}
                                        
                                        {obtenerArticulos().map((articulo, ind) =>
                                            <div>
                                                {ind === 0 ?
                                                    <div>
                                                        <h4 style={{ margin: '0px 20px 10px 20px' }}>{traducirPagina("Piezas_necesarias")}</h4>

                                                    </div>
                                                    : null}


                                                <li key={ind} className={"mt-1 ml-3 mr-3 mb-1"} style={{ listStyle: 'none' }}>
                                                    <div className={"site-collapse-custom-tareas"}>
                                                        <Row gutter={[8, 8]}>
                                                            <Col md={14}>

                                                                <div>{articulo.Referencia === "" ? articulo.Descripcion : articulo.Descripcion + " (" + articulo.Referencia + " - " + articulo.Marca + ")"} </div>

                                                            </Col>
                                                            <Col md={2} style={{ textAlign: 'center' }}>
                                                                <div>{convertirMoneda(articulo.Precio, "€")}</div>

                                                            </Col>
                                                            <Col md={2} style={{ textAlign: 'center' }}>
                                                                <div>{convertirMoneda(1)}</div>
                                                            </Col>
                                                            <Col md={4} style={{ textAlign: 'center' }}>
                                                                <div style={{ cursor: 'pointer' }} onClick={(e) => { setGrupoMontaje(articulo.Id); store.dispatch(DrawerFichaGMVehiculoHaynes(true)); }}> {<span style={{ verticalAlign: 'middle', fontSize: '16px' }}> <SearchOutlined style={{ fontSize: '20px' }} />{traducirPagina("Buscar_referencia")}</span>} </div>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </li>

                                            </div>
                                        )}

                                    </div>

                                    : null}
                            </div>




                            <Row gutter={[8, 8]} style={{ fontSize: '16px', fontWeight: '500', color: '#000', marginTop: '10px' }}>
                                <Col md={6}>
                                    {nodos === null ?
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_nodos")}</h4>
                                        </div>
                                        :
                                        nodos.length > 0 ?
                                            <Menu
                                                style={{ width: 256 }}
                                                defaultSelectedKeys={['1']}
                                                defaultOpenKeys={['sub1']}
                                                mode={mode}
                                                theme={theme}
                                            >
                                                {nodos.map((nodo, i) =>
                                                    <Menu.Item key={(i + 1)} onClick={(e) => { obtnerSubNodos(tiempoReparacion, nodo.Id) }}
                                                        style={{ marginTop: '0px', marginBottom: '0px', height: '28px', lineHeight: '28px' }}>
                                                        {nodo.Descripcion}
                                                    </Menu.Item>
                                                )}
                                            </Menu>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '70vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                    }

                                </Col>

                                <Col md={18} className="conatinerTiempos">
                                    {nodos === null ?
                                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <h4>{traducirPagina("No_resultados")}</h4>
                                        </div>
                                        :
                                        tareasTiempoReparacion.length > 0 ?
                                            <Collapse accordion onChange={(e, isActive) => { ObtenerTareasTiempoReparacion(tiempoReparacion, e) }}
                                                className="site-collapse-custom-collapse"
                                                bordered={false}
                                            >

                                                {tareasTiempoReparacion.map((subnodo, indice) =>
                                                    subnodo.Nodo === nodoActivo ?
                                                        <Panel
                                                            className="site-collapse-custom-panel"
                                                            header={subnodo.Descripcion}
                                                            key={subnodo.Id}
                                                        >
                                                            {tareasTiempoReparacion === null ?
                                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <h3>{traducirPagina("No_tareas")}</h3> </div>
                                                                :
                                                                subnodo.tareas !== undefined ?
                                                                    subnodo.tareas.length === 0 ?
                                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '20vh' }}> <div color=" mdb-color darken-3" className="spinner-border  fast" role="status" disabled /> </div>
                                                                        :
                                                                        <ul style={{ paddingLeft: '8px' }}>
                                                                            {subnodo.tareas.sort(dynamicSortAsc("Orden")).map((tarea, indice) =>
                                                                                tarea.visible ?
                                                                                    <li key={indice} className={"mb-2"} style={{ listStyle: 'none' }}>
                                                                                        <div className={"site-collapse-custom-tareas"}>
                                                                                            <Row gutter={[8, 8]}>
                                                                                                <Col md={19}>
                                                                                                    {tarea.Direccion === undefined ?
                                                                                                        <div>
                                                                                                            {tarea.Numero + " - " + tarea.Descripcion}
                                                                                                            {tarea.Descripcion2 !== "" ?
                                                                                                                <div>
                                                                                                                    <span style={{ color: 'red' }}>{tarea.Descripcion2}</span>
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                                            }

                                                                                                        </div>
                                                                                                        :
                                                                                                        <Row gutter={[8, 8]}>
                                                                                                            <Col md={4}>
                                                                                                                {tarea.Direccion === "U" ?
                                                                                                                    <span className="included">Incluido</span>
                                                                                                                    :
                                                                                                                    <span className="followup">Secuencial</span>
                                                                                                                }
                                                                                                            </Col>
                                                                                                            <Col md={20}>
                                                                                                                <div>
                                                                                                                    {tarea.Direccion === "U" ?
                                                                                                                        tarea.Descripcion
                                                                                                                        :
                                                                                                                        tarea.Numero + " - " + tarea.Descripcion

                                                                                                                    }

                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    }

                                                                                                </Col>

                                                                                                <Col md={4} style={{ textAlign: 'right' }}>
                                                                                                    {tarea.Direccion === undefined ?
                                                                                                        <div>{convertirMoneda(tarea.Valor, traducirPagina("Horas").toLowerCase())}</div>
                                                                                                        :
                                                                                                        tarea.Direccion === "U" ?
                                                                                                            <div>{ }</div>
                                                                                                            :
                                                                                                            <div><Checkbox disabled={tarea.Enabled} checked={tarea.Enabled} onChange={(e) => { actualizarItemSeleccionado(tarea, subnodo.Id) }} style={{ marginRight: '10px' }} /> {convertirMoneda(tarea.Valor,traducirPagina("Horas").toLowerCase())}</div>
                                                                                                    }
                                                                                                </Col>
                                                                                                <Col md={1} style={{ textAlign: 'right' }}>
                                                                                                    {tarea.Direccion === undefined ?
                                                                                                        <div style={{ cursor: 'pointer' }} onClick={(e) => { ObtmnerHijosDeLasTareas(tiempoReparacion, tarea, subnodo.Id); /*ObtenerInfoTareasTiempoReparacion(tiempoReparacion, tarea);*/ }}> {tarea.Seleccionado ? <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} /> : <PlusCircleOutlined style={{ fontSize: '20px', color: 'green' }} />} </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </li>
                                                                                    : null
                                                                            )}
                                                                        </ul>

                                                                    : null
                                                            }
                                                        </Panel>

                                                        : null
                                                )
                                                }
                                            </Collapse>
                                            : null
                                    }

                                </Col>
                            </Row>
                        </div>

                    </Card>
                </div>
            </Drawer>

            {openDrawerFichaTiemposReparacion ?
                <FichaGMVehiculoHaynesPro title={traducirPagina("Buscar_referencias").toUpperCase()} vehiculoId={codmotorizacion.toString()} grupoMontaje={grupoMontaje.toString()} modificarArticulo={setTareasTiempoReparacion} listaTiempoReparacion={tareasTiempoReparacion} componentePadre={"TIEMPOS"}></FichaGMVehiculoHaynesPro>
                : null
            }



            <Modal
                visible={visible}
                width={600}
                centered
                title={traducirPagina("Tiene_articulos_ sinreferenciar")}
                afterClose={() => { }}
                onCancel={() => setVisible(false)}
                style={{ zIndex: '9999' }}
                footer={[
                    <Button key="back" onClick={() => { setVisible(false) }}>
                        {traducirPagina("NO")}
                    </Button>,
                    <Button className="btnColor" key="submit" type="primary" onClick={() => {
                        añadirApresupuesto();
                        setVisible(false)
                    }}> {traducirPagina("SI")}</Button>
                ]}

            >
                <p>{traducirPagina("msgManPre_material_no_referenciado_01")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_02")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_03")}</p>
                <p>{traducirPagina("msgManPre_material_no_referenciado_04")}</p>
            </Modal>




        </div>
    )
}

FichaTiemposReparacion.propTypes = {
    config: PropTypes.object,
    detallesReferencia: PropTypes.object,
    masInformacion: PropTypes.object,
    fichaAbierta: PropTypes.bool,
    activeItemOuterTabs: PropTypes.string,
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerFichaTiemposReparacion: state.estadoDrawer.openDrawerFichaTiemposReparacion,
        codmotorizacion: state.codmotorizacion

    };
}

export default connect(mapStateToProps)(FichaTiemposReparacion);