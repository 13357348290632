import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createRipple, DrawerCarroCompra } from "../action/actionCreators"
import { pad, buscarDetallesReferencia, getStockDesglose, setCestaLineasEnAPI, ObtenerArticuloERPEnAPI } from "../action/actionCreatorsRequests"
import { addDefaultSrc } from "../librerias/herramientas.js"
import { traducirPagina, MSGOK, MSGERROR, calcularNeto, convertirMoneda, obtenerDatosUsuarioYLicenciaActual, redondeo, etiquetaSinDisponibilidad, etiquetaBtnSolicitar } from '../librerias/funciones'
import { MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBInput } from 'mdbreact';
import {
    MinusOutlined,
    PlusOutlined,
    LikeOutlined,
    DislikeOutlined,
} from '@ant-design/icons';
import { Card, Drawer } from 'antd';
import store from "../store";
import BtnSolicitar from './utils/BtnSolicitar.jsx';
import { ImagenArticulo } from './ImagenArticulo.jsx';



const CarroCompra = ({ props, configApp, openDrawerCarroCompra, DIRECTORIO_DOCUMENTO }) => {
    const [count, setCount] = React.useState(1);
    const [maximo, setMaximo] = React.useState(0);
    const [esperandoDesglose, setesperandoDesglose] = React.useState(true);
    const [esperandoClickStock, setesperandoClickStock] = React.useState(true);
    const [petionAPI, setPetionAPI] = React.useState(true);
    const [detalles, setDetalles] = React.useState({});
    const [datosReferencia, setDatosReferencia] = React.useState({});
    const [desglose, setDesglose] = React.useState([]);
    const [PVP, setPVP] = React.useState(0);
    const [NETO, setNETO] = React.useState(0);
    const [stockSeleccionado, setStockSeleccionado] = React.useState(0);
    const [idAlmacenSeleccionado, setIdAlmacenSeleccionado] = React.useState("");
    const [descripcionAlmacenSeleccionado, setDescripcionAlmacenSeleccionado] = React.useState("");

    


    if (Object.keys(datosReferencia).length === 0) {

        if (props.referencia !== undefined && props.marca !== undefined && props.referenciaProveedor !== undefined && openDrawerCarroCompra && petionAPI) {
            //Se ejecuta una sola vez la llamada a la API
            setPetionAPI(false)
            setesperandoDesglose(true);
            setDesglose([]);

            let obtenerMarca = typeof props.marca === 'string' ? props.marca !== "" ? parseInt(props.marca) : 0 : props.marca;
            let ObjetoArticuloERP = {
                CODIGO_MARCA: props.marca,
                CODIGO_MARCA_ERP: "",
                DESCUENTO_1: 0,
                DESCUENTO_2: 0,
                DESCUENTO_3: 0,
                EAN: "",
                GRUPO_MONTAJE: 0,
                IMAGEN_MARCA: "",
                LADO_MONTAJE: "",
                MARCA_PATROCINADA: false,
                NOMBRE_GRUPO_MONTAJE: "",
                NOMRE_MARCA: "",
                PRECIO: 0,
                DESCUENTO: 0,
                NETO: 0,
                REFERENCIA: props.referencia,
                REFERENCIA_PROVEEDOR: "",
                STOCK_ALMACEN: 0,
                STOCK_TOTAL: 0
            }

            if (props.referenciaProveedor !== "") {
                ObtenerArticuloERPEnAPI(false, props.referenciaProveedor, "").then(registros => {

                    if (registros !== null && registros !== undefined && Object.keys(registros).length > 0) {
                        let referencia = registros[0];

                        if (referencia !== undefined) {
                            ObjetoArticuloERP.CODIGO_MARCA = referencia.Codigo.MarcaTecdoc
                            ObjetoArticuloERP.CODIGO_MARCA_ERP = referencia.Codigo.MarcaTecdoc
                            ObjetoArticuloERP.NOMBRE_GRUPO_MONTAJE = referencia.Codigo.DescripcionERP
                            ObjetoArticuloERP.NOMRE_MARCA = referencia.Codigo.MarcaERPDescripcion
                            ObjetoArticuloERP.PRECIO = referencia.precio
                            ObjetoArticuloERP.DESCUENTO = referencia.descuento
                            ObjetoArticuloERP.NETO = referencia.precioNeto
                            ObjetoArticuloERP.REFERENCIA = referencia.Codigo.ReferenciaTecdoc
                            ObjetoArticuloERP.REFERENCIA_PROVEEDOR = referencia.Codigo.CodigoERP
                            ObjetoArticuloERP.STOCK_ALMACEN = 0
                            ObjetoArticuloERP.STOCK_TOTAL = 39

                            setDatosReferencia(ObjetoArticuloERP);
                            setPVP(ObjetoArticuloERP.PRECIO)
                            setNETO(ObjetoArticuloERP.NETO)
                            if (props.cantidad !== undefined) {
                                let PVP = ObjetoArticuloERP.PRECIO * props.cantidad
                                let calcularDesc = calcularNeto(PVP, ObjetoArticuloERP.DESCUENTO)
                                let Neto = PVP - calcularDesc;
                                //setPVP(PVP)
                                setNETO(Neto)
                            }
                        }

                    } else {
                        if (props.REGISTROS !== undefined && props.REGISTROS !== null && Object.keys(props.REGISTROS).length > 0) {
                            ObjetoArticuloERP.CODIGO_MARCA = props.REGISTROS.CODIGO_MARCA
                            ObjetoArticuloERP.CODIGO_MARCA_ERP = props.REGISTROS.CODIGO_MARCA_ERP
                            ObjetoArticuloERP.DESCUENTO = props.REGISTROS.DESCUENTO
                            ObjetoArticuloERP.EAN = props.REGISTROS.EAN
                            ObjetoArticuloERP.NOMBRE_GRUPO_MONTAJE = props.REGISTROS.NOMBRE_GRUPO_MONTAJE
                            ObjetoArticuloERP.NOMRE_MARCA = props.REGISTROS.NOMRE_MARCA
                            ObjetoArticuloERP.REFERENCIA = props.REGISTROS.REFERENCIA
                            ObjetoArticuloERP.REFERENCIA_PROVEEDOR = props.REGISTROS.REFERENCIA_PROVEEDOR

                            setDatosReferencia(ObjetoArticuloERP);
                            setPVP(ObjetoArticuloERP.PRECIO)
                            setNETO(ObjetoArticuloERP.NETO)
                            if (props.cantidad !== undefined) {
                                let PVP = ObjetoArticuloERP.PRECIO * props.cantidad
                                let calcularDesc = calcularNeto(PVP, ObjetoArticuloERP.DESCUENTO)
                                let Neto = PVP - calcularDesc;
                                //setPVP(PVP)
                                setNETO(Neto)
                            }
                        } else {
                            setDatosReferencia(ObjetoArticuloERP);
                            setPVP(ObjetoArticuloERP.PRECIO)
                            setNETO(ObjetoArticuloERP.NETO)
                            if (props.cantidad !== undefined) {
                                let PVP = ObjetoArticuloERP.PRECIO * props.cantidad
                                let calcularDesc = calcularNeto(PVP, ObjetoArticuloERP.DESCUENTO)
                                let Neto = PVP - calcularDesc;
                                //setPVP(PVP)
                                setNETO(Neto)
                            }
                        }
                    }
                })
            } else {
                if (props.REGISTROS !== undefined && props.REGISTROS !== null && Object.keys(props.REGISTROS).length > 0) {
                    ObjetoArticuloERP.CODIGO_MARCA = props.REGISTROS.CODIGO_MARCA
                    ObjetoArticuloERP.CODIGO_MARCA_ERP = props.REGISTROS.CODIGO_MARCA_ERP
                    ObjetoArticuloERP.DESCUENTO = props.REGISTROS.DESCUENTO
                    ObjetoArticuloERP.EAN = props.REGISTROS.EAN
                    ObjetoArticuloERP.NOMBRE_GRUPO_MONTAJE = props.REGISTROS.NOMBRE_GRUPO_MONTAJE
                    ObjetoArticuloERP.NOMRE_MARCA = props.REGISTROS.NOMRE_MARCA
                    ObjetoArticuloERP.REFERENCIA = props.REGISTROS.REFERENCIA
                    ObjetoArticuloERP.REFERENCIA_PROVEEDOR = props.REGISTROS.REFERENCIA_PROVEEDOR


                    setDatosReferencia(ObjetoArticuloERP);
                    setPVP(ObjetoArticuloERP.PRECIO)
                    setNETO(ObjetoArticuloERP.NETO)
                    if (props.cantidad !== undefined) {
                        let PVP = ObjetoArticuloERP.PRECIO * props.cantidad
                        let calcularDesc = calcularNeto(PVP, ObjetoArticuloERP.DESCUENTO)
                        let Neto = PVP - calcularDesc;
                        //setPVP(PVP)
                        setNETO(Neto)
                    }
                } else {
                    setDatosReferencia(ObjetoArticuloERP);
                    setPVP(ObjetoArticuloERP.PRECIO)
                    setNETO(ObjetoArticuloERP.NETO)
                    if (props.cantidad !== undefined) {
                        let PVP = ObjetoArticuloERP.PRECIO * props.cantidad
                        let calcularDesc = calcularNeto(PVP, ObjetoArticuloERP.DESCUENTO)
                        let Neto = PVP - calcularDesc;
                        //setPVP(PVP)
                        setNETO(Neto)
                    }
                }

            }


            if (props.referenciaProveedor !== "") {
                getStockDesglose(props.referencia, props.marca, props.referenciaProveedor).then(stock => {
                    if (stock !== null && stock.length > 0) {
                        let STOCK_DISPONIBLE = stock.filter((registros) => registros.Stock > 0);

                        if (STOCK_DISPONIBLE.length > 0) {
                            setesperandoDesglose(true)
                            setDesglose(stock);
                            if (props.cantidad === undefined) {
                                stock.map((item, i) => {
                                    if (item.DefectoCliente) {
                                        setMaximo(item.Stock)
                                        setStockSeleccionado(item.Stock)
                                        setIdAlmacenSeleccionado(item.CodigoAlmacen)
                                        setDescripcionAlmacenSeleccionado(item.DescripcionAlmacen)
                                    }
                                })
                            } else {
                                setCount(props.cantidad)
                                stock.map((item, i) => {
                                    if (item.CodigoAlmacen === props.almacen) {
                                        setMaximo(item.Stock)
                                        setStockSeleccionado(item.Stock)
                                        setIdAlmacenSeleccionado(item.CodigoAlmacen)
                                        setDescripcionAlmacenSeleccionado(item.DescripcionAlmacen)
                                    }
                                })
                            }
                        } else {
                            setesperandoDesglose(false)
                        }
                    } else {
                        setesperandoDesglose(false)
                        setesperandoClickStock(true)
                        setPetionAPI(true)
                    }
                })
            } else {
                setesperandoDesglose(false)
            }


            buscarDetallesReferencia({ REFERENCIA: props.referencia, CODIGO_MARCA: obtenerMarca }).then(data => {
                if (data !== undefined) {
                    setDetalles(data)
                } else {
                    setDetalles({})
                }

            })
        }
    }


    //Se controla el estado inicial del checked  que sea por defecto true
    if (!openDrawerCarroCompra) {
        if (!esperandoClickStock) {
            setesperandoClickStock(true)
        }

        if (detalles !== undefined && detalles !== null) {
            if (Object.keys(detalles).length > 0) {
                setDetalles({})
                setCount(1)
            }
        }

        if (Object.keys(datosReferencia).length > 0) {
            setDatosReferencia({})
        }

        if (!petionAPI) {
            setPetionAPI(true)
        }
    }


    const resetChecked = (seleccionado) => {
        setesperandoClickStock(false)
        desglose.map((desglose) => {
            document.getElementById("chk" + desglose.CodigoAlmacen).checked = false;
            if (desglose.CodigoAlmacen === seleccionado) {
                setStockSeleccionado(desglose.Stock)
                setIdAlmacenSeleccionado(desglose.CodigoAlmacen)
                setDescripcionAlmacenSeleccionado(desglose.DescripcionAlmacen)
                //console.log("preci",desglose.Stock)
            }

        })
        document.getElementById("chk" + seleccionado).checked = true;
    }

    const actualizarCantidad = (e) => {
        let cantidad = parseInt(e.target.value);
        if (cantidad === 0 || e.target.value === "") {
            setCount(1);
            calcularPVP(1)
        } else {
            if (parseInt(count) < maximo) {
                setCount(cantidad);
                calcularPVP(cantidad)
            } else {

                //setCount(maximo === 0 ? 1:maximo);
                //calcularPVP(maximo === 0 ? 1:maximo) 
            }
        }
    }

    const calcularPVP = (cantidad) => {
        let PVP = datosReferencia.PRECIO * cantidad
        let redondeoPVP = redondeo(PVP, 2);
        let calcularDesc = calcularNeto(redondeoPVP, datosReferencia.DESCUENTO)
        let Neto = redondeo((redondeoPVP - calcularDesc), 2);


        //setPVP(PVP)
        setNETO(Neto)
    }

    const añadirALaCesta = () => {
        if (stockSeleccionado >= count) {
            setCestaLineasEnAPI(datosReferencia.REFERENCIA_PROVEEDOR, datosReferencia.CODIGO_MARCA,
                datosReferencia.REFERENCIA, datosReferencia.CODIGO_MARCA,
                "[ " + datosReferencia.NOMRE_MARCA + " ] - " + datosReferencia.NOMRE_MARCA, count, 0, datosReferencia.PRECIO, datosReferencia.DESCUENTO, datosReferencia.NETO, idAlmacenSeleccionado, descripcionAlmacenSeleccionado, props.cantidad === undefined ? 0 : 1, props.IDCESTA).then(data => {
                    if (data.OK) {
                        MSGOK(traducirPagina("Referencia_cesta"), "botton-right");
                        store.dispatch(DrawerCarroCompra(false));
                    }
                    //Se cambia el estado para que la ficha de cesta compras se actualice.
                    if (props.cantidad !== undefined) {
                        store.dispatch({ type: "STATE_CESTA_LINEAS_COMPRA", actualizarCestaLineas: true })
                    }
                })


        } else {
            MSGERROR(traducirPagina("msg_sin_stock_almacen"));
        }
    }


    const insertarConsula = (datosReferencia) => {
        let almacen = obtenerDatosUsuarioYLicenciaActual();
        //sa agrego la referencia de proveedor.
        setCestaLineasEnAPI(datosReferencia.REFERENCIA_PROVEEDOR, "", "CONSULTA", "", "[ " + datosReferencia.REFERENCIA.toUpperCase() + " ] - " + datosReferencia.NOMRE_MARCA.toUpperCase() + " - " + datosReferencia.NOMBRE_GRUPO_MONTAJE.toUpperCase(),
            1, 1, datosReferencia.PRECIO, datosReferencia.DESCUENTO, datosReferencia.NETO, almacen.ALMACEN, "", props.cantidad === undefined ? 0 : 1).then(data => {
                if (data.OK) {
                    MSGOK("Consulta añadida.", "botton-left");
                    store.dispatch(DrawerCarroCompra(false));
                }
            })
    }

    return (
        <Drawer
            width={600}
            onClose={() => { store.dispatch(DrawerCarroCompra(false)); store.dispatch({ type: "STATE_CESTA_LINEAS_COMPRA", actualizarCestaLineas: false }) }}
            visible={openDrawerCarroCompra}
            placement={"right"}
            bodyStyle={{ padding: '0px' }}
            style={{ zIndex: '99999999999999999', color: '#fff' }}>
            <div>

                <div className="flex-container-Grow-Tercero" >
                    <div style={{ textAlign: 'inherit' }}>
                        <MDBBtn className="backgrounNegroClaro soloColorBlanco" aria-label="Close" style={{ border: 'none', width: '61px', height: '61px', padding: '0px', margin: '0px', borderRadius: 'unset' }} onClick={(e) => { store.dispatch(DrawerCarroCompra(false)); store.dispatch({ type: "STATE_CESTA_LINEAS_COMPRA", actualizarCestaLineas: false }); createRipple(e) }} >
                            <i aria-label="icon: close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" style={{ fontSize: '16px' }} ><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                            </i>
                        </MDBBtn>
                    </div>

                    <div style={{ width: '500px', color: '#fff', verticalAlign: 'middle', paddingLeft: '10px', fontSize: '16px' }}>
                        {props.title}
                    </div>
                </div>


                <div className="p-3" style={{ height: (configApp.height - 70).toString() + "px", overflow: 'auto' }}>
                    <Card style={{ marginBottom: '10px' }}>
                        {detalles !== undefined && datosReferencia !== undefined ?
                            <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                {Object.keys(detalles).length > 0 ?
                                    <MDBCol md="3">
                                        <div>
                                            <img src={detalles.IMAGEN === "" ? "./imagenes/sinimagen.jpg" : DIRECTORIO_DOCUMENTO + pad(parseInt(props.marca), 4).toString() + "/" + detalles.IMAGEN} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} />

                                            <ImagenArticulo REGISTRO={detalles} DIRECTORIO_DOCUMENTO={DIRECTORIO_DOCUMENTO}></ImagenArticulo>
                                            <div style={{ backgroundColor: detalles.ID_ESTADO === 1 ? '#4285f4' : detalles.ID_ESTADO === 9 ? '#D91E18' : '#dcdcdc', textAlign: 'center', color: detalles.ID_ESTADO === 1 || detalles.ID_ESTADO === 9 ? '#fff' : '#000' }}>
                                                {detalles.DESCRIPCION_ESTADO}
                                            </div>
                                        </div>
                                    </MDBCol>
                                    :
                                    <MDBCol md="3">

                                        <div>
                                            <img src={"./imagenes/sinimagen.jpg"} onError={addDefaultSrc} className="img-fluid z-depth-0" style={{ verticalAlign: 'text-top' }} />

                                        </div>
                                    </MDBCol>
                                }

                                {Object.keys(datosReferencia).length > 0 ?
                                    <MDBCol md="9">
                                        <div>
                                            <strong>
                                                {props.referencia + " " + datosReferencia.NOMBRE_GRUPO_MONTAJE}
                                            </strong>
                                            <br />
                                            {traducirPagina("Referencia_proveedor") + ": " + datosReferencia.REFERENCIA_PROVEEDOR}
                                            <br />

                                            <span className="eanNumber">
                                                {"EAN" + datosReferencia.EAN}
                                            </span>

                                        </div>
                                    </MDBCol>
                                    :
                                    null
                                }
                            </div>
                            :
                            esperandoDesglose ?
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                    <MDBCol md="3">
                                        <div>
                                            <span className="skeleton-box" style={{ width: '130px', height: '8em' }}></span>
                                        </div>
                                    </MDBCol>

                                    <MDBCol md="9">
                                        <div>
                                            <span className="skeleton-box" style={{ width: '150px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '280px' }}></span>
                                            <br />
                                            <span className="skeleton-box" style={{ width: '150px' }}></span>
                                        </div>
                                    </MDBCol>
                                </div>
                                :
                                null

                        }
                    </Card>


                    <Card title={traducirPagina("Seleccion_almacen")} style={{ marginBottom: '10px' }}>
                        {desglose.length > 0 ?
                            <div style={{ fontSize: '16px', fontWeight: '500', color: "#000", height: "170px", overflow: 'auto' }} className="row">
                                <MDBTable responsive hover>
                                    <MDBTableHead>
                                        <tr>
                                            <th style={{ textAlign: 'left', fontWeight: '500' }}>{traducirPagina("Almacen").toUpperCase()}</th>
                                            {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <th style={{ textAlign: 'right', width: '100px', fontWeight: '500' }}>{obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE ? "" : obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? "" : traducirPagina("Cantidad").toUpperCase()}</th>}
                                            <th style={{ textAlign: 'center', width: '100px', fontWeight: '500' }}>{traducirPagina("Disponibilidad").toUpperCase()}</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {desglose.map((item, i) =>
                                            props.cantidad === undefined ?
                                                <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }}>
                                                    <td style={{ textAlign: 'left' }}>
                                                        {/* checked={item.DefectoCliente && esperandoClickStock ? true : null}} */}
                                                        <MDBInput checked={item.DefectoCliente && esperandoClickStock ? true : null} label={item.DescripcionAlmacen} type="checkbox" id={"chk" + item.CodigoAlmacen} onChange={(e) => { setMaximo(item.Stock); setCount(1); resetChecked(item.CodigoAlmacen); }} />
                                                    </td>

                                                    {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <td style={{ textAlign: 'right', width: '100px' }}>{item.Stock}</td>}
                                                    <td style={{ textAlign: 'center', width: '100px' }}>
                                                        <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: item.Stock === 0 ? "red" : '#25c281', backgroundColor: 'transparent' }}>  {item.Stock === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                                    </td>
                                                </tr>
                                                :
                                                <tr key={i} style={{ color: item.DefectoCliente ? "blue" : "", fontWeight: item.DefectoCliente ? 'bold' : "" }}>
                                                    <td style={{ textAlign: 'left' }}>
                                                        {/* checked={item.DefectoCliente && esperandoClickStock ? true : null}} */}
                                                        <MDBInput checked={item.CodigoAlmacen === props.almacen && esperandoClickStock ? true : null} label={item.DescripcionAlmacen} type="checkbox" id={"chk" + item.CodigoAlmacen} onChange={(e) => { setMaximo(item.Stock); setCount(1); resetChecked(item.CodigoAlmacen); calcularPVP(1) }} />
                                                    </td>
                                                    {obtenerDatosUsuarioYLicenciaActual().SIEMPRE_VERDE || obtenerDatosUsuarioYLicenciaActual().SEMAFORO_DISPONIBILIDAD ? null : <td style={{ textAlign: 'right', width: '100px' }}>{item.Stock}</td>}
                                                    <td style={{ textAlign: 'center', width: '100px' }}>
                                                        <button className="activebtnMenu2" style={{ border: '0', borderRadius: '3px', fontSize: '13px', color: item.Stock === 0 ? "red" : '#25c281', backgroundColor: 'transparent' }}>  {item.Stock === 0 ? <DislikeOutlined style={{ fontSize: '20px' }}></DislikeOutlined> : <LikeOutlined style={{ fontSize: '20px' }} />}  </button>
                                                    </td>
                                                </tr>
                                        )
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                            :
                            esperandoDesglose ?
                                <div>
                                    <span className="skeleton-box" style={{ width: '450px' }}></span>
                                    <br />
                                    <span className="skeleton-box" style={{ width: '450px' }}></span>
                                    <br />
                                    <span className="skeleton-box" style={{ width: '450px' }}></span>
                                    <br />
                                    <span className="skeleton-box" style={{ width: '450px' }}></span>
                                    <br />
                                    <span className="skeleton-box" style={{ width: '450px' }}></span>
                                </div>
                                :
                                <div style={{ fontSize: '22px', fontWeight: '500', color: "#dc3545" }} className="row">
                                    <MDBCol className="col-md-6">
                                        <img src="./imagenes/no_disponible.svg" style={{ height: '170px' }}></img>
                                    </MDBCol>
                                    <MDBCol className="col-md-6">
                                        <span>{etiquetaSinDisponibilidad()}</span>
                                    </MDBCol>
                                </div>
                        }
                    </Card>

                    <Card>
                        {Object.keys(datosReferencia).length > 0 ?
                            PVP > 0 && datosReferencia.NETO > 0 && esperandoDesglose ?
                                <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                    <MDBCol className="col-md-6">
                                        <span className="next-number-picker next-number-picker-inline next-medium zoro-ui-number-picker number-picker product-number-picker">
                                            <span className="next-input-group">
                                                <span className="next-input-group-addon next-before">
                                                    <button type="button" className="next-btn next-medium next-btn-normal" role="button" onClick={() => { setCount(count - 1); calcularPVP(count - 1) }} disabled={count > 1 ? false : true}>
                                                        <MinusOutlined style={{ fontSize: '16px' }} />
                                                    </button>
                                                </span>
                                                <span className="next-input next-medium next-input-group-auto-width">
                                                    <input className="txtCantidad" aria-valuemax="1798" aria-valuemin="1" height="100%" autoComplete="off" value={count} onChange={(e) => {
                                                        setCount(e.target.value);
                                                    }} onBlur={(e) => actualizarCantidad(e)} />
                                                </span>
                                                <span className="next-input-group-addon next-after">
                                                    <button type="button" className="next-btn next-medium next-btn-normal" role="button" data-spm-anchor-id="a2g0o.detail.1000016.i3.759e707cqToxAp" onClick={() => { setCount(count + 1); calcularPVP(count + 1) }} disabled={count < maximo ? false : true}>
                                                        <PlusOutlined style={{ fontSize: '16px' }} />
                                                    </button>
                                                </span>
                                            </span>
                                        </span>

                                        <div style={{ marginTop: '20px' }}>
                                            <button className="activebtnMenu3" style={{ height: '40px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#fff', backgroundColor: stockSeleccionado >= count ? '#4285f4' : '#dc3545' }} onClick={() => { añadirALaCesta(); }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{
                                                props.cantidad === undefined ?
                                                    stockSeleccionado >= count ?
                                                        traducirPagina("Añadir_a_la_cesta")
                                                        :
                                                        traducirPagina("No_disponibilidad_stock")
                                                    :
                                                    stockSeleccionado >= count ? traducirPagina("Modificar_a_la_cesta") : traducirPagina("No_disponibilidad_stock")}</span>
                                            </button>
                                        </div>
                                    </MDBCol>

                                    <MDBCol className="col-md-6">
                                        <div style={{ color: '#2c3e50', marginBottom: '10px', textAlign: 'right' }}>
                                            <div id="PVPCesta">{traducirPagina("PVP").toUpperCase() + ": " + convertirMoneda(PVP) + " €"}</div>
                                            <div id="PVPPrecio">{traducirPagina("Precio") + ": " + convertirMoneda(PVP) + " €"} </div>
                                            <span>{traducirPagina("Dto") + ":" + datosReferencia.DESCUENTO + " %"} </span>
                                            <br />
                                            <span>{traducirPagina("Neto") + ": " + convertirMoneda(NETO) + " €"}</span>
                                            <br />
                                        </div>
                                    </MDBCol>
                                </div>
                                :
                                <div style={{ fontSize: '16px', fontWeight: '500' }} className="row">
                                    <MDBCol className="col-md-6">
                                        <BtnSolicitar
                                            texto={etiquetaBtnSolicitar()}
                                            onClick={() => insertarConsula(datosReferencia)}
                                        />
                                    </MDBCol>

                                    <MDBCol className="col-md-6">
                                        <button style={{ height: '60px', width: '250px', border: '0', borderRadius: '3px', fontSize: '13px', verticalAlign: 'bottom', color: '#000', backgroundColor: '#eee' }}>   <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>{traducirPagina("btn_pedido_referencia")}</span>  </button>
                                    </MDBCol>
                                </div>
                            :
                            <div style={{ fontSize: '16px', fontWeight: '500', color: "#000" }} className="row">
                                <MDBCol md="3">
                                    <div>
                                        <span className="skeleton-box" style={{ width: '150px', height: '10em' }}></span>
                                    </div>
                                </MDBCol>

                                <MDBCol md="9" style={{ textAlign: 'right' }}>
                                    <div>
                                        <span className="skeleton-box" style={{ width: '150px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '200px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '120px' }}></span>
                                        <br />
                                        <span className="skeleton-box" style={{ width: '150px' }}></span>
                                    </div>
                                </MDBCol>
                            </div>
                        }
                    </Card>
                </div>
            </div>
        </Drawer>
    )
}

CarroCompra.propTypes = {
    onClick: PropTypes.func,
    referencia: PropTypes.string,
    marca: PropTypes.number,
    referenciaProveedor: PropTypes.string,
    cantidad: PropTypes.number,
    almacen: PropTypes.object,
    IDCESTA: PropTypes.string
};

const mapStateToProps = (state, props) => {
    return {
        configApp: state.configApp,
        props: props,
        openDrawerCarroCompra: state.estadoDrawer.openDrawerCarroCompra,
        DIRECTORIO_DOCUMENTO: state.DIRECTORIO_DOCUMENTO

    };
}

export default connect(mapStateToProps)(CarroCompra);